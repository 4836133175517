const Cognito = {
  protocol: "https:",
  hostname: "vana-buddies.auth.us-east-1.amazoncognito.com",
  pathname: "login",
  storageKey: "session",
  client: "7vj6289qocio8n2gii4cudnvme",
  redirect: process.env.REACT_APP_AUTH_REDIRECT_URL,
};

export default Cognito;
