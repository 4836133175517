import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Flag from "../flag/flag";
import UserVerified from "../certified/certified";
import "./name.css";

class UserName extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    className: PropTypes.string,
  };
  static defaultProps = {
    user: {},
  };
  render() {
    const { className, user } = this.props;
    const personal = user.personal || {};
    const first = (personal.first_name || "").trim();
    const last = (personal.last_name || "").trim();
    const country = personal.country;
    let state = "";
    const confirmed = "confirmed";
    if (user !== undefined || user !== "") {
      state = user?.state?.id_number_status;
    }
    let userName = "Unknown user";
    if (first && last) {
      userName = `${first} ${last}`
        .toLocaleLowerCase()
        .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toLocaleUpperCase())
        .trim();
    }
    return (
      <div className="name-wrapper">
        <Flag country={country} />
        <span className={classNames("user-name", className)}>{userName}</span>
        {state === confirmed && <UserVerified />}
      </div>
    );
  }
}

export default UserName;
