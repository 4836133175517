import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./avatar.css";

class Avatar extends React.Component {
  static propTypes = {
    payment: PropTypes.object,
    className: PropTypes.string,
  };
  static defaultProps = {
    payment: {},
  };
  render() {
    const { className, payment } = this.props;
    return (
      <div
        className={classNames("payment-avatar", className)}
        style={{ backgroundImage: `url(${payment.image.remote_url})` }}
      />
    );
  }
}

export default Avatar;
