import { extraArgument } from "./extraargument";
import { loggerMiddleware } from "./logger";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const params = {
  thunk: {
    extraArgument,
  },
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  }
};
export const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware(params).concat(loggerMiddleware);
