import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ReactJson from "react-json-view";

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from "@material/react-dialog";

import MaterialIcon from "@material/react-material-icon";
import { compose } from "../../../util/compose";

import "@material/react-dialog/dist/dialog.css";
import "@material/react-material-icon/dist/material-icon.css";

const styles = {
  button: {
    marginTop: 24,
    borderTop: "1px solid rgba(0,0,0,0.12)",
    cursor: "pointer",
    paddingTop: 12,
  },
  span: {
    verticalAlign: "bottom",
    display: "inline-block",
    paddingRight: 6,
  },
  icon: {
    verticalAlign: "bottom",
    transition: "transform 300ms cubic-bezier(0.25, 0.8, 0.25, 1)",
  },
  details: {
    borderRadius: 4,
    padding: 12,
    border: "1px solid rgba(0,0,0, 0.12)",
    backgroundColor: "rgba(0,0,0,0.02)",
    fontSize: 11,
    lineHeight: 1.1,
  },
};

class AppError extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    data: null,
    onClose: () => {},
  };

  state = {
    details: false,
  };

  onClick() {
    this.setState({ details: !this.state.details });
  }

  render() {
    const { onClose, t } = this.props;
    const { details } = this.state;
    const data = Object.assign(
      {
        title: t("Oops! Something went wrong"),
        text: t("Some unknown error has occured."),
        data: {},
      },
      this.props.data
    );
    return (
      <Dialog open={!!this.props.data} onClose={onClose}>
        <DialogTitle>{data.title}</DialogTitle>
        <DialogContent>
          {data.text && (
            <div>
              <p>{data.text}</p>
            </div>
          )}
          {data.data && (
            <div>
              <p onClick={this.onClick.bind(this)} style={styles.button}>
                <span style={styles.span}>{t("View details")}</span>
                <MaterialIcon
                  icon="chevron_right"
                  style={Object.assign(
                    { transform: `rotate(${details ? "-" : ""}90deg)` },
                    styles.icon
                  )}
                />
              </p>
              <div style={{ display: details ? "block" : "none" }}>
                <ReactJson
                  src={data.data}
                  collapsed={1}
                  name={false}
                  sortKeys={true}
                  displayDataTypes={false}
                  enableClipboard={false}
                  indentWidth={2}
                  collapseStringsAfterLength={33}
                />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogFooter>
          <DialogButton action="dismiss">{t("Dismiss")}</DialogButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default compose(AppError)(withTranslation());
