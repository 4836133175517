import { objectKeysToCamelCase } from "../util/commonutils";

export const createPaymentRepo = (apiClient) => {
  const createPayment = async ({
    userId,
    loanId,
    bank,
    bankOrigin,
    amount,
    agency,
    date,
    reference,
    receiptBase64,
  }) => {
    if (
      !userId ||
      !loanId ||
      !bank ||
      !amount ||
      !agency ||
      !date ||
      !reference ||
      !receiptBase64
    ) {
      throw new Error("invalid or missing params");
    }
    const url = `/loans/${loanId}/payments`;
    const type = "payout";
    const data = {
      user_id: userId,
      loan_id: loanId,
      amount: Number(amount),
      bank,
      bank_origin: bankOrigin,
      agency,
      type,
      date,
      reference,
      receipt_base_64: receiptBase64,
    };
    const response = await apiClient.post({ url, data });
    return response.data ? objectKeysToCamelCase(response.data) : "";
  };
  const getPaymentById = async (paymentId) => {
    const url = `/payments/${paymentId}`;
    const response = await apiClient.get({ url });
    return response.data ? objectKeysToCamelCase(response.data) : "";
  };
  const listPaymentsByStatus = async (paymentStatus) => {
    const url = `/payments`;
    const params = {
      associations: "users",
      type: "status",
      status: paymentStatus,
    };
    const response = await apiClient.get({ url, params });
    return response.data && response.data.items && response.data.items.length
      ? response.data.items.map(objectKeysToCamelCase)
      : [];
  };
  const updatePayment = async ({
    amount,
    bank,
    bankOrigin,
    date,
    paymentId,
    reference,
    status,
    type,
    agency,
  }) => {
    if (!paymentId || !status) {
      throw new Error("invalid or missing params");
    }
    const payment = await getPaymentById(paymentId);
    if (!payment) {
      throw new Error(`payment ${paymentId} not found`);
    }
    /* TODO: validate other parameters types and constraints */
    const url = `/loans/${payment.loanRequestId}/payments/${paymentId}/update_status`;
    const bankTransaction = Object.entries({
      amount: Number(amount),
      bank,
      bank_origin: bankOrigin,
      date,
      reference,
      type,
      agency
    }).reduce((prev, [propKey, propVal]) => {
      return propVal ? { ...prev, [propKey]: propVal } : { ...prev };
    }, {});
    const data = {
      status: status,
      user_id: payment.userId,
      bank_transaction: bankTransaction,
    };
    await apiClient.post({ url, data });
  };
  return {
    createPayment,
    getPaymentById,
    listPaymentsByStatus,
    updatePayment,
  };
};
