import React from "react";
import PropTypes from "prop-types";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Card from "../../common/card/card";
import CardContent from "../../common/card/cardcontent";
import CircleIcon from "../../common/circle/circleicon";
import DisbursalList from "./list/list";
import DisbursalListAdapter from "./list/listadapter";
import Heading from "../../common/heading";
import { withDataLayer } from "../../datalayer/datalayer";
import { compose } from "../../../util/compose";
import { withApp } from "../../app/app";

class Details extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidUpdate(props) {
    const { service, type } = this.props.match.params;
    const { params } = props.match;
    if (service !== params.service && type !== params.type) {
      this.getDisbursals();
    }
  }
  componentDidMount() {
    if (!this.state.disbursals) this.getDisbursals();
  }

  getDisbursals() {
    this.setState({
      isLoading: true,
    });
    const { service, handler, type } = this.props.match.params;
    this.props.dataLayer.disbursals
      .getDisbursals(service, handler, type)
      .then((disbursals) => {
        this.setState({
          isLoading: false,
          disbursals: disbursals.data.data.items,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        this.props.app.onError({
          text: this.props.t("Error generating process."),
          data: error.details,
        });
      });
  }

  render() {
    const { service, handler, type } = this.props.match.params;
    return (
      <Grid>
        <Row>
          <Cell columns={12}>
            <Card className="disbursals">
              <Heading
                title={this.props.t(service)}
                handler={this.props.t(handler)}
                subtitle={this.props.t(type)}
                graphic={<CircleIcon light={true} icon="autorenew" />}
              />
              <CardContent>
                <DisbursalList
                  loading={this.state.isLoading}
                  disbursals={this.state.disbursals}
                  adapter={DisbursalListAdapter}
                  onReload={this.getDisbursals.bind(this)}
                />
              </CardContent>
            </Card>
          </Cell>
        </Row>
      </Grid>
    );
  }
}
export default compose(Details)(
  withDataLayer,
  withApp,
  withRouter,
  withTranslation()
);
