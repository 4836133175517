export const selectAllPayments = () => (store) => {
  const payments = store.payments.payments
    ? Object.values(store.payments.payments)
    : [];
  return payments.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  });
};

export const selectPaymentById = (paymentId) => (store) => {
  return paymentId &&
    store.payments.payments &&
    store.payments.payments[paymentId]
    ? store.payments.payments[paymentId]
    : "";
};

export const selectPaymentsByStatus =
  (...status) =>
  (store) => {
    return selectAllPayments(status)(store)
      .filter((payment) => status.includes(payment.status))
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        return 0;
      });
  };
