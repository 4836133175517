import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

import "@material/react-typography/dist/typography.css";
import "@material/react-layout-grid/dist/layout-grid.css";
import "@material/react-material-icon/dist/material-icon.css";

class WorkAddress extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  render() {
    const { data, t } = this.props;
    return (
      <FieldsCard
        data={data}
        fields={fields}
        icon="business"
        title={t("Work address")}
      />
    );
  }
}

export default compose(WorkAddress)(withTranslation());
