import React from "react";
import { Route } from "react-router-dom";
import Device from "../applayout/device/device";
import Disbursal from "../applayout/disbursal/disbursal";
import LoanRouter from "../applayout/loan/router";
import Reports from "../applayout/reports/reports";
import Request from "../applayout/request/request";
import Swagger from "../applayout/swagger/swagger";
import PageNotFound from "../applayout/appcontent/pagenotfound";
import User from "../applayout/user/user";
import { Banks } from "../banks/banks.js";
import { Payments } from "../payments/payments";

class Routes extends React.Component {
  render() {
    return [
      <Route key="users" path="/users">
        <User />
      </Route>,
      <Route key="banks" path="/banks/:paramA?/:paramB?">
        <Banks />
      </Route>,
      <Route key="loans" path="/loans">
        <LoanRouter />
      </Route>,
      <Route key="request" path="/requests">
        <Request />
      </Route>,
      <Route key="payments" path="/payments/:paymentId?">
        <Payments />
      </Route>,
      <Route key="devices" path="/devices">
        <Device />
      </Route>,
      <Route key="disbursals" path="/disbursals">
        <Disbursal />
      </Route>,
      <Route key="reports" path="/reports">
        <Reports />
      </Route>,
      <Route key="swagger_not_found" path="/swagger">
        <PageNotFound />
      </Route>,
      <Route key="swagger" path="/access/swagger">
        <Swagger />
      </Route>,
    ];
  }
}

export default Routes;
