import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useModalView } from "../../../hooks/usemodalview";
import { dateFormats, formatDate } from "../../../util/dateutils";
import { anchorVerticalToRightOf } from "../../globals/modalviews/anchors";
import { Icon } from "../icon/icon";
import { Ripple } from "../ripple/ripple";
import {
  TextInputLeadingIcon,
  TextInputTrailingIcon,
} from "../textinput/textinput";
import "./datepickerinput.css";

export const DatePickerInput = ({
  children,
  className,
  hasError,
  isDisabled,
  onChange,
  options,
  title = "Selecciona una fecha",
  value = "",
  ...otherProps
}) => {
  /* setup dropdown */
  const [revealDropDown, dropDown] = useModalView();
  const onTrailingIconButtonClick = async ({ target }) => {
    if (isDisabled) {
      return;
    }
    await revealDropDown({
      ...anchorVerticalToRightOf(target, 12),
      component: "controls/datepicker/datepicker",
      props: { title, value },
    });
  };
  useEffect(() => {
    if (
      onChange &&
      dropDown.id &&
      dropDown.isVisible &&
      dropDown.selected &&
      dropDown.selected !== value
    ) {
      onChange(formatDate(dropDown.selected, dateFormats.YYYY_MMMM_DD));
    }
  }, [onChange, value, dropDown.id, dropDown.isVisible, dropDown.selected]);
  /* compute layout flags */
  let hasLeadingIcon = false;
  React.Children.forEach(children, (child) => {
    if (child && child.type === TextInputLeadingIcon) {
      hasLeadingIcon = true;
    }
  });
  /* comp state */
  const [hasFocus, setHasFocus] = useState();
  /* event handlers */
  const onFocus = () => {
    setHasFocus(true);
  };
  const onBlur = () => {
    setHasFocus(false);
  };
  const formattedValue = useMemo(
    () => (value ? formatDate(value, dateFormats.YYYY_MMMM_DD) : ""),
    [value]
  );
  return (
    <Ripple
      dark
      {...otherProps}
      isDisabled={isDisabled}
      onClick={onTrailingIconButtonClick}
      className={classNames(className, "edit-text date-input", {
        "is-disabled": isDisabled,
        "is-visible": dropDown.isVisible,
        "has-focus": hasFocus,
        "has-error": hasError,
        "has-value": value,
        "has-leading-icon": hasLeadingIcon,
        "has-trailing-icon": true,
      })}
    >
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        className="input"
        onChange={() => {}}
        type="text"
        value={formattedValue}
        disabled={false}
      />
      <div className="buttons">
        {children}
        <TextInputTrailingIcon>
          <Icon icon="event" />
        </TextInputTrailingIcon>
      </div>
    </Ripple>
  );
};
