export const fields = {
  country: {
    key: "country",
    label: "Country",
    icon: "lock"
  },
  state: {
    key: "state",
    label: "State",
    icon: "lock"
  },
  city: {
    key: "city",
    label: "City",
    icon: "lock"
  },
  street: {
    key: "street",
    label: "Street",
    icon: "lock"
  },
  number: {
    key: "number",
    label: "Number",
    icon: "lock"
  }
};
