import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import List from "../../../common/list/list";
import ListItems from "../../../common/list/listitems";
import ListToolbar from "../../../common/list/toolbar/toolbar";
import DisbursalListItem from "./item/item";
import { compose } from "../../../../util/compose";

import "./list.css";
class DisbursalList extends React.Component {
  static propTypes = {
    adapter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    disbursals: PropTypes.array,
    loading: PropTypes.bool,
    selected: PropTypes.string,
    onReload: PropTypes.func,
  };
  static defaultProps = {
    loading: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      tools: [],
      filters: {
        sort: ["recent"],
      },
      sorted: null,
    };
  }

  filter() {
    console.log("LoanList.filter");
    if (!this.props.disbursals) {
      return null;
    }
    const adapter = this.props.adapter;
    const filters = this.state.filters;
    let sorted = this.props.disbursals;
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filters[filter].length) {
        sorted = adapter.filters[filter].onFilter(sorted, filters[filter]);
      }
    });
    return sorted;
  }
  onReload() {
    this.props.onReload();
  }
  onFilter(filters) {
    this.setState({ sorted: null, filters });
  }

  render() {
    return (
      <List className="disbursals">
        <ListItems
          adapter={this.props.adapter}
          loading={this.props.loading}
          filters={this.state.filters}
          items={this.filter()}
        >
          {(disbursal, key) => (
            <DisbursalListItem key={key} disbursal={disbursal} />
          )}
        </ListItems>
        <ListToolbar
          filters={this.props.adapter.filters}
          loading={this.props.loading}
          selected={{ filters: this.state.filters }}
          onFilter={this.onFilter.bind(this)}
          onReload={this.onReload.bind(this)}
        />
      </List>
    );
  }
}

export default compose(DisbursalList)(withRouter);
