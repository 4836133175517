import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import classNames from "classnames";
import "./time.css";

class Time extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    format: PropTypes.string,
    text: PropTypes.string,
    time: PropTypes.string,
  };
  static defaultProps = {
    format: "MMMM DD, YYYY HH:MM[hrs]",
    time: Moment(),
  };
  render() {
    return (
      <div className={classNames("time", this.props.className)}>
        {this.props.text && <strong>{this.props.text}</strong>}
        <time>{Moment(this.props.time).format(this.props.format)}</time>
      </div>
    );
  }
}

export default Time;
