import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { selectSession as selectAuthSession } from "../../redux/auth/selectors";
import { handleAuth } from "../../redux/auth/thunks/handleauth";
import { signOut } from "../../redux/auth/thunks/signout";
import Progress from "../common/progress/progress";
import "./auth.css";
import Context from "./context";

const AUTH_CHECK_INTERVAL = 60 * 1000;

const Auth = ({ t, children }) => {
  const dispatch = useDispatch();
  const session = useSelector(selectAuthSession());
  const context = {
    ...session,
    clearToken: async () => {
      await dispatch(signOut());
    },
  };
  useEffect(() => {
    let timer = "";
    dispatch(handleAuth()).then((session) => {
      timer = setInterval(() => {
        dispatch(handleAuth());
      }, AUTH_CHECK_INTERVAL);
    });
    return () => clearTimeout(timer);
  }, [dispatch]);
  return (
    <Progress
      className="auth"
      loading={!session}
      text={t("Redirecting to login page")}
    >
      {() => (
        <Context.Provider value={context}>
          <BrowserRouter>{children}</BrowserRouter>
        </Context.Provider>
      )}
    </Progress>
  );
};

export default withTranslation()(Auth);

export function withAuth(Component) {
  return (props) => (
    <Context.Consumer>
      {(auth) => <Component {...auth} {...props} />}
    </Context.Consumer>
  );
}
