import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

class Personal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    onUpdate: PropTypes.func
  };

  static defaultProps = {
    onUpdate: (update) => {}
  };

  onUpdate(update) {
    const personal = {};
    Object.values(update).forEach((item) => {
      if (item.updated) personal[item.key] = item.value;
    });
    this.props.onUpdate(personal);
  }

  render() {
    const { data, t } = this.props;
    return (
      <FieldsCard
        editable={true}
        data={data}
        fields={fields}
        icon="perm_identity"
        onUpdate={this.onUpdate.bind(this)}
        title={t("User personal")}
        subtitle={t("User's personal profile data")}
      />
    );
  }
}

export default compose(Personal)(withTranslation());
