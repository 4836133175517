import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import CircleIcon from "../../../common/circle/circleicon";
import ListItem from "../../../common/list/item/item";
import ListItemGraphic from "../../../common/list/item/itemgraphic";
import ListItemContent from "../../../common/list/item/itemcontent";
import ListItemMeta from "../../../common/list/item/meta/meta";
import RequestListItemTags from "./itemtags";
import Time from "../../../common/time/time";
import Uuid from "../../../common/uuid/uuid";
import UserName from "../../../user/name/name";
import { Currency } from "../../../globals/currency/currency";
import { compose } from "../../../../util/compose";

class RequestListItem extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    request: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    linkRef: PropTypes.string,
    type: PropTypes.string
  };
  static defaultProps = {
    selected: false,
    onSelect: (request) => {},
  };
  onRequest() {
    const request = this.props.request;
    this.props.history.push(`/requests/details/${request.loan_request_id}`);
  }
  render() {
    if(this.props.type !== 'document_sent'){
      return (
        <ListItem
          className="request"
          item={this.props.request}
          selected={this.props.selected}
          onSelect={this.onRequest.bind(this)}
          linkRef={this.props.linkRef}
        >
          <ListItemGraphic>
            <CircleIcon icon="credit_card" />
          </ListItemGraphic>
          <ListItemContent>
            <h4>
              <UserName user={this.props.request.user} />
            </h4>
            <Uuid text="ID:" uuid={this.props.request.loan_request_id} />
            <Time
              time={this.props.request.state.updated_at}
              format="MMMM DD, YYYY H:mm:ss"
            />
          </ListItemContent>
          <ListItemMeta>
            <h4>
              <Currency
                amount={this.props.request.amount || 0}
                country={this.props.request.user?.personal?.country}
              />
            </h4>
            <h5># {this.props.request.state.request_number}</h5>
          </ListItemMeta>
          <RequestListItemTags request={this.props.request} />
        </ListItem>
      );
    } else {
      return null;
    }
  }
}

export default compose(RequestListItem)(withRouter, withTranslation());
