import React from "react";

function UserVerified() {
  const verified = `/img/verified.png`;
  return (
    <img
      className="verified_icon"
      src={verified}
      alt="flag"
      width={21}
      height={21}
    ></img>
  );
}

export default UserVerified;
