import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  mergeFulfilledUser,
  mergePendingUser,
  mergeRejectedUser,
} from "../actions";
import { thunkTypes } from "../thunktypes";

export const getUserById = createAsyncThunk(
  thunkTypes.GET_USER_BY_ID,
  async (params, { dispatch, extra }) => {
    if (!params.userId) {
      throw new Error("invalid user id");
    }
    await dispatch(mergePendingUser(params.userId));
    let user = "";
    try {
      user = await extra.userRepo.getUserById(params.userId);
      await dispatch(mergeFulfilledUser(user));
    } catch (error) {
      await dispatch(mergeRejectedUser(params.userId));
    }
    if (!user) {
      throw new Error(`user ${params.userId} not found`);
    }
    return user;
  }
);
