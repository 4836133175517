import React from "react";
import PropTypes from "prop-types";
import SwaggerUI from "swagger-ui-react";
import { compose } from "../../../util/compose";
import { withAuth } from "../../auth/auth";
import "./swagger.css";
import "swagger-ui-react/swagger-ui.css";

class Swagger extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  render() {
    return (
      <SwaggerUI
        url="https://cdn.vana.gt/publicopenapi.yml"
        onComplete={(swaggerUi) => {
          swaggerUi.preauthorizeApiKey(
            "LambdaAuthorizer",
            this.props.token.access
          );
        }}
      />
    );
  }
}

export default compose(Swagger)(withAuth);
