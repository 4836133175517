export const bankAgencyTypes = {
  ATM: "atm",
  AGENCY: "agency",
  AGENT: "agent",
  ONLINE: "online",
};

export const bankAgencyTypeLabels = {
  [bankAgencyTypes.ATM]: `ATM`,
  [bankAgencyTypes.AGENCY]: `Agencia`,
  [bankAgencyTypes.AGENT]: `Agente`,
  [bankAgencyTypes.ONLINE]: `Transferencia`,
};

export const bankAgencyTypeOptions = Object.values(bankAgencyTypes).map(
  (value) => ({
    value,
    label: bankAgencyTypeLabels[value],
  })
);
