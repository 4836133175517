import classNames from "classnames";
import {
  bankAgencyTypeLabels,
  bankAgencyTypes,
} from "../../../domain/banks/bankagencytype";
import { paymentBanks } from "../../../domain/payments/paymentbanks";
import { useFragment } from "../../../hooks/usefragment";
import { useTextInput } from "../../../hooks/usetextinput";
import {
  createErrorResult,
  createOkResult,
  isEmpty,
  isNumeric,
  okValidator,
} from "../../../util/validators";
import {
  Button,
  ButtonLabel,
  ContainedButton,
  RoundedButton,
} from "../../controls/button/button";
import { CheckBox, CheckBoxLabel } from "../../controls/checkbox/checkbox";
import { FlagIcon, Icon } from "../../controls/icon/icon";
import { RadioButton, RadioGroup } from "../../controls/radiogroup/radiogroup";
import {
  TextInputEditText,
  TextInputLabel,
} from "../../controls/textinput/textinput";
import {
  TextInputErrorMessage,
  TextInputLayout,
} from "../../controls/textinput/textinputlayout";
import {
  DropDown,
  DropDownContent,
  DropDownHeader,
} from "../../globals/modalviews/dropdown";
import "./listfilter.css";

export const FILTER_COUNTRY_GT = "gt";
export const FILTER_COUNTRY_DO = "do";
export const FILTER_COUNTRY_HN = "hn";
export const FILTER_STATUS_PROCESSING = "processing";
export const FILTER_STATUS_NOT_FOUND = "not_found";
export const FILTER_SORT_ORDER_RECENT = "recent";
export const FILTER_SORT_ORDER_OLDER = "older";
export const FILTER_FRAGMENT_ID = "payments/list/filter";
export const defaultFilter = {
  bank: Object.values(paymentBanks),
  bankAgency: Object.values(bankAgencyTypes),
  country: [FILTER_COUNTRY_GT, FILTER_COUNTRY_DO, FILTER_COUNTRY_HN],
  maxAmount: "",
  minAmount: "",
  sortOrder: FILTER_SORT_ORDER_OLDER,
  status: [FILTER_STATUS_PROCESSING, FILTER_STATUS_NOT_FOUND],
};

export const amountValidator = () => (amount) => {
  return !isEmpty(amount) && !isNumeric(amount)
    ? createErrorResult("Deves escribir un número")
    : createOkResult();
};

export const ListFilter = ({ anchor, className, onHide, origin }) => {
  const [filter, setFilter] = useFragment(FILTER_FRAGMENT_ID, defaultFilter);
  const bank = useTextInput(okValidator(), filter.bank);
  const bankAgency = useTextInput(okValidator(), filter.bankAgency);
  const country = useTextInput(okValidator(), filter.country);
  const maxAmount = useTextInput(amountValidator(), filter.maxAmount);
  const minAmount = useTextInput(amountValidator(), filter.minAmount);
  const sortOrder = useTextInput(okValidator(), filter.sortOrder);
  const status = useTextInput(okValidator(), filter.status);
  const onHideButtonClick = async () => {
    await onHide();
  };
  const onApplyFilter = async () => {
    if (maxAmount.validate().hasError || minAmount.validate().hasError) {
      return;
    }
    await setFilter({
      bank: bank.value,
      bankAgency: bankAgency.value,
      country: country.value,
      maxAmount: maxAmount.value,
      minAmount: minAmount.value,
      sortOrder: sortOrder.value,
      status: status.value,
    });
    await onHide();
  };
  const onClearFilter = async () => {
    await setFilter(defaultFilter);
    await onHide();
  };
  return (
    <DropDown
      anchor={anchor}
      className={classNames(className, "payment-list-filter")}
      onHide={onHide}
      origin={origin}
    >
      <DropDownHeader>
        <h4>Filtrar y ordenar</h4>
        <RoundedButton onClick={onHideButtonClick}>
          <Icon icon="close" />
        </RoundedButton>
      </DropDownHeader>
      <DropDownContent>
        <Country value={country.value} onChange={country.update} />
        <SortOrder value={sortOrder.value} onChange={sortOrder.update} />
        <Status value={status.value} onChange={status.update} />
        <BankFilter value={bank.value} onChange={bank.update} />
        <BankAgencyFilter
          value={bankAgency.value}
          onChange={bankAgency.update}
        />
        <AmountFilter
          hasMaxAmountError={maxAmount.hasError}
          hasMinAmountError={minAmount.hasError}
          maxAmountErrorMessage={maxAmount.errorMessage}
          maxAmountValue={maxAmount.value}
          minAmountErrorMessage={minAmount.errorMessage}
          minAmountValue={minAmount.value}
          onMaxAmountChange={maxAmount.update}
          onMinAmountChange={minAmount.update}
        />
        <Buttons onApplyFilter={onApplyFilter} onClearFilter={onClearFilter} />
      </DropDownContent>
    </DropDown>
  );
};

/* reac lazy uses default export */
export default ListFilter;

export const Buttons = ({ onApplyFilter, onClearFilter }) => {
  return (
    <fieldset className="buttons">
      <Button className="clear-filter" onClick={onClearFilter}>
        <ButtonLabel>Limpiar filtros</ButtonLabel>
      </Button>
      <ContainedButton className="apply-filter" onClick={onApplyFilter}>
        <ButtonLabel>Aplicar filtros</ButtonLabel>
      </ContainedButton>
    </fieldset>
  );
};

export const Status = ({ value, onChange }) => {
  const includesProcessing = value.includes(FILTER_STATUS_PROCESSING);
  const includesNotFound = value.includes(FILTER_STATUS_NOT_FOUND);
  const onProcessingClick = () => {
    if (includesProcessing) {
      onChange(value.filter((v) => v !== FILTER_STATUS_PROCESSING));
    } else {
      onChange([...value, FILTER_STATUS_PROCESSING]);
    }
  };
  const onNotFoundClick = () => {
    if (includesNotFound) {
      onChange(value.filter((v) => v !== FILTER_STATUS_NOT_FOUND));
    } else {
      onChange([...value, FILTER_STATUS_NOT_FOUND]);
    }
  };
  return (
    <fieldset className="status">
      <h5>Estado</h5>
      <RadioGroup>
        <RadioButton
          isSelected={includesProcessing}
          onClick={onProcessingClick}
        >
          <Icon className="done" icon="done" />
          <ButtonLabel>Procesando</ButtonLabel>
          <Icon icon="sync" />
        </RadioButton>
        <RadioButton isSelected={includesNotFound} onClick={onNotFoundClick}>
          <Icon className="done" icon="done" />
          <ButtonLabel>No encontrado</ButtonLabel>
          <Icon icon="sync_problem" />
        </RadioButton>
      </RadioGroup>
    </fieldset>
  );
};

export const Country = ({ value, onChange }) => {
  const includesGt = value.includes(FILTER_COUNTRY_GT);
  const includesDo = value.includes(FILTER_COUNTRY_DO);
  const includesHn = value.includes(FILTER_COUNTRY_HN);
  const onGtClick = () => {
    if (includesGt) {
      onChange(value.filter((v) => v !== FILTER_COUNTRY_GT));
    } else {
      onChange([...value, FILTER_COUNTRY_GT]);
    }
  };
  const onDoClick = () => {
    if (includesDo) {
      onChange(value.filter((v) => v !== FILTER_COUNTRY_DO));
    } else {
      onChange([...value, FILTER_COUNTRY_DO]);
    }
  };
  const onHnClick = () => {
    if (includesHn) {
      onChange(value.filter((v) => v !== FILTER_COUNTRY_HN));
    } else {
      onChange([...value, FILTER_COUNTRY_HN]);
    }
  };
  return (
    <fieldset className="country">
      <h5>País</h5>
      <RadioGroup>
        <RadioButton isSelected={includesGt} onClick={onGtClick}>
          <Icon className="done" icon="done" />
          <ButtonLabel>GT</ButtonLabel>
          <FlagIcon country={FILTER_COUNTRY_GT} />
        </RadioButton>
        <RadioButton isSelected={includesDo} onClick={onDoClick}>
          <Icon className="done" icon="done" />
          <ButtonLabel>DO</ButtonLabel>
          <FlagIcon country={FILTER_COUNTRY_DO} />
        </RadioButton>
        
      </RadioGroup>
      <div/>
      <RadioGroup>
        
        <RadioButton isSelected={includesHn} onClick={onHnClick}>
          <Icon className="done" icon="done" />
          <ButtonLabel>HN</ButtonLabel>
          <FlagIcon country={FILTER_COUNTRY_HN} />
        </RadioButton>
      </RadioGroup>
      
    </fieldset>
  );
};

export const SortOrder = ({ value, onChange }) => {
  return (
    <fieldset className="sort-order">
      <h5>Antigüedad</h5>
      <RadioGroup>
        <RadioButton
          isSelected={value === FILTER_SORT_ORDER_OLDER}
          onClick={() => onChange(FILTER_SORT_ORDER_OLDER)}
        >
          <Icon icon="done" />
          <ButtonLabel>Más antigüos</ButtonLabel>
        </RadioButton>
        <RadioButton
          isSelected={value === FILTER_SORT_ORDER_RECENT}
          onClick={() => onChange(FILTER_SORT_ORDER_RECENT)}
        >
          <Icon icon="done" />
          <ButtonLabel>Más Recientes</ButtonLabel>
        </RadioButton>
      </RadioGroup>
    </fieldset>
  );
};

export const BankFilter = ({ onChange, value }) => {
  const onCheckBoxChange = async (bank, selected) => {
    if (bank && selected && !value.includes(bank)) {
      onChange([...value, bank]);
    } else if (bank && !selected) {
      onChange(value.filter((item) => item !== bank));
    }
  };
  return (
    <fieldset className="bank">
      <h5>Banco</h5>
      {Object.values(paymentBanks).map((bank, key) => (
        <CheckBox
          key={key}
          onChange={(selected) => onCheckBoxChange(bank, selected)}
          checked={value.includes(bank)}
        >
          <CheckBoxLabel>{bank}</CheckBoxLabel>
        </CheckBox>
      ))}
    </fieldset>
  );
};

export const BankAgencyFilter = ({ onChange, value }) => {
  const onCheckBoxChange = async (bankAgencyType, selected) => {
    if (bankAgencyType && selected && !value.includes(bankAgencyType)) {
      onChange([...value, bankAgencyType]);
    } else if (bankAgencyType && !selected) {
      onChange(value.filter((item) => item !== bankAgencyType));
    }
  };
  return (
    <fieldset className="bank-agency-type">
      <h5>Tipo de depósito</h5>
      {Object.values(bankAgencyTypes).map((bankAgencyType, key) => (
        <CheckBox
          key={key}
          onChange={(selected) => onCheckBoxChange(bankAgencyType, selected)}
          checked={value.includes(bankAgencyType)}
        >
          <CheckBoxLabel>{bankAgencyTypeLabels[bankAgencyType]}</CheckBoxLabel>
        </CheckBox>
      ))}
    </fieldset>
  );
};

export const AmountFilter = ({
  hasMaxAmountError,
  hasMinAmountError,
  maxAmountErrorMessage,
  maxAmountValue,
  minAmountErrorMessage,
  minAmountValue,
  onMaxAmountChange,
  onMinAmountChange,
}) => {
  return (
    <fieldset className="amount">
      <h5>Monto</h5>
      <TextInputLayout className="min-amount">
        <TextInputEditText
          hasError={hasMinAmountError}
          onChange={onMinAmountChange}
          value={minAmountValue}
        >
          <TextInputLabel>Q min</TextInputLabel>
        </TextInputEditText>
        <TextInputErrorMessage>{minAmountErrorMessage}</TextInputErrorMessage>
      </TextInputLayout>
      <TextInputLayout className="max-amount">
        <TextInputEditText
          hasError={hasMaxAmountError}
          onChange={onMaxAmountChange}
          value={maxAmountValue}
        >
          <TextInputLabel>Q max</TextInputLabel>
        </TextInputEditText>
        <TextInputErrorMessage>{maxAmountErrorMessage}</TextInputErrorMessage>
      </TextInputLayout>
    </fieldset>
  );
};
