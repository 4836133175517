import { reducer as auth } from "./auth/slice";
import { reducer as banks } from "./banks/slice";
import { reducer as globals } from "./globals/slice";
import { reducer as loans } from "./loans/slice";
import { reducer as payments } from "./payments/slice";
import { reducer as sidebar } from "./sidebar/slice";
import { reducer as users } from "./users/slice";
import { reducer as request } from "./request/slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const requestPersisted = persistReducer(persistConfig, request);

export const reducer = {
  auth,
  banks,
  globals,
  loans,
  payments,
  sidebar,
  users,
  request: requestPersisted,
};
