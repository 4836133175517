import { useModalView } from "../../../hooks/usemodalview";
import { RoundedButton } from "../../controls/button/button";
import { Icon } from "../../controls/icon/icon";
import { ItemCount } from "../../globals/itemcount/itemcount";
import { anchorToBottomRightOf } from "../../globals/modalviews/anchors";
import { Header } from "../../layout/layout";
import "./listheader.css";

export const ListHeader = ({ payments, onReload }) => {
  const [revealFilters] = useModalView();
  const onRevealFiltersButtonClick = async ({ target }) => {
    await revealFilters({
      ...anchorToBottomRightOf(target),
      component: "payments/list/listfilter",
    });
  };
  return (
    <Header>
      <hgroup>
        <h2>Pagos en proceso</h2>
        <p>
          <ItemCount count={payments.length} />
        </p>
      </hgroup>
      <div className="buttons">
        <RoundedButton onClick={onReload}>
          <Icon icon="sync" />
        </RoundedButton>
        <RoundedButton onClick={onRevealFiltersButtonClick}>
          <Icon icon="filter_list" />
        </RoundedButton>
      </div>
    </Header>
  );
};
