import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, Cell, Row } from "@material/react-layout-grid";
import VanaMasLogo from "../../request/details/request/vanamas";
import SideBar from "../../sidebar/sidebar";
import Fields from "../../../common/fields/fields";
import Progress from "../../../common/progress/progress";
import Card from "../../../common/card/card";
import CardContent from "../../../common/card/cardcontent";
import Contact from "./contact/contact";
import Request from "./request/request";
import State from "./state/state";
import User from "./user/user";
import { fields } from "./fields";
import { compose } from "../../../../util/compose";
import { withApp } from "../../../app/app";
import { withDataLayer } from "../../../datalayer/datalayer";
import "@material/react-card/dist/card.css";
import "@material/react-typography/dist/typography.css";
import "@material/react-text-field/dist/text-field.css";
import "@material/react-layout-grid/dist/layout-grid.css";
import Work from "../../user/details/work/work";
import Flag from "../../../user/flag/flag";
import UserVerified from "../../../user/certified/certified";

class Details extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func,
  };

  state = {
    loading: false,
    request: null,
  };

  componentDidMount() {
    !this.state.request && this.getRequest();
  }

  onStateUpdate(status, isSoftReject) {
    const { app, dataLayer, t } = this.props;
    const { request } = this.state;
    return dataLayer.requests
      .updateRequestStatus(
        request.loan_request_id,
        request.user_id,
        status,
        isSoftReject ?? false
      )
      .then(() => {
        app.onSnackbar(t("Loan request status updated"));
        this.getRequest();
      })
      .catch((error) =>
        app.onError({
          text: t("Error updating request status"),
          data: error.details,
        })
      );
  }

  getRequest() {
    const { app, dataLayer, match, t } = this.props;
    const update = {};
    this.setState({ loading: true });
    return dataLayer.requests
      .getRequest(match.params.loanRequestId, true)
      .then(async (request) => {
        if (
          request.state.rejected_until &&
          request.state.status === "rejected"
        ) {
          request.state.status = "soft_rejected";
        }
        update.request = request;
        this.setState(update);
      })
      .catch((error) =>
        app.onError({
          text: t("Error fetching request data."),
          data: error.details,
        })
      )
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { t } = this.props;
    const { request, loading } = this.state;
    const data = {};
    const loanId = request?.state?.loan_id;
    const state = request?.user?.state.id_number_status;
    const confirmed = "confirmed";

    Object.keys(fields).forEach((key) => {
      data[key] = Object.assign(
        { value: request ? request[fields[key].key] : "" },
        fields[key]
      );
    });
    if (loanId) {
      data["loan_id"] = {
        columns: 12,
        type: "anchor",
        value: loanId,
        url: () => `/loans/details/${request.loan_request_id}`,
        label: t("ID de crédito"),
      };
    }
    return (
      <Progress
        className="request details sidebar-layout"
        loading={!request || loading}
        text={t("Fetching request details")}
      >
        {() => {
          const personal = request.user.personal || {};
          const document = personal.document || {};
          const contact = request.user.referrer
            ? request.user.referrer.contacts
            : {};
          return [
            <div key="content" className="profile content">
              <Grid>
                <Cell columns={12}>
                  <Row>
                    <Cell columns={12}>
                      <Row>
                        <Cell columns={12}>
                          <Row>
                            <Cell columns={12}>
                              <div className="title-wrapper">
                                <Flag
                                  country={personal?.country}
                                  className="flag"
                                />
                                <span className="title-1">
                                  {t("Request details")}
                                </span>
                                {state === confirmed && <UserVerified />}
                                {request !== null &&
                                  request.state.offer_type ===
                                    "short_term_installments" && (
                                    <VanaMasLogo />
                                  )}
                              </div>
                            </Cell>
                            <Cell columns={12}>
                              <Card>
                                <CardContent>
                                  <Fields data={data} />
                                </CardContent>
                              </Card>
                            </Cell>
                            <Cell columns={12}>
                              <User
                                data={{
                                  id_number: personal.id_number,
                                  first_name: personal.first_name,
                                  last_name: personal.last_name,
                                  selfie: document.selfie,
                                  front: document.front,
                                  back: document.back,
                                }}
                              />
                            </Cell>
                            <Cell columns={12}>
                              <Work data={request?.user?.work || {}}></Work>
                            </Cell>
                            <Cell columns={12}>
                              <Request data={request} />
                            </Cell>

                            <Contact
                              data={contact}
                              onUpdate={this.onStateUpdate.bind(this)}
                            />

                            <Cell columns={12}>
                              <State
                                data={request.state}
                                onUpdate={this.onStateUpdate.bind(this)}
                              />
                            </Cell>
                          </Row>
                        </Cell>
                      </Row>
                    </Cell>
                  </Row>
                </Cell>
              </Grid>
            </div>,
            <SideBar key="sidebar" user={request.user} />,
          ];
        }}
      </Progress>
    );
  }
}

export default compose(Details)(
  withApp,
  withDataLayer,
  withRouter,
  withTranslation()
);
