import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  bankAgencyTypeLabels,
  bankAgencyTypeOptions,
} from "../../../domain/banks/bankagencytype";
import { useSelector } from "react-redux";
import { selectUserById } from "../../../redux/users/selectors";
import {
  paymentBankLabels,
  paymentBankOptions,
  paymentBankOriginLabels,
  paymentBankOriginOptions,
} from "../../../domain/payments/paymentbanks";
import { paymentSettings } from "../../../domain/payments/paymentsettings";
import {
  paymentStatus,
  paymentStatusLabels,
} from "../../../domain/payments/paymentstatus";
import { useModalView } from "../../../hooks/usemodalview";
import {
  usePaymentAmountTextInput,
  usePaymentBankAgencyTextInput,
  usePaymentBankTextInput,
  usePaymentBankOriginTextInput,
  usePaymentDateTextInput,
  usePaymentReferenceTextInput,
} from "../../../hooks/usetextinput";
import {
  addHoursToDateString,
  formatDate,
  timezoneByCountry,
  localeByCountry,
} from "../../../util/dateutils";
import { Conditional } from "../../components";
import {
  Button,
  ButtonLabel,
  RoundedButton,
} from "../../controls/button/button";
import { DatePickerInput } from "../../controls/datepicker/datepickerinput";
import { Icon } from "../../controls/icon/icon";
import { AutoCompleteTextView } from "../../controls/textinput/autocompletetextview";
import {
  TextInputEditText,
  TextInputLabel,
} from "../../controls/textinput/textinput";
import {
  TextInputErrorMessage,
  TextInputLayout,
} from "../../controls/textinput/textinputlayout";
import { Header, Section } from "../../layout/layout";
import "./update.css";
import { MODULE_PAYMENT_ACTIVE } from "../../globals/variables/variables";

export const PaymentStatus = ({ payment, locale, timezone }) => {
  let icon = "";
  let status = payment.status;
  const currentDate = formatDate(
    new Date().toLocaleString(locale, {
      timeZone: timezone,
    }),
    null,
    true
  );
  const dateCreatedAt = formatDate(
    new Date(payment.createdAt).toLocaleString(locale, {
      timeZone: timezone,
    }),
    null,
    true
  );

  const addHoursToDate = addHoursToDateString(
    dateCreatedAt,
    paymentSettings.NOT_FOUND_AFTER_HOURS
  );

  switch (payment.status) {
    case paymentStatus.PROCESSING:
      if (addHoursToDate < currentDate) {
        status = "not_found";
        icon = "sync_problem";
      } else {
        icon = "sync";
      }
      break;
    case paymentStatus.UPLOADING:
      icon = "sync";
      break;
    case paymentStatus.CREATED:
      icon = "sync";
      break;
    default:
      icon = "";
      break;
  }
  return (
    <span className={classNames("status", `status-${status}`)}>
      <Icon icon={icon} />
      <span className="label">{paymentStatusLabels[status]}</span>
    </span>
  );
};

export const AgencyField = ({
  errorMessage,
  onChange,
  value,
  hasError,
  isDisabled,
}) => {
  return (
    <TextInputLayout className="agency">
      <AutoCompleteTextView
        isDisabled={isDisabled}
        hasError={hasError}
        options={bankAgencyTypeOptions}
        onChange={onChange}
        value={
          value && bankAgencyTypeLabels[value]
            ? bankAgencyTypeLabels[value]
            : value
        }
      >
        <TextInputLabel>Método de pago</TextInputLabel>
      </AutoCompleteTextView>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const AmountField = ({
  errorMessage,
  onChange,
  hasError,
  value,
  isDisabled,
}) => {
  return (
    <TextInputLayout className="amount">
      <TextInputEditText
        isDisabled={isDisabled}
        hasError={hasError}
        onChange={onChange}
        value={value}
      >
        <TextInputLabel>Cantidad</TextInputLabel>
      </TextInputEditText>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const BankField = ({
  errorMessage,
  hasError,
  isDisabled,
  onChange,
  value,
  label,
  list,
  options,
}) => {
  return (
    <TextInputLayout className="bank">
      <AutoCompleteTextView
        isDisabled={isDisabled}
        hasError={hasError}
        options={options}
        onChange={onChange}
        value={value && list[value] ? list[value] : value}
      >
        <TextInputLabel>{label}</TextInputLabel>
      </AutoCompleteTextView>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const DateField = ({
  errorMessage,
  onChange,
  value,
  hasError,
  isDisabled,
}) => {
  return (
    <TextInputLayout className="date">
      <DatePickerInput
        isDisabled={isDisabled}
        hasError={hasError}
        onChange={onChange}
        value={value}
      >
        <TextInputLabel>Fecha</TextInputLabel>
      </DatePickerInput>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const ReferenceField = ({
  errorMessage,
  onChange,
  hasError,
  isDisabled,
  value,
}) => {
  return (
    <TextInputLayout className="reference">
      <TextInputEditText
        isDisabled={isDisabled}
        hasError={hasError}
        onChange={onChange}
        value={value}
      >
        <TextInputLabel>No. de referencia</TextInputLabel>
      </TextInputEditText>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const Update = ({ payment }) => {
  const paymentStatusAllowedEdit = [paymentStatus.PROCESSING];
  /* modal views */
  const [confirmReject] = useModalView();
  const [confirmResolve] = useModalView();
  /* form fields */
  const agency = usePaymentBankAgencyTextInput(payment.agency);
  const amount = usePaymentAmountTextInput(payment.amount);
  const bank = usePaymentBankTextInput(payment.bank);
  const bankOrigin = usePaymentBankOriginTextInput(payment.bankOrigin);
  const date = usePaymentDateTextInput(payment.date);
  const reference = usePaymentReferenceTextInput(payment.reference);
  /* comp state */
  const [isDisabled, setIsDisabled] = useState(true);
  const [isResolving] = useState();
  const ONLINE_METHOD_PAYMENT = "online";
  const [requiredMessage, setRequiredMessage] = useState("");
  const [requiredSelect, setRequiredSelect] = useState(false);

  /* event handlers */
  const onCancelButtonClick = async () => {
    onReset();
  };
  const onEditButtonClick = async () => {
    setIsDisabled(false);
  };
  const onResolveButtonClick = async () => {
    if (!validateAll()) {
      return;
    }
    // Origin bank is required if payment agency is online
    if (
      agency.value === ONLINE_METHOD_PAYMENT &&
      bankOrigin.value === undefined
    ) {
      setRequiredSelect(true);
      setRequiredMessage("Banco origen requerido");
    } else {
      setRequiredSelect(false);
      setRequiredMessage("");
      await confirmResolve({
        component: "payments/payment/confirmresolve",
        props: {
          payment: {
            amount: amount.value,
            bank: bank.value,
            bankOrigin: bankOrigin.value,
            date: date.value,
            paymentId: payment.paymentId,
            reference: reference.value,
            agency: agency.value,
          },
        },
      });
    }
  };
  const onRejectButtonClick = async () => {
    if (!validateAll()) {
      return;
    }
    await confirmReject({
      component: "payments/payment/confirmreject",
      props: {
        payment: {
          amount: amount.value,
          bank: bank.value,
          date: date.value,
          paymentId: payment.paymentId,
          reference: reference.value,
        },
      },
    });
  };
  const onSubmit = async (event) => {
    event.preventDefault();
  };
  const onReset = async () => {
    setIsDisabled(true);
    agency.init(payment.agency);
    amount.init(payment.amount);
    bank.init(payment.bank);
    bankOrigin.init(payment.bankOrigin);
    date.init(payment.date);
    reference.init(payment.reference);
  };
  const user = useSelector(selectUserById(payment.userId));

  const timezone = timezoneByCountry[user?.personal?.country || "GT"];
  const locale = localeByCountry[user?.personal?.country || "GT"];

  const validateAll = () => {
    return [agency, amount, bank, bankOrigin, date, reference].reduce(
      (prev, next) => next.validate().isOk && prev,
      true
    );
  };
  useEffect(() => {
    /* reset fields on payment id change */
    onReset();
    // eslint-disable-next-line
  }, [
    payment.agency,
    payment.amount,
    payment.bank,
    payment.bankOrigin,
    payment.date,
    payment.reference,
    payment.status,
  ]);

  return (
    <Section className="update" isPending={isResolving}>
      <form
        className={classNames("form", { disabled: isDisabled })}
        onSubmit={onSubmit}
      >
        <Header>
          <h5>Detalles del pago</h5>
          <PaymentStatus
            payment={payment}
            locale={locale}
            timezone={timezone}
          />
        </Header>
        <fieldset className="input-fields">
          <AgencyField
            errorMessage={agency.errorMessage}
            hasError={agency.hasError}
            isDisabled={isDisabled}
            onChange={agency.update}
            value={agency.value}
          />
          <AmountField
            errorMessage={amount.errorMessage}
            hasError={amount.hasError}
            isDisabled={isDisabled}
            onChange={amount.update}
            value={amount.value}
          />
          <BankField
            errorMessage={
              agency.value === ONLINE_METHOD_PAYMENT
                ? requiredMessage
                : bankOrigin.errorMessage
            }
            hasError={bankOrigin.hasError | requiredSelect}
            isDisabled={isDisabled}
            onChange={bankOrigin.update}
            value={bankOrigin.value}
            label="Banco origen"
            list={paymentBankOriginLabels}
            options={paymentBankOriginOptions}
          />
          <BankField
            errorMessage={bank.errorMessage}
            hasError={bank.hasError}
            isDisabled={isDisabled}
            onChange={bank.update}
            value={bank.value}
            label="Banco destino"
            list={paymentBankLabels}
            options={paymentBankOptions}
          />
          <DateField
            errorMessage={date.errorMessage}
            hasError={date.hasError}
            isDisabled={isDisabled}
            onChange={date.update}
            value={date.value}
          />
          <ReferenceField
            errorMessage={reference.errorMessage}
            hasError={reference.hasError}
            isDisabled={isDisabled}
            onChange={reference.update}
            value={reference.value}
          />
        </fieldset>
        <fieldset className="buttons">
          <Conditional
            condition={
              isDisabled && paymentStatusAllowedEdit.includes(payment.status)
            }
          >
            {!MODULE_PAYMENT_ACTIVE && (
              <Button className="edit" onClick={onEditButtonClick}>
                <ButtonLabel>Editar datos</ButtonLabel>
              </Button>
            )}
          </Conditional>
          <Conditional condition={!isDisabled}>
            <Button className="cacel" onClick={onCancelButtonClick}>
              <ButtonLabel>Cancelar</ButtonLabel>
            </Button>
            <span className="blank" />
            <Button className="reject" onClick={onRejectButtonClick}>
              <ButtonLabel>Rechazar</ButtonLabel>
            </Button>
            <RoundedButton className="resolve" onClick={onResolveButtonClick}>
              <ButtonLabel>Aprobar</ButtonLabel>
            </RoundedButton>
          </Conditional>
        </fieldset>
      </form>
    </Section>
  );
};
