import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBalanceByLoanId } from "../../../redux/loans/selectors";
import { getLoanBalance } from "../../../redux/loans/thunks/getloanbalance";
import { selectUserById } from "../../../redux/users/selectors";
import { getUserById } from "../../../redux/users/thunks/getuserbyid";
import { Card, CardContent, CardHeader } from "../../layout/card/card";
import { BalanceInstallmentList } from "./balance-installment-list";
import { BalanceSummary } from "./balance-summary";
import "./balance.css";

export const Balance = ({ loanId, userId, dailyFeeData }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserById(userId));
  const balance = useSelector(selectBalanceByLoanId(loanId));

  useEffect(() => {
    if (loanId && !balance.$isPending && !balance.$isFulfilled) {
      dispatch(getLoanBalance({ loanId, userId }));
    }
  }, [dispatch, balance.$isFulfilled, balance.$isPending, loanId, userId]);
  useEffect(() => {
    if (userId && !user.$isPending && !user.$isRejected && !user.$isFulfilled) {
      dispatch(getUserById({ userId }));
    }
  }, [dispatch, userId, user.$isPending, user.$isRejected, user.$isFulfilled]);
  return (
    <Card className="loan-balance">
      <CardHeader>
        <h4>Pagos</h4>
      </CardHeader>
      <CardContent isPending={!balance.$isFulfilled}>
        <BalanceSummary
          country={user?.personal?.country}
          details={balance?.details}
          dailyFeeData={dailyFeeData}
          {...balance?.summary}
        />
        <BalanceInstallmentList
          country={user?.personal?.country}
          installments={balance?.installments}
        />
      </CardContent>
    </Card>
  );
};
