export const fields = {
  bank: {
    key: "bank",
    label: "Bank",
    icon: "lock",
  },
  name: {
    key: "name",
    label: "Name",
    icon: "lock",
  },
  number: {
    key: "number",
    label: "Account number",
    icon: "lock",
  },
  type: {
    key: "type",
    label: "Account type",
    icon: "lock",
  },
};
