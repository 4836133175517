import React from "react";
import Routes from "../routes/routes";
import AppContent from "./appcontent/appcontent";
import AppDrawer from "./appdrawer/appdrawer";
import "./applayout.css";

function AppLayout() {
  return (
    <div className="app-layout">
      <AppDrawer />
      <AppContent>
        <Routes />
      </AppContent>
    </div>
  );
}

export default AppLayout;
