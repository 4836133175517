export function handleError(error) {
  if (error.response) {
    error.details = {
      message: error.message,
      status: error.response.status,
      headers: error.response.headers,
      body: error.response.data
    };
  } else if (error.request) {
    error.details = { message: error.message, request: error.request };
  } else {
    error.details = { message: error.message };
  }
  throw error;
}
