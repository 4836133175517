export const selectBalanceByLoanId = (loanId) => (store) => {
  return loanId && store.loans.balances && store.loans.balances[loanId]
    ? { ...store.loans.balances[loanId] }
    : "";
};

export const selectBalanceByUserId = (userId) => (store) => {
  const balance = (
    store?.loans?.balances ? Object.values(store.loans.balances) : []
  ).find((balance) => balance?.userId === userId);
  return balance ? balance : "";
};
