import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import MaterialIcon from '@material/react-material-icon';
import { Corner } from '@material/react-menu-surface';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemGraphic,
  MenuListItemText,
} from '@material/react-menu';

import { compose } from '../../../util/compose';
import { withLanguage } from '../../language/language';
import { withAuth } from '../../auth/auth';

import '@material/react-material-icon/dist/material-icon.css';
import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-menu-surface/dist/menu-surface.css';

class AppBarMenu extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    clearToken: PropTypes.func,
  };
  state = {
    open: false,
    anchor: null,
  };
  onClose() {
    this.setState({ open: false });
  }
  onLocaleClick() {
    const { locale, changeLocale } = this.props.language;
    changeLocale(locale === 'es' ? 'en' : 'es');
  }
  setAnchorElement(anchor) {
    !this.state.anchor && this.setState({ anchor });
  }
  toggleMenu(event) {
    const update = { open: !this.state.open };
    if (!this.state.coordinates) {
      update.coordinates = { x: event.clientX, y: event.clientY };
    }
    this.setState(update);
  }
  render() {
    const lang = this.props.language.locale === 'en' ? 'Español' : 'English';
    return (
      <div
        className="mdc-menu-surface--anchor"
        ref={this.setAnchorElement.bind(this)}
      >
        <MaterialIcon
          hasRipple
          aria-label={this.props.t('More')}
          icon="arrow_drop_down"
          onClick={this.toggleMenu.bind(this)}
        />
        <Menu
          anchorCorner={Corner.BOTTOM_LEFT}
          coordinates={this.state.coordinates}
          onClose={this.onClose.bind(this)}
          open={this.state.open}
        >
          <MenuList>
            <MenuListItem handleClick={this.onLocaleClick.bind(this)}>
              <MenuListItemGraphic graphic={<MaterialIcon icon="language" />} />
              <MenuListItemText primaryText={lang} />
            </MenuListItem>
            <MenuListItem handleClick={this.props.clearToken}>
              <MenuListItemGraphic
                graphic={<MaterialIcon icon="power_settings_new" />}
              />
              <MenuListItemText primaryText={this.props.t('Logout')} />
            </MenuListItem>
          </MenuList>
        </Menu>
      </div>
    );
  }
}

export default compose(AppBarMenu)(withAuth, withLanguage, withTranslation());
