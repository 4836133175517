import { dateFormats, formatDate } from "../../../util/dateutils";
import { Currency } from "../../globals/currency/currency";
import Card from "../../common/card/card";
import CardContent from "../../common/card/cardcontent";
import CardHeader from "../../common/heading";

const DETAIL_LATE_FEE = ["Mora (", "Cargos por atraso"];

export const BalanceSummaryProperty = ({ amount, country, title, text }) => {
  return (
    <div>
      <dt>
        <span>{title}</span>
      </dt>
      <dd>
        {text !== undefined ? (
          <span className="text-data">{text}</span>
        ) : (
          <Currency amount={amount} country={country} />
        )}
      </dd>
    </div>
  );
};

export const BalanceSummary = ({
  total,
  offerDiscounts,
  feeDiscount,
  interestAmount,
  fixedFee,
  settled,
  balance,
  lateFee,
  date,
  daysLate,
  country,
  details,
  lateFeeComplement,
  processingFee,
}) => {
  // Handle details
  const handleTotalPreMoraByCountry = (
    country,
    lateFee,
    offerDiscount,
    processingFee
  ) => {
    let amount;

    switch (country) {
      case "GT":
        amount = total - (lateFee + (offerDiscount ?? 0));
        break;
      case "DO":
        amount = total - (fixedFee + lateFee) - processingFee;
        break;
      case "HN":
        amount = total - (fixedFee + lateFee);
        break;
      default:
        amount = total - (lateFee + (offerDiscount ?? 0));
        break;
    }

    return amount;
  };

  let detailLateFee;
  if (details) {
    details.forEach((item) => {
      if (DETAIL_LATE_FEE.some((text) => item.text.includes(text))) {
        detailLateFee = item;
      }
    });
  }

  const lateFeeHandled = detailLateFee?.amount ?? 0; // This include late_fee_complement in DO

  const lateFeeComplementValue =
    lateFeeComplement === undefined || lateFeeComplement === null
      ? 0
      : lateFeeComplement;

  const totalPreMora = handleTotalPreMoraByCountry(
    country,
    lateFeeHandled,
    offerDiscounts,
    processingFee
  );

  const expensesAdminInterest =
    country === "GT" ? interestAmount : fixedFee + interestAmount;

  const dailyFeeLate =
    lateFee > 0 && daysLate > 0
      ? Math.round((lateFee + lateFeeComplementValue) / (daysLate + 1))
      : 0;

  const settledHandled =
    settled - (offerDiscounts ?? 0) - (country === "GT" ? 0 : feeDiscount ?? 0);

  return (
    <dl className="summary">
      <div className="d-flex">
        <BalanceSummaryProperty
          title="Total pre mora"
          toolTipComponent="loan/balance/total-tool-tip"
          amount={totalPreMora}
          country={country}
        />
        <BalanceSummaryProperty
          title="Mora"
          toolTipComponent="loan/balance/late-fee-tool-tip"
          amount={detailLateFee?.amount ?? lateFee + lateFeeComplementValue}
          country={country}
        />
        <BalanceSummaryProperty
          title="Pagado"
          toolTipComponent="loan/balance/settled-tool-tip"
          amount={settledHandled}
          country={country}
        />
        <BalanceSummaryProperty
          title="Pendiente de pago"
          toolTipComponent="loan/balance/balance-tool-tip"
          amount={balance}
          country={country}
        />
        <BalanceSummaryProperty
          title="Días de atraso"
          toolTipComponent="loan/balance/fee-discount-tool-tip"
          text={daysLate}
          country={country}
        />
        <BalanceSummaryProperty
          title="Mora diaria"
          toolTipComponent="loan/balance/late-fee-tool-tip"
          amount={dailyFeeLate}
          country={country}
        />
        <BalanceSummaryProperty
          title="Mora Primer dia"
          toolTipComponent=""
          amount={dailyFeeLate * 2 ?? 0}
          country={country}
        />
      </div>
      {country === "DO" && (
        <div className="d-block">
          <Card className="collapse-payment" isDefaultCollapse>
            <CardHeader
              title={"Desglose de pago"}
              titleNote={""}
              subtitle={""}
              className="title-test"
            />
            <CardContent>
              <div className="container-collapse">
                <div className="collapse-row">
                  <span className="collapse-data">Capital</span>
                  <span className="collapse-data">
                    <Currency
                      amount={
                        (totalPreMora ?? 0) - interestAmount - processingFee
                      }
                      country={country}
                    />
                  </span>
                </div>
                <div className="collapse-row">
                  <span className="collapse-data">
                    Gastos administrativos e interés
                  </span>
                  <span className="collapse-data">
                    <Currency
                      amount={expensesAdminInterest}
                      country={country}
                    />
                  </span>
                </div>
                <div className="collapse-row">
                  <span className="collapse-data">Mora</span>
                  <span className="collapse-data">
                    <Currency
                      amount={detailLateFee?.amount ?? lateFee}
                      country={country}
                    />
                  </span>
                </div>
                <div className="collapse-row">
                  <span className="collapse-data">
                    Total antes de descuentos
                  </span>
                  <span className="collapse-data">
                    <Currency amount={total} country={country} />
                  </span>
                </div>
                <div className="collapse-row">
                  <span className="collapse-data">
                    Descuento por inclusión financiera
                  </span>
                  <span className="collapse-data-right">
                    <Currency amount={feeDiscount ?? 0} country={country} />
                  </span>
                </div>
                <div className="collapse-row">
                  <span className="collapse-data">Pagado</span>
                  <span className="collapse-data-right">
                    <Currency amount={settledHandled} country={country} />
                  </span>
                </div>
                <div className="collapse-row">
                  <span className="collapse-data bold">Total a pagar</span>
                  <span className="collapse-data-right bold">
                    <Currency amount={balance} country={country} />
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <div>
        <dt>
          <span>Siguiente fecha de pago</span>
        </dt>
        <dd>{formatDate(date, dateFormats.D_OF_MMMM_OF_YYYY)}</dd>
      </div>
    </dl>
  );
};
