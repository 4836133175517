import React from "react";
import PropTypes from "prop-types";
import Menu from "./menu/menu";
import "./appdrawer.css";

function AppDrawer(props) {
  return (
    <div className="app-drawer">
      <Menu toggle={props.toggle} />
    </div>
  );
}

AppDrawer.propTypes = {
  onToggle: PropTypes.func,
  toggle: PropTypes.object,
};
AppDrawer.defaultProps = {
  onToggle: () => {},
  toggle: { menu: false, collections: false },
};

export default AppDrawer;
