import React from "react";
import CardActionIcon from "./cardactionicon";
import PropTypes from "prop-types";
import "./cardtoggle.css";

const CardToggle = (props) => {
  return (
    <div className="toggle-container">
      <div className="toggle-actions">
        <div className="toggle-icons">
          <CardActionIcon
            className={`toggle-icon ${props.collapsed ? "collapsed" : ""}`}
            icon={"expand_less"}
            onClick={() => props.onToggle(props.collapsed ? false : true)}
          />
        </div>
      </div>
    </div>
  );
};

CardToggle.ProtoTypes = {
  onToggle: PropTypes.func,
  collapsed: PropTypes.bool,
};

export default CardToggle;
