import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TextField, { Input } from "@material/react-text-field";
import Select, { Option } from "@material/react-select";
import MaterialIcon from "@material/react-material-icon";

import "@material/react-material-icon/dist/material-icon.css";
import "@material/react-menu-surface/dist/menu-surface.css";
import "@material/react-menu/dist/menu.css";
import "@material/react-select/dist/select.css";
import "@material/react-text-field/dist/text-field.css";
import "@material/react-material-icon/dist/material-icon.css";
import "./select.css";

const styles = {
  textField: {
    width: "100%",
  },
};

class SelectField extends React.Component {
  optionsFiltered = [];

  static propTypes = {
    data: PropTypes.object,
    dataStore: PropTypes.object,
    readonly: PropTypes.bool,
    validateFrom: PropTypes.bool,
    options: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    prevValue: PropTypes.string,
  };

  static defaultProps = {
    data: {},
    dataStore: {},
    options: [],
    readonly: true,
    validateFrom: false,
    value: "",
    prevValue: "",
    onChange: (update, data) => {},
  };

  render() {
    const { readonly, value, label, t, prevValue } = this.props;
    let options = [];

    if (prevValue !== null) {
      options = this.getOptionsFilterd(prevValue.value);
    }else {
      options = this.getOptionsFilterd(value);
    }
  
    return readonly ? (
      <TextField
        label={t(label)}
        style={styles.textField}
        trailingIcon={<MaterialIcon icon="lock" />}
      >
        <Input disabled value={t(this.getValueLabel())} />
      </TextField>
    ) : (
      <Select
        enhanced
        label={t(label)}
        value={value}
        onEnhancedChange={this.onChange.bind(this)}
      >
        {options.map((option, key) => (
          <Option key={key} value={option.value}>
            {t(option.label)}
          </Option>
        ))}
      </Select>
    );
  }

  getValueLabel() {
    let label = this.props.value;
    this.props.options.forEach((option) => {
      if (option.value === this.props.value) label = option.label;
    });
    return label;
  }

  getOptionsFilterd(value) {
    const filtered = this.props.options.filter(
      (option) =>
        (this.props.validateFrom &&
          (option.from.includes(value) || value === option.value)) ||
        !this.props.validateFrom
    );
    this.setOptionsFiltered(filtered);
    return filtered;
  }

  setOptionsFiltered(filtered) {
    this.optionsFiltered = filtered;
  }

  onChange(index) {
    this.props.onChange(this.optionsFiltered[index].value, this.props.data);
  }
}

export default withTranslation()(SelectField);
