import classNames from "classnames";
import React, { useState } from "react";
import { Conditional } from "../../components";
import { TextTrim } from "../../globals/texttrim/texttrim";
import { Icon } from "../icon/icon";
import "./textinput.css";

export const TextInputEditText = ({
  children,
  className,
  hasError,
  isDisabled,
  onChange,
  value = "",
  ...otherProps
}) => {
  /* compute layout flags */
  let hasLeadingIcon = false;
  let hasTrailingIcon = false;
  React.Children.forEach(children, (child) => {
    if (child && child.type === TextInputLeadingIcon) {
      hasLeadingIcon = true;
    }
    if (child && child.type === TextInputTrailingIcon) {
      hasTrailingIcon = true;
    }
  });
  /* comp state */
  const [hasFocus, setHasFocus] = useState();
  /* event handlers */
  const onFocus = () => {
    setHasFocus(true);
  };
  const onBlur = () => {
    setHasFocus(false);
  };
  const onInputChange = ({ target }) => {
    onChange && onChange(target.value ? target.value.trim() : "");
  };
  return (
    <div
      {...otherProps}
      className={classNames(className, "edit-text", {
        "is-disabled": isDisabled,
        "has-focus": hasFocus,
        "has-error": hasError,
        "has-value": value,
        "has-leading-icon": hasLeadingIcon,
        "has-trailing-icon": hasTrailingIcon || hasError,
      })}
    >
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onInputChange}
        className="input"
        type="text"
        value={value}
        disabled={isDisabled}
      />
      <div className="buttons">
        {children}
        <Conditional condition={hasError && !hasTrailingIcon}>
          <TextInputTrailingIcon>
            <Icon icon="info" />
          </TextInputTrailingIcon>
        </Conditional>
      </div>
    </div>
  );
};

export const TextInputLabel = ({
  children,
  className,
  label,
  maxLength,
  ...otherProps
}) => {
  return (
    <div {...otherProps} className={classNames(className, "label")}>
      <TextTrim maxLength={maxLength}>{children || label}</TextTrim>
    </div>
  );
};

export const TextInputLeadingIcon = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <div {...otherProps} className={classNames(className, "leading-icon")}>
      {children}
    </div>
  );
};

export const TextInputLeadingText = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <div {...otherProps} className={classNames(className, "leading-text")}>
      {children}
    </div>
  );
};

export const TextInputTrailingIcon = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <div {...otherProps} className={classNames(className, "trailing-icon")}>
      {children}
    </div>
  );
};

export const TextInputTrailingText = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <div {...otherProps} className={classNames(className, "trailing-text")}>
      {children}
    </div>
  );
};
