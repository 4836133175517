import classNames from "classnames";
import { useEffect, useState } from "react";
import "./ripple.css";

const ANIMATION_DURATION = 360;

const RippleCircle = ({ left, top, isRippling }) =>
  isRippling ? (
    <span
      className="ripple-circle"
      style={{
        animationDuration: `${ANIMATION_DURATION}ms`,
        left,
        top,
      }}
    />
  ) : null;

export const sleep = async (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const Ripple = ({
  className,
  component,
  onClick,
  children,
  light,
  dark,
  isDisabled,
  ...otherProps
}) => {
  const [isRippling, setIsRippling] = useState(false);
  const [left, setLeft] = useState(-1);
  const [top, setTop] = useState(-1);
  const Wrap = component || "div";
  const onComponentClick = async (event) => {
    if (isDisabled) {
      return;
    }
    const { left, top } = event.target.getBoundingClientRect();
    setLeft(event.clientX - left);
    setTop(event.clientY - top);
    if (onClick) {
      event.persist(); /* user persist() because of react 16 */
      await sleep(ANIMATION_DURATION / 4);
      onClick(event);
    }
  };
  useEffect(() => {
    let timeOut = 0;
    if (top >= 0 && left >= 0) {
      setIsRippling(true);
      timeOut = setTimeout(() => {
        setIsRippling(false);
        timeOut = 0;
      }, ANIMATION_DURATION);
      return () => clearTimeout(timeOut);
    } else {
      setIsRippling(false);
    }
  }, [top, left]);
  useEffect(() => {
    if (!isRippling) {
      setLeft(-1);
      setTop(-1);
    }
  }, [isRippling]);
  return (
    <Wrap
      {...otherProps}
      className={classNames(className, "ripple", {
        "ripple-dark": dark,
        "ripple-light": light,
      })}
      onClick={onComponentClick}
    >
      {children}
      <RippleCircle isRippling={isRippling} left={left} top={top} />
    </Wrap>
  );
};

export const LightRipple = ({ children, ...props }) => (
  <Ripple {...props} light={true}>
    {children}
  </Ripple>
);

export const DarkRipple = ({ children, props }) => (
  <Ripple {...props} dark={true}>
    {children}
  </Ripple>
);
