import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import ListItemTags from "../../../common/list/item/tags/tags";
import { compose } from "../../../../util/compose";

class LoanListItemTags extends React.Component {
  static propTypes = {
    loan: PropTypes.object.isRequired,
  };
  render() {
    const tags = [];
    this.props.loan.loan_request_id ===
      this.props.loan.user?.state.active_loan_id &&
      tags.push({
        className: "active-loan",
        icon: "verified",
        title: this.props.t("Active loan"),
      });
    this.props.loan.state.status === "released" &&
      tags.push({
        className: "released",
        icon: "account_balance_wallet",
        title: this.props.t("Released"),
      });
    this.props.loan.state.status === "transferring" &&
      tags.push({
        className: "transferring",
        icon: "autorenew",
        title: this.props.t("Transferring"),
      });
    this.props.loan.state.status === "fulfilled" &&
      tags.push({
        className: "fulfilled",
        icon: "done_all",
        title: this.props.t("Fulfilled"),
      });
    this.props.loan.state.status === "archived" &&
      tags.push({
        className: "archived",
        icon: "delete",
        title: this.props.t("Archived"),
      });
    this.props.loan.state.status === "failed_transfer" &&
      tags.push({
        className: "failed-transfer",
        icon: "clear",
        title: this.props.t("Failed transfer"),
      });
    this.props.loan.state.status === "signed" &&
      tags.push({
        className: "signed",
        icon: "gesture",
        title: this.props.t("Signed"),
      });
    this.props.loan.state.late_installments > 0 &&
      tags.push({
        className: "late",
        icon: "watch_later",
        title: this.props.t("Late"),
      });
    this.props.loan.state.active_payment_id &&
      tags.push({
        className: "payments",
        icon: "payments",
        title: this.props.t("Processing payment"),
      });
    tags.push({
      className: "disbursal-method",
      icon: "account_balance",
      title:
        this.props.loan.disbursal_method === "gtc_rem"
          ? this.props.t("Cash pickup")
          : this.props.t("Bank transfer"),
    });
    return <ListItemTags tags={tags} />;
  }
}

export default compose(LoanListItemTags)(withTranslation());
