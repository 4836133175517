import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "../slice";
import { thunkTypes } from "../thunktypes";

export const updateFragment = createAsyncThunk(
  thunkTypes.UPDATE_FRAGMENT,
  async (params, { dispatch }) => {
    await dispatch(actions.mergeFragment(params));
    return params.id;
  }
);
