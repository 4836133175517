export const createAuthRepo = (authClient) => {
  const handleAuth = async () => {
    return await authClient.handleAuth();
  };
  const signOut = async () => {
    return await authClient.signOut();
  };
  return {
    handleAuth,
    signOut,
  };
};
