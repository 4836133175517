export const fields = {
  amount: {
    key: "amount",
    label: "Amount",
    icon: "lock",
    columns: 6
  },
  interest_amount: {
    key: "interest_amount",
    label: "Interest amount",
    icon: "lock",
    columns: 6
  },
  interest_rate: {
    key: "interest_rate",
    label: "Interest amount",
    icon: "lock",
    columns: 6
  },
  term: {
    key: "term",
    label: "Term",
    icon: "lock",
    columns: 6
  },
  category: {
    key: "category",
    label: "Category",
    icon: "lock",
    columns: 6
  },
  sub_category: {
    key: "sub_category",
    label: "Subcategory",
    icon: "lock",
    columns: 6
  },
  purpose: {
    key: "purpose",
    label: "Purpose",
    icon: "lock",
    columns: 6,
    textarea: "true"
  },
  disbursal_method: {
    key: "disbursal_method",
    label: "Disbursal method",
    icon: "lock",
    type: "select",
    readonly: true,
    columns: 6,
    options: [
      { label: "Cash pickup", value: "gtc_rem", from: [] },
      { label: "Bank transfer", value: "transfer", from: [] }
    ]
  },
};
