import Papa from "papaparse";
import * as XLSX from "xlsx";
const MAX_FILE_SIZE_IN_BYTES = 500000;

export const createFileReader = () => {
  const exportToCsv = async (params) => {
    return Papa.unparse(params.data, {
      header: true,
      quotes: true,
      ...params.config,
    });
  };
  const parseTextAsCsv = async (text, config) => {
    return Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      ...config,
    });
  };
  const readFileAsText = async (file, encoding = "utf-8") => {
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      throw new Error("File must be under 50MB.");
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const text = e.target.result;
        resolve(text);
      };
      fileReader.onerror = async (error) => {
        reject(error);
      };
      fileReader.readAsText(file, encoding);
    });
  };

  const readXLSFileAsText = async (file) => {
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      throw new Error("File must be under 50MB.");
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const text = e.target.result;

        const xlsData = XLSX.read(text, { type: "binary" });
        const sheetName = xlsData.SheetNames[0];
        const sheetData = xlsData.Sheets[sheetName];

        const data = XLSX.utils.sheet_to_csv(sheetData, { header: 1 });
        resolve(data);
      };
      fileReader.onerror = async (error) => {
        reject(error);
      };
      fileReader.readAsBinaryString(file);
    });
  };
  return {
    exportToCsv,
    parseTextAsCsv,
    readFileAsText,
    readXLSFileAsText,
  };
};
