import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Atm } from "../../../assets/atm.svg";
import BancoDeDesarrolloRural from "../../../assets/bancodedesarrollorural.png";
import { ReactComponent as GTContinental } from "../../../assets/bancogytcontinental.svg";
import { ReactComponent as BancoIndustrial } from "../../../assets/bancoindustrial.svg";
import Bac from "../../../assets/bac.png";
import BancoPromerica from "../../../assets/bancopromerica.png";
import { ReactComponent as Banreservas } from "../../../assets/banreservas.svg";
import {
  bankAgencyTypeLabels,
  bankAgencyTypes,
} from "../../../domain/banks/bankagencytype";
import { paymentSettings } from "../../../domain/payments/paymentsettings";
import {
  paymentStatus,
  paymentStatusLabels,
} from "../../../domain/payments/paymentstatus";
import { useModalView } from "../../../hooks/usemodalview";
import { selectPaymentById } from "../../../redux/payments/selectors";
import {
  selectUserById,
  selectUserFullName,
} from "../../../redux/users/selectors";
import { getUserById } from "../../../redux/users/thunks/getuserbyid";
import {
  dateFormats,
  formatDate,
  addHoursToDateString,
  timezoneByCountry,
  localeByCountry,
} from "../../../util/dateutils";
import { Conditional } from "../../components";
import { Icon } from "../../controls/icon/icon";
import { Ripple } from "../../controls/ripple/ripple";
import { Currency } from "../../globals/currency/currency";
import { anchorTo } from "../../globals/modalviews/anchors";
import { TextTrim } from "../../globals/texttrim/texttrim";
import { BlockingView } from "../../layout/blockingview/blockingview";
import "./listitem.css";

export const PaymentBankIcon = ({ bank }) => {
  switch (bank) {
    case "Banco Industrial":
      return <BancoIndustrial />;
    case "Banreservas":
      return <Banreservas />;
    case "G&T":
      return <GTContinental />;
    case "Banrural":
      return <img src={BancoDeDesarrolloRural} alt={bank} />;
    case "BAC":
      return <img src={Bac} alt={bank} />;
    case "Promerica":
      return <img src={BancoPromerica} alt={bank} />;
    default:
      return null;
  }
};

export const PaymentBankAgencyType = ({ agencyType }) => {
  let icon = null;
  switch (agencyType) {
    case bankAgencyTypes.AGENCY:
      icon = <Icon icon="account_balance" />;
      break;
    case bankAgencyTypes.AGENT:
      icon = <Icon icon="storefront" />;
      break;
    case bankAgencyTypes.ATM:
      icon = <Atm />;
      break;
    case bankAgencyTypes.ONLINE:
      icon = <Icon icon="phone_android" />;
      break;
    default:
      icon = null;
      break;
  }
  return (
    <Conditional condition={icon}>
      <span className="agency-type">
        {icon}
        <span>{bankAgencyTypeLabels[agencyType]}</span>
      </span>
    </Conditional>
  );
};

export const PaymentBank = ({ payment }) => {
  return (
    <div className="payment-bank">
      <PaymentBankIcon bank={payment.bank} />
      <PaymentBankAgencyType agencyType={payment.agency} />
    </div>
  );
};

export const PaymentStatus = ({ payment, locale, timezone }) => {
  let icon = "";
  let status = payment.status;
  const currentDate = formatDate(
    new Date().toLocaleString(locale, {
      timeZone: timezone,
    }),
    null,
    true
  );
  const dateCreatedAt = formatDate(
    new Date(payment.createdAt).toLocaleString(locale, {
      timeZone: timezone,
    }),
    null,
    true
  );
  const addHoursToDate = addHoursToDateString(
    dateCreatedAt,
    paymentSettings.NOT_FOUND_AFTER_HOURS
  );

  switch (payment.status) {
    case paymentStatus.PROCESSING:
      if (addHoursToDate < currentDate) {
        status = "not_found";
        icon = "sync_problem";
      } else {
        icon = "sync";
      }
      break;
    case paymentStatus.UPLOADING:
      icon = "sync";
      break;
    case paymentStatus.CREATED:
      icon = "sync";
      break;
    default:
      icon = "";
      break;
  }
  return (
    <span className={classNames("status", `status-${status}`)}>
      <Icon icon={icon} />
      <span className="label">{paymentStatusLabels[status]}</span>
    </span>
  );
};

export const ListItem = ({ isActive, onClick, paymentId }) => {
  const [revealContextMenu] = useModalView();
  const dispatch = useDispatch();
  const payment = useSelector(selectPaymentById(paymentId));
  const user = useSelector(selectUserById(payment.userId));
  const userFullName = useSelector(selectUserFullName(user.userId));

  const timezone = timezoneByCountry[user?.personal?.country || "GT"];
  const locale = localeByCountry[user?.personal?.country || "GT"];
  const isACH = payment.bankOrigin
    ? !(
        payment.bankOrigin.includes(payment.bank) ||
        payment.bankOrigin === payment.bank
      )
    : false;
  const onRippleClick = () => {
    onClick(`/payments/${payment.paymentId}`);
  };
  const onContextMenu = async (event) => {
    event.preventDefault();
    await revealContextMenu({
      ...anchorTo(event.clientX, event.clientY),
      component: "payments/list/listitemcontextmenu",
      props: { payment },
    });
  };
  useEffect(() => {
    if (
      payment.userId &&
      !user.$isPending &&
      !user.$isRejected &&
      !user.$isFulfilled
    ) {
      dispatch(getUserById({ userId: payment.userId }));
    }
  }, [
    dispatch,
    payment.userId,
    user.$isPending,
    user.$isRejected,
    user.$isFulfilled,
  ]);
  return (
    <Ripple
      component="li"
      className={classNames("item", { active: isActive })}
      onClick={onRippleClick}
      onContextMenu={onContextMenu}
    >
      <BlockingView isPending={user.$isPending}>
        <div className="primary">
          {isACH ? <span className="header-ach">ACH</span> : ""}
          <h4>
            <TextTrim>{user.$isPending ? "" : userFullName}</TextTrim>
          </h4>
          <dl>
            <div>
              <dt>Amount</dt>
              <dd className="amount">
                <Currency
                  amount={payment.amount}
                  country={user?.personal?.country}
                />
              </dd>
            </div>
            <div>
              <dt>Bank</dt>
              <dd className="bank">
                <PaymentBank payment={payment} />
              </dd>
            </div>
          </dl>
        </div>
        <div className="secondary">
          <dl>
            <div>
              <dt>Status</dt>
              <dd>
                <PaymentStatus
                  payment={payment}
                  locale={locale}
                  timezone={timezone}
                />
              </dd>
            </div>
            <div>
              <dt>Created at</dt>
              <dd className="created-at">
                {formatDate(
                  new Date(payment.createdAt).toLocaleString(locale, {
                    timeZone: timezone,
                  }),
                  dateFormats.MMMM_D_YYYY_H_mm,
                  true
                )}
              </dd>
            </div>
          </dl>
        </div>
      </BlockingView>
    </Ripple>
  );
};
