export const fields = {
  active_due_day: {
    key: "active_due_day",
    label: "Due day",
    icon: "lock",
    columns: 6,
    readonly: true,
  },
  status: {
    key: "status",
    label: "Status",
    icon: "lock",
    type: "select",
    columns: 6,
    readonly: false,
    validateFrom: true,
    options: [
      { label: "Created", value: "created", from: [] },
      { label: "Reviewing", value: "reviewing", from: ["rejected", "created"] },
      {
        label: "Hard Rejected",
        value: "rejected",
        from: ["reviewing", "created"],
      },
      {
        label: "Soft Rejected",
        value: "soft_rejected",
        from: ["reviewing", "created"],
      },
      {
        label: "Approved",
        value: "approved",
        from: ["reviewing", "created"],
      },
      { label: "Offer declined", value: "offer_declined", from: ["approved"] },
      { label: "Offer accepted", value: "offer_accepted", from: ["approved"] },
      {
        label: "Offer expired",
        value: "offer_expired",
        from: [
          "approved",
          "offer_declined",
          "offer_accepted",
          "document_sent",
          "document_approved",
          "document_rejected",
        ],
      },
      {
        label: "Document sent",
        value: "document_sent",
        from: ["document_rejected", "offer_accepted"],
      },
      {
        label: "Document approved",
        value: "document_approved",
        from: ["offer_accepted"],
      },
      {
        label: "Document rejected",
        value: "document_rejected",
        from: [],
      },
      { label: "Signed", value: "signed", from: ["document_approved"] },
      {
        label: "Archived",
        value: "archived",
        from: [
          "created",
          "rejected",
          "reviewing",
          "approved",
          "offer_declined",
          "offer_accepted",
          "offer_expired",
          "document_sent",
          "document_approved",
          "document_rejected",
          "signed",
        ],
      },
    ],
  },
  estimated_release: {
    key: "estimated_release",
    label: "Estimated release",
    icon: "event",
    columns: 12,
    readonly: true,
  },
  rejected_until: {
    key: "rejected_until",
    label: "Rejected until",
    icon: "event",
    columns: 12,
    readonly: true,
    visible: false,
  },
  updated_at: {
    key: "updated_at",
    label: "Updated at",
    icon: "event",
    columns: 12,
    readonly: true,
  },
  created_at: {
    key: "created_at",
    label: "Created at",
    icon: "event",
    columns: 12,
    readonly: true,
  },
};
