import { handleError } from "../util/handleerror";

/* @Deprecated in favor of `UserRepo` and redux.
 */

class Users {
  /**
   * @param {Object} token
   * @param {Axios} requests
   */
  constructor(token, requests) {
    this.token = token;
    this.requests = requests;
  }
  getUsers(userIds) {
    const users = {};
    return Promise.all(userIds.map((userId) => this.getUser(userId))).then(
      (all) => {
        all.forEach((user) => (users[user.user_id] = user));
        return users;
      }
    );
  }
  getUser(userId) {
    return this.requests({
      method: "get",
      url: `/users/${userId}`,
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
  addAnnotation(userId, annotation) {
    return this.requests({
      method: "post",
      url: `/users/${userId}/annotations`,
      data: { data: { userId: userId, ...annotation } },
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
  getAnnotations(userId, params) {
    return this.requests({
      method: "get",
      params: params,
      url: `/users/${userId}/annotations`,
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
  getLoans(userId) {
    return this.requests({
      method: "get",
      url: `/users/${userId}/loans`,
    })
      .then(({ data }) => data.data.items)
      .catch(handleError);
  }
  searchUser(userId) {
    return this.requests({
      method: "get",
      url: `/users/search`,
      params: { q: userId },
    })
      .then(({ data }) => data.data)
      .then((users) => {
        return users;
      })
      .catch(handleError);
  }
  updateUser(userId, patch) {
    return this.requests({
      method: "patch",
      url: `/users/${userId}/update`,
      data: { data: patch },
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
  getSignature(userId) {
    return this.requests({
      method: "get", 
      url: `/users/${userId}/signatures/latest`
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
}

export default Users;
