import { useState, useEffect } from "react";
export function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function keyDownHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const keyUpHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    window.addEventListener("keyup", keyUpHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
      window.removeEventListener("keyup", keyUpHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return keyPressed;
}
