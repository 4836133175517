import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

class User extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data, t } = this.props;
    return (
      <FieldsCard
        data={data}
        icon="perm_data_setting"
        fields={fields}
        title={t("User data")}
      />
    );
  }
}

export default compose(User)(withTranslation());
