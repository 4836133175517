import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FieldsCard from '../../../../common/fields/fieldscard';
import { fields } from './fields';
import { compose } from '../../../../../util/compose';

const Work = (props) => {
  const { data, t } = props;
  return (
    <FieldsCard
      data={data}
      fields={fields}
      icon="perm_identity"
      title={t('User work')}
    />
  );
};

Work.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default compose(Work)(withTranslation());
