import { useSelector } from "react-redux";
import { selectUserFullName } from "../../../redux/users/selectors";
import { FlagIcon } from "../../controls/icon/icon";
import { Card, CardContent, CardHeader } from "../../layout/card/card";
import { Attributes } from "./attributes";
import "./details.css";
import { ReceiptImage } from "./receiptimage";
import { Update } from "./update";

export const Details = ({ payment, user }) => {
  const userFullName = useSelector(selectUserFullName(user.userId));
  const isACH = payment.bankOrigin
    ? !(payment.bankOrigin.includes(payment.bank) || payment.bankOrigin === payment.bank)
    : false;
  return (
    <Card className="details">
      <CardHeader isPending={!user || user.$isPending}>
        <FlagIcon country={user?.personal?.country} />
        <h4>{userFullName}</h4>
        <span className="header-ach">{isACH ? "ACH" : ""}</span>
      </CardHeader>
      <CardContent isPending={!payment || payment.$isPending}>
        <Attributes payment={payment} user={user} />
        <Update payment={payment} />
        <ReceiptImage payment={payment} />
      </CardContent>
    </Card>
  );
};
