import { Section } from "../../layout/layout";
import { ListItem } from "./listitem";
import "./listitems.css";

export const ListItems = ({ payments, paymentId, onClick }) => {
  return (
    <Section className="items">
      <ul>
        {payments.map((payment, key) => (
          <ListItem
            isActive={payment.paymentId === paymentId}
            key={key}
            onClick={onClick}
            paymentId={payment.paymentId}
          />
        ))}
      </ul>
    </Section>
  );
};
