import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./uuid.css";

class Uuid extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    uuid: PropTypes.string.isRequired,
  };
  render() {
    return (
      <div className={classNames("uuid", this.props.className)}>
        {this.props.text && <strong>{this.props.text}</strong>}
        <code>{this.props.uuid}</code>
      </div>
    );
  }
}

export default Uuid;
