import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentStatus } from "../../../domain/payments/paymentstatus";
import { useFragment } from "../../../hooks/usefragment";
import { useModalView } from "../../../hooks/usemodalview";
import {
  selectPaymentById,
  selectPaymentsByStatus,
} from "../../../redux/payments/selectors";
import { listPaymentsByStatus } from "../../../redux/payments/thunks/listpaymentsbystatus";
import { getDiffInHours } from "../../../util/dateutils";
import { RoundedButton } from "../../controls/button/button";
import { Icon } from "../../controls/icon/icon";
import { Article } from "../../layout/layout";
import "./list.css";
import {
  defaultFilter,
  FILTER_FRAGMENT_ID,
  FILTER_SORT_ORDER_OLDER,
} from "./listfilter";
import { ListHeader } from "./listheader";
import { ListItems } from "./listitems";

const LIST_FRAGMENT_ID = "payments/list";

export const List = ({ onItemClick, paymentId }) => {
  const dispatch = useDispatch();
  const payment = useSelector(selectPaymentById(paymentId));
  const [revealCreatePayment] = useModalView();
  const [listRequest, setListRequest] = useFragment(LIST_FRAGMENT_ID);
  const [filter] = useFragment(FILTER_FRAGMENT_ID, defaultFilter);
  const [isPending, setIsPending] = useState();
  const users = useSelector((store) => store?.users?.users);
  const payments = useSelector(
    selectPaymentsByStatus(
      paymentStatus.PROCESSING,
      paymentStatus.CREATED,
      paymentStatus.UPLOADING
    )
  )
    .filter((payment) => {
      const isNotFound = getDiffInHours(payment.createdAt, "") > 1;
      if (
        (filter &&
          filter.status &&
          filter.status.length &&
          payment.status &&
          isNotFound &&
          !filter.status.includes("not_found")) ||
        (filter &&
          filter.status &&
          filter.status.length &&
          payment.status &&
          !isNotFound &&
          !filter.status.includes("processing")) ||
        (filter &&
          filter.maxAmount &&
          payment.amount &&
          parseInt(payment.amount) > parseInt(filter.maxAmount)) ||
        (filter &&
          filter.minAmount &&
          payment.amount &&
          parseInt(payment.amount) < parseInt(filter.minAmount)) ||
        (filter &&
          filter.bank &&
          filter.bank.length &&
          !filter.bank.includes(payment.bank)) ||
        (filter &&
          filter.bankAgency &&
          filter.bankAgency.length &&
          !filter.bankAgency.includes(payment.agency))
      ) {
        return false;
      }
      const country =
        payment.userId &&
        users &&
        users[payment.userId] &&
        users[payment.userId]?.personal?.country
          ? users[payment.userId].personal.country
          : "";
      if (
        filter &&
        filter.country &&
        country &&
        !filter.country.includes(country.toLowerCase())
      ) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      if (filter.sortOrder === FILTER_SORT_ORDER_OLDER) {
        if (a.createdAt >= b.createdAt) return 1;
        if (a.createdAt < b.createdAt) return -1;
      } else {
        if (a.createdAt >= b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;
      }
      return 0;
    });
    
  const onCreatePaymentButtonClick = async () => {
    await revealCreatePayment({
      component: "payments/payment/create",
      props: { payment },
    });
  };
  const onReload = async () => {
    listPayments();
  };
  const listPayments = useCallback(async () => {
    setIsPending(true);
    await setListRequest();
    await dispatch(listPaymentsByStatus({ status: paymentStatus.PROCESSING }));
    setIsPending(false);
  }, [dispatch, setIsPending, setListRequest]);
  useEffect(() => {
    if (!listRequest.id) {
      listPayments();
    }
  }, [listPayments, listRequest.id]);
  return (
    <Article className="list" isPending={isPending}>
      <ListHeader payments={payments} onReload={onReload} />
      <ListItems
        payments={payments}
        paymentId={paymentId}
        onClick={onItemClick}
      />
      <RoundedButton
        rippleLight
        className="create-payment"
        onClick={onCreatePaymentButtonClick}
      >
        <Icon icon="add" />
      </RoundedButton>
    </Article>
  );
};
