import { createApiClient } from "../../infrastructure/apiclient";
import { createAuthClient } from "../../infrastructure/authclient";
import { createFileReader } from "../../infrastructure/filereader";
import { createAuthRepo } from "../../repository/authrepo";
import { createBankRepo } from "../../repository/bankrepo";
import { createFileRepo } from "../../repository/filerepo";
import { createLoanRepo } from "../../repository/loanrepo";
import { createPaymentRepo } from "../../repository/paymentrepo";
import { createUserRepo } from "../../repository/userrepo";

/* create clients */
const authClient = createAuthClient();
const apiClient = createApiClient(authClient);
const fileReader = createFileReader();
/* create repos */
const authRepo = createAuthRepo(authClient);
const bankRepo = createBankRepo(apiClient, fileReader);
const fileRepo = createFileRepo(fileReader);
const loanRepo = createLoanRepo(apiClient);
const paymentRepo = createPaymentRepo(apiClient);
const userRepo = createUserRepo(apiClient);
/* compose and export */
export const extraArgument = {
  authRepo,
  bankRepo,
  fileRepo,
  loanRepo,
  paymentRepo,
  userRepo,
};
