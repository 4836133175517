import { objectKeysToCamelCase } from "../util/commonutils";

export const createUserRepo = (apiClient) => {
  const getBankAccount = async (userId) => {
    const url = `/users/${userId}/bank_account`;
    const bankAccount = await apiClient.get({ url });
    return bankAccount && bankAccount.data
      ? objectKeysToCamelCase(bankAccount.data)
      : "";
  };
  const getUserById = async (userId) => {
    const url = `/users/${userId}`;
    const response = await apiClient.get({ url });
    return response && response.data
      ? objectKeysToCamelCase(response.data)
      : "";
  };
  return {
    getBankAccount,
    getUserById,
  };
};
