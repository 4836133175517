import classNames from "classnames";
import { dateFormats, formatDate } from "../../../util/dateutils";
import { Conditional } from "../../components";
import { Icon } from "../../controls/icon/icon";
import { Currency } from "../../globals/currency/currency";

export const BalanceInstallmentItem = ({
  status,
  dueDate,
  fulfilledDate,
  amount,
  country,
  installmentNumber,
}) => {
  return (
    <li
      className={classNames("installment", status, {
        fulfilled: fulfilledDate,
      })}
    >
      <dl>
        <div className="number">
          <dt>Número de pago</dt>
          <dd>{installmentNumber}</dd>
        </div>
        <div className="date">
          <dt>Fecha de pago</dt>
          <dd>
            <time>{formatDate(dueDate, dateFormats.D_OF_MMMM)}</time>
            <Icon icon="east" />
          </dd>
        </div>
        <div className="amount">
          <dt>Cantidad</dt>
          <dd>
            <Currency amount={amount} country={country} />
            <Conditional condition={fulfilledDate}>
              <Icon icon="done" />
            </Conditional>
          </dd>
        </div>
      </dl>
    </li>
  );
};
