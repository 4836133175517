import React from "react";
import PropTypes from "prop-types";
import "./table.css";

class Table extends React.Component {
  static propTypes = {
    service: PropTypes.string.isRequired,
    failures: PropTypes.number.isRequired,
    signed_loans: PropTypes.number.isRequired,
    transferring_loans: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { service, handler, failures, signed_loans, transferring_loans, total } =
      this.props;
    return (
      <table>
        <tbody className="details-list">
          <tr>
            <th>Service: </th>
            <td>{service}</td>
          </tr>
          <tr>
            <th>Handler: </th>
            <td>{handler}</td>
          </tr>
          <tr>
            <th>Failures: </th>
            <td>{failures}</td>
          </tr>
          <tr>
            <th>Signed loans: </th>
            <td>{signed_loans}</td>
          </tr>
          <tr>
            <th>Transferring loans: </th>
            <td>{transferring_loans}</td>
          </tr>
          <tr>
            <th>Total: </th>
            <td>{`Q${total.toFixed(2)}`}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}
export default Table;
