export const fields = {
  employer: {
    key: 'employer',
    label: 'Employer',
    icon: 'lock',
  },
  first_income_date_main_source: {
    key: 'first_income_date_main_source',
    label: 'First Income Date Main Source',
    icon: 'lock',
  },
  latest_income_date_main_source: {
    key: 'latest_income_date_main_source',
    label: 'Latest Income Date Main Source',
    icon: 'lock',
  },
  main_income_source_description: {
    key: 'main_income_source_description',
    label: 'Main Income Source Description',
    icon: 'lock',
  },
  monthly_income_main_source: {
    key: 'monthly_income_main_source',
    label: 'Main Income Main Source',
    icon: 'lock',
  },
};
