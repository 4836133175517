import { createAsyncThunk } from "@reduxjs/toolkit";
import { mergeFulfilledUser } from "../../users/actions";
import { mergeFulfilledPayments } from "../actions";
import { thunkTypes } from "../thunktypes";

export const listPaymentsByStatus = createAsyncThunk(
  thunkTypes.LIST_PAYMENTS_BY_STATUS,
  async (params, { dispatch, extra }) => {
    if (!params.status) {
      throw new Error("invalid payment status");
    }
    const payments = await extra.paymentRepo.listPaymentsByStatus(
      params.status
    );
    if (!payments) {
      throw new Error("error fetching payment");
    }

    await payments.forEach((payment) => {
      dispatch(mergeFulfilledUser(payment.user));
    });

    await dispatch(mergeFulfilledPayments(payments));
    return payments;
  }
);
