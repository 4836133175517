import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

import "@material/react-layout-grid/dist/layout-grid.css";

class State extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { data, t } = this.props;
    return (
      <FieldsCard
        data={data}
        fields={fields}
        icon="description"
        title={t("Loan request")}
      />
    );
  }
}

export default compose(State)(withTranslation());
