import classNames from "classnames";
import { RoundedButton } from "../button/button";
import "./radiogroup.css";

export const RadioButton = ({
  children,
  isSelected,
  className,
  ...otherProps
}) => {
  return (
    <RoundedButton
      {...otherProps}
      className={classNames(className, "radio", { selected: isSelected })}
    >
      {children}
    </RoundedButton>
  );
};

export const RadioGroup = ({ children, className, ...otherProps }) => {
  return (
    <div {...otherProps} className={classNames(className, "radio-group")}>
      {children}
    </div>
  );
};
