import { handleError } from "../util/handleerror";

/**
 * Loan Request repository.
 */
class Requests {
  /**
   * @param {Object} token
   * @param {Axios} requests
   * @param {Object} dataLayer
   */
  constructor(token, requests, dataLayer) {
    this.token = token;
    this.requests = requests;
    this.dataLayer = dataLayer;
  }

  getRequest(requestId, withUser) {
    const { requests, dataLayer } = this;
    return requests({
      method: "get",
      url: `/loan_requests/${requestId}`,
    })
      .then(async ({ data }) => {
        return withUser
          ? await dataLayer.users.getUser(data.data.user_id).then((user) => {
            data.data.user = user;
            return data.data;
          })
          : data.data;
      })
      .catch(handleError);
  }

  /**
   * @param {Object} params
   * @return {Promise<Object>}
   */
  getRequests(params) {
    return this.requests({
      method: "get",
      url: `/loan_requests`,
      params: params,
    })
      .then(async ({ data }) => {
        const requests = {};
        data.data.items.forEach(
          (request) => (requests[request.loan_request_id] = request)
        );
        return requests;
      })
      .catch(handleError);
  }

  /**
   * @param {String} loanRequestId
   * @param {String} userId
   * @param {String} status
   * @return {Promise<Object>}
   */
  updateRequestStatus(loanRequestId, userId, status, isSoftReject) {
    return this.requests({
      method: "post",
      url: `/loan_requests/${loanRequestId}/update_status`,
      data: { data: { user_id: userId, status, is_soft_reject: isSoftReject ?? false} },
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
}

export default Requests;
