import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import ListItemTags from "../../../../common/list/item/tags/tags";
import { compose } from "../../../../../util/compose";

class DisbursalListItemTags extends React.Component {
  static propTypes = {
    disbursal: PropTypes.object.isRequired,
  };
  render() {
    const tags = [];
    this.props.disbursal.revised_status === "failed_transfer" &&
      tags.push({
        className: "failed-transfer",
        icon: "clear",
        title: this.props.t("Failed transfer"),
      });
    return <ListItemTags tags={tags} />;
  }
}

export default compose(DisbursalListItemTags)(withTranslation());
