import { useSelector } from "react-redux";
import { useFragment } from "../../../hooks/usefragment";
import { selectBankStatement } from "../../../redux/banks/selectors";
import { Conditional } from "../../components";
import { OutlinedIcon } from "../../controls/icon/icon";
import { Card, CardContent } from "../../layout/card/card";
import { Header, Section } from "../../layout/layout";
import "./statement.css";
import { StatementFilter } from "./statementfilter";
import { StatementTable } from "./statementtable";

const baseFilter = {
  bank: "",
  reference: "",
  amount: "",
  dateFrom: "",
  dateTo: "",
  status: "",
  sortOrder: "",
  sortBy: "",
};

const STATEMENT_FRAGMENT_ID = "payments/statement";

export const Statement = () => {
  const [filter, setFilter] = useFragment(STATEMENT_FRAGMENT_ID, baseFilter);
  const store = useSelector((store) => store);
  const statement = useSelector(selectBankStatement(filter.bank));
  const clearFilter = async () => {
    setFilter(baseFilter);
  };
  return (
    <Card className="statement" isPending={store.banks.isPending}>
      <Header>
        <h4>Encuentra este pago</h4>
        <StatementFilter
          filter={filter}
          setFilter={setFilter}
          clearFilter={clearFilter}
        />
      </Header>
      <CardContent>
        <Conditional condition={filter.id && filter.dateFrom && filter.dateTo && filter.bank}>
          <StatementTable
            statement={statement}
            filter={filter}
            setFilter={setFilter}
          />
        </Conditional>
        <Conditional condition={!filter.id || !filter.dateFrom || !filter.dateTo || !filter.bank}>
          <Section className="no-filter">
            <OutlinedIcon icon="find_in_page" />
            <p>Empieza con los campos de tu búsqueda.</p>
            <p>Busca número de referecia, fecha, cantidad, etc.</p>
          </Section>
        </Conditional>
      </CardContent>
    </Card>
  );
};
