import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CardToggle from "./cardtoggle";
import Heading from "../heading";

const Card = (props) => {
  const { children, className, isDefaultCollapse } = props;
  const [collapsed, setCollapsed] = useState(isDefaultCollapse ? true : false);

  const showToggle =
    props.collapsible &&
    props.children &&
    Array.from(children).find(({ type }) => type === Heading);

  const onToggle = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <div
      className={classNames(
        "card",
        className,
        collapsed ? "collapsed" : "expanded"
      )}
    >
      {showToggle && <CardToggle onToggle={onToggle} collapsed={collapsed} />}
      {children}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  children: PropTypes.node,
};

Card.defaultProps = {
  className: "",
  collapsible: true,
};
export default Card;
