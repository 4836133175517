import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  mergeFulfilledPayment,
  mergePendingPayment,
  mergeRejectedPayment,
} from "../actions";
import { thunkTypes } from "../thunktypes";

export const getPaymentById = createAsyncThunk(
  thunkTypes.GET_PAYMENT_BY_ID,
  async (params, { dispatch, extra }) => {
    if (!params.paymentId) {
      throw new Error("invalid payment id");
    }
    await dispatch(mergePendingPayment(params.paymentId));
    let payment = "";
    try {
      payment = await extra.paymentRepo.getPaymentById(params.paymentId);
      await dispatch(mergeFulfilledPayment(payment));
    } catch (error) {
      await dispatch(mergeRejectedPayment(params.paymentId));
    }
    if (!payment) {
      throw new Error(`payment ${params.paymentId} not found`);
    }
    return payment;
  }
);
