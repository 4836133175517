import classNames from "classnames";
import React from "react";
import { TextTrim } from "../../globals/texttrim/texttrim";
import "./checkbox.css";

export const CheckBoxLabel = ({ children }) => {
  return (
    <span className="label">
      <TextTrim className="label">{children}</TextTrim>
    </span>
  );
};

export const CheckBox = ({
  children,
  className,
  onChange,
  checked,
  isDisabled,
  ...otherProps
}) => {
  const onInputChange = ({ target }) => {
    onChange && onChange(target.checked);
  };
  return (
    <label
      className={classNames("check-box", className, {
        "is-disabled": isDisabled,
      })}
    >
      <input
        onChange={onInputChange}
        className="input"
        type="checkbox"
        checked={checked}
        disabled={isDisabled}
        {...otherProps}
      />
      {children}
    </label>
  );
};
