import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { withTranslation } from "react-i18next";
import Button from "@material/react-button";
import MaterialIcon from "@material/react-material-icon";

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from "@material/react-dialog";
import Calendar from "./calendar";
import { compose } from "../../../util/compose";

import "@material/react-button/dist/button.css";
import "@material/react-dialog/dist/dialog.css";
import "@material/react-material-icon/dist/material-icon.css";

class CalendarDialog extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    toggle: PropTypes.bool,
    onConfirm: PropTypes.func,
    onDismiss: PropTypes.func,
    dismiss: PropTypes.string,
    accept: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.object.isRequired,
    type: PropTypes.string,
    format: PropTypes.string,
  };
  static defaultProps = {
    open: false,
    onConfirm: (date) => {},
    onDismiss: () => {},
    dismiss: "Dismiss",
    accept: "Select",
    title: "Select a date",
    format: "YYYY-MM-DD",
    type: "date",
  };
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.date,
    };
  }
  onClose(action) {
    switch (action) {
      case "accept":
        this.props.onConfirm(this.state.date);
        break;
      default:
        this.props.onDismiss();
        break;
    }
  }
  onDate(date) {
    this.setState({ date });
  }
  onPrevious() {
    this.setState({
      date: {
        from: Moment(this.state.date.from)
          .subtract(1, "months")
          .format(this.props.format),
      },
    });
  }
  onNext() {
    this.setState({
      date: {
        from: Moment(this.state.date.from)
          .add(1, "months")
          .format(this.props.format),
      },
    });
  }
  render() {
    return (
      <Dialog open={this.props.toggle} onClose={this.onClose.bind(this)}>
        <DialogTitle>{this.props.t(this.props.title)}</DialogTitle>
        <DialogContent className="calendar-dialog">
          <div className="actions">
            <div className="back">
              <Button
                onClick={this.onPrevious.bind(this)}
                icon={<MaterialIcon icon="arrow_back" />}
              >
                {this.props.t("Previous")}
              </Button>
            </div>
            <div className="forward">
              <Button
                onClick={this.onNext.bind(this)}
                trailingIcon={<MaterialIcon icon="arrow_forward" />}
              >
                {this.props.t("Next")}
              </Button>
            </div>
          </div>
          <Calendar
            date={this.state.date}
            format={this.props.format}
            onDate={this.onDate.bind(this)}
            type={this.props.type}
          />
        </DialogContent>
        <DialogFooter>
          <DialogButton action="dismiss">
            {this.props.t(this.props.dismiss)}
          </DialogButton>
          <DialogButton action="accept">
            {this.props.t(this.props.accept)}
          </DialogButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default compose(CalendarDialog)(withTranslation());
