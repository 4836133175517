import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkTypes } from "../thunktypes";
import { actions } from "../slice";

export const signOut = createAsyncThunk(
  thunkTypes.SIGN_OUT,
  async (params, { dispatch, extra }) => {
    await extra.authRepo.signOut();
    await dispatch(actions.clearSession());
  }
);
