class ArrayUtils {
  static containsAll(array, items) {
    if (!array) {
      return false;
    }
    if (!items) {
      return true;
    }
    for (const item of items) {
      if (!array.includes(item)) return false;
    }
    return true;
  }
}

export default ArrayUtils;
