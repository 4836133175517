import { mergeItemByIndex, mergeItemsByIndex } from "../../util/stateutils";

export const mergeBalance = (state, action) => {
  state.balances = mergeItemByIndex(
    (balance) => balance.loanId,
    action.payload,
    state.balances
  );
};

export const mergeBalances = (state, action) => {
  state.balances = mergeItemsByIndex(
    (balance) => balance.loanId,
    action.payload,
    state.balances
  );
};

export const mergePendingBalance = (state, action) => {
  state.balances = mergeItemByIndex(
    (balance) => balance.loanId,
    {
      loanId: action.payload,
      $isPending: true,
      $isFulfilled: false,
      $isRejected: false,
    },
    state.balances
  );
};

export const mergeFulfilledBalance = (state, action) => {
  state.balances = mergeItemByIndex(
    (balance) => balance.loanId,
    {
      ...action.payload,
      $isPending: false,
      $isFulfilled: true,
      $isRejected: false,
    },
    state.balances
  );
};

export const mergeFulfilledBalances = (state, action) => {
  state.balances = mergeItemsByIndex(
    (balance) => balance.loanId,
    action.payload.map((balance) => ({
      ...balance,
      $isPending: false,
      $isFulfilled: true,
      $isRejected: false,
    })),
    state.balances
  );
};

export const mergeRejectedBalance = (state, action) => {
  state.balances = mergeItemByIndex(
    (balance) => balance.loanId,
    {
      loanId: action.payload,
      $isPending: false,
      $isFulfilled: false,
      $isRejected: true,
    },
    state.balances
  );
};
