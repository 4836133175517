import { FlagIcon } from "../controls/icon/icon";
import { Card, CardContent, CardHeader } from "../layout/card/card";
import "./attributes.css";

export const Attributes = ({ bank }) => {
  return (
    <div className="attributes">
      <Card>
        <CardHeader>
          <h4>
            <span>{bank.name}</span>
            <FlagIcon country={bank.country} />
          </h4>
        </CardHeader>
        <CardContent>
          <dl>
            <div>
              <dt>Swift</dt>
              <dd>
                <code>{bank.swift}</code>
              </dd>
            </div>
            <div>
              <dt>País</dt>
              <dd>
                <code>{bank.country}</code>
              </dd>
            </div>
          </dl>
        </CardContent>
      </Card>
    </div>
  );
};
