import classNames from "classnames";
import { BlockingView } from "../blockingview/blockingview";
import { Article, Header } from "../layout";
import "./card.css";

export const CardHeader = ({ children, className, ...otherProps }) => {
  return (
    <Header {...otherProps} className={className}>
      {children}
    </Header>
  );
};

export const CardContent = ({ children, className, ...otherProps }) => {
  return (
    <BlockingView {...otherProps} className={classNames(className, "content")}>
      {children}
    </BlockingView>
  );
};

export const Card = ({ children, className, ...otherProps }) => {
  return (
    <Article
      {...otherProps}
      className={classNames(className, "card-container")}
    >
      {children}
    </Article>
  );
};
