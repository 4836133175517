import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import * as reducers from './reducers';

const name = 'sidebar';
const slice = createSlice({
  initialState,
  name,
  reducers,
});

export const actions = slice.actions;
export const reducer = slice.reducer;
export default slice;
