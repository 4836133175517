import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TextField, { Input } from "@material/react-text-field";
import MaterialIcon from "@material/react-material-icon";

import { compose } from "../../../../util/compose";

import "@material/react-text-field/dist/text-field.css";
import "@material/react-material-icon/dist/material-icon.css";

const styles = {
  textField: {
    width: "100%",
  },
};
class Text extends React.Component {
  /**
   * @type {Object}
   */
  static propTypes = {
    className: PropTypes.string,
    textArea: PropTypes.bool,
    data: PropTypes.object,
    readonly: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  /**
   * @type {Object}
   */
  static defaultProps = {
    textArea: false,
    className: "",
    data: {},
    readonly: true,
    value: "",
    icon: "edit",
    onChange: (update, data) => {},
    onClick: ({ value, meta }) => {},
  };
  /**
   * @param {Object} event
   */
  onText(event) {
    this.props.onChange(event.target.value, this.props.data);
  }
  /**
   *
   */
  onClick() {
    const { value, data, onClick } = this.props;
    onClick({ value, meta: data });
  }
  /**
   * @return {React.ReactNode}
   */
  render() {
    const { textArea, className, readonly, value, label, t, icon } = this.props;
    const i = readonly ? "lock" : icon;
    return (
      <TextField
        textarea={textArea}
        className={className}
        label={t(label)}
        style={styles.textField}
        trailingIcon={
          typeof i === "string" ? <MaterialIcon role="button" icon={i} /> : i
        }
        onTrailingIconSelect={this.onClick.bind(this)}
      >
        <Input
          disabled={readonly}
          value={value}
          onChange={this.onText.bind(this)}
        />
      </TextField>
    );
  }
}

export default compose(Text)(withTranslation());
