import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

class PersonalAddress extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  render() {
    return (
      <FieldsCard
        data={this.props.data}
        fields={fields}
        icon="house"
        title={this.props.t("Personal address")}
      />
    );
  }
}

export default compose(PersonalAddress)(withTranslation());
