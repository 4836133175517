import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class DrawerHeader extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
  };
  static defaultProps = {
    children: null,
    className: "",
  };
  render() {
    return (
      <div className={classNames(this.props.className, `drawer-header`)}>
        {this.props.children}
      </div>
    );
  }
}

export default DrawerHeader;
