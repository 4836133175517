import * as utils from "../../util/stateutils";

const isFulfilled = (action) => utils.isFulfilled(action, "loans");
const isPending = (action) => utils.isPending(action, "loans");
const isRejected = (action) => utils.isRejected(action, "loans");

export const extraReducers = (builder) => {
  builder
    .addMatcher(isFulfilled, utils.onFulfilled)
    .addMatcher(isPending, utils.onPending)
    .addMatcher(isRejected, utils.onRejected);
};
