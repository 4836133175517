import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import FieldsCard from '../../../../common/fields/fieldscard';
import { fields, extraFields } from './fields';
import { compose } from '../../../../../util/compose';

import '@material/react-layout-grid/dist/layout-grid.css';
import { Cell } from '@material/react-layout-grid';

class Contact extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  static defaultProps = {
    onUpdate: () => {},
    onChange: () => {},
  };

  onUpdate(update) {
    const { data } = this.state;
    const contact = data[update['phone_number'].value];
    Object.values(update).forEach((item) => {
      if (item.updated) contact[item.key] = item.value;
    });
    this.props.onUpdate({ contacts: { ...data } });
  }

  onChange(update) {
    const { data } = this.state;
    const contact = data[update['phone_number'].value];
    Object.values(update).forEach((item) => {
      if (item.updated) contact[item.key] = item.value;
    });
    this.setState({ data: { ...data } });
  }

  onRelationCheck(contact) {
    switch (this.state.data[contact].type) {
      case 'personal':
        return {
          ...fields,
          relation: {
            ...fields['relation'],
            options: extraFields['personal_relation'],
          },
        };
      case 'work':
        return {
          ...fields,
          relation: {
            ...fields['relation'],
            options: extraFields['work_relation'],
          },
        };
      default:
        return fields;
    }
  }

  render() {
    const { t } = this.props;
    const { data } = this.state;
    if (data) {
      return (
        <>
          {Object.keys(data).map((contact, index) => {
            if (!data[contact]['status'])
              data[contact]['status'] = 'registered';
            return (
              <Cell columns={12} key={contact}>
                <FieldsCard
                  data={data[contact]}
                  fields={this.onRelationCheck(contact)}
                  editable={true}
                  icon="description"
                  title={t(`Contact ${index + 1}`)}
                  onUpdate={this.onUpdate.bind(this)}
                  onDataChange={this.onChange.bind(this)}
                />
              </Cell>
            );
          })}
        </>
      );
    }
    return (
      <FieldsCard
        data={null}
        fields={fields}
        icon="description"
        title={t('Contact')}
      />
    );
  }
}

export default compose(Contact)(withTranslation());
