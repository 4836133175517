import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  mergeFulfilledBalance,
  mergePendingBalance,
  mergeRejectedBalance,
} from "../actions";
import { thunkTypes } from "../thunktypes";

export const getLoanBalance = createAsyncThunk(
  thunkTypes.GET_BALANCE,
  async (params, { dispatch, extra }) => {
    if (!params.loanId || !params.userId) {
      throw new Error("invalid loan id");
    }
    await dispatch(mergePendingBalance(params.loanId));
    let loanBalance = "";
    try {
      const balance = await extra.loanRepo.getBalance(params.loanId);
      loanBalance = {
        loanId: params.loanId,
        userId: params.userId,
        ...balance,
      };
      await dispatch(mergeFulfilledBalance(loanBalance));
    } catch (error) {
      await dispatch(mergeRejectedBalance(params.loanId));
    }
    if (!loanBalance) {
      throw new Error(`loan balance ${params.loanId} not found`);
    }
    return loanBalance;
  }
);
