import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import TextField, { Input } from "@material/react-text-field";
import MaterialIcon from "@material/react-material-icon";

import { compose } from "../../../../util/compose";

import "@material/react-text-field/dist/text-field.css";
import "@material/react-material-icon/dist/material-icon.css";
import { useKeyPress } from "../../../../hooks/useKeyPress";

const styles = {
  textField: {
    width: "100%"
  }
};

function Clickable(props) {

  const ctrlPressed = useKeyPress("Control")

  const handleTrailingIconSelect = () => {    
    const url =
      props.url && {}.toString.call(props.url) === "[object Function]"
        ? props.url.apply(this, [props.value])
        : props.url;
      if (ctrlPressed) {
        window.open(url, '_blank')
      }
      else { 
        props.history.push(url);
      }
  }

  const { icon, label, t, value } = props;
  return (
    <TextField
      style={styles.textField}
      label={t(label)}
      onTrailingIconSelect={handleTrailingIconSelect}
      trailingIcon={<MaterialIcon role="button" icon={icon} />}
    >
      <Input value={value || ""} />
    </TextField>
  );
}

Clickable.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.string
};

Clickable.defaultProps = {
  icon: "link",
  url: ""
};

export default compose(Clickable)(withRouter, withTranslation());
