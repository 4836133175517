import {
  MODULE_SEARCH_CLIENT_ACTIVE,
  MODULE_PAYMENT_ACTIVE,
} from "../../../globals/variables/variables";

const menuItems = {
  users: {
    text: "Users",
    path: "/users",
    icon: "people",
    redirectURL:
      process.env.REACT_APP_ENVIRONMENT === "qa" ||
      process.env.REACT_APP_ENVIRONMENT === "dev"
        ? `https://${process.env.REACT_APP_ENVIRONMENT}.vanalms.com/v2/searchClients`
        : "https://vanalms.com/v2/searchClients",
    active: MODULE_SEARCH_CLIENT_ACTIVE,
  },
  loans: {
    text: "Loans",
    path: "/loans",
    icon: "account_balance_wallet",
  },
  requests: {
    text: "Requests",
    path: "/requests",
    icon: "assignment",
  },
  payments: {
    text: "Payments",
    path: "/payments",
    icon: "payment",
    redirectURL:
      process.env.REACT_APP_ENVIRONMENT === "qa" ||
      process.env.REACT_APP_ENVIRONMENT === "dev"
        ? `https://${process.env.REACT_APP_ENVIRONMENT}.vanalms.com/v2/payments`
        : "https://vanalms.com/v2/payments",
    active: MODULE_PAYMENT_ACTIVE,
  },
  disbursals: {
    text: "Disbursals",
    path: "/disbursals",
    icon: "account_balance",
  },
};

export default menuItems;
