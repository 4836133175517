export const fields = {
  selfie: {
    key: "selfie",
    label: "Selfie",
    type: "image",
    columns: 12
  },
  front: {
    key: "front",
    label: "Front",
    type: "image",
    columns: 6
  },
  back: {
    key: "back",
    label: "Back",
    type: "image",
    columns: 6
  }
};
