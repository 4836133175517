import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Time from "../../../common/time/time";
import Uuid from "../../../common/uuid/uuid";
import { compose } from "../../../../util/compose";

class AnnotationListItemEvent extends React.Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };
  onEvent() {
    const { loanRequestId } = this.props.annotation.data;
    this.props.history.push(`/loans/details/${loanRequestId}`);
  }
  render() {
    return (
      <div className="annotation-type" onClick={this.onEvent.bind(this)}>
        <span className="icon">{this.props.annotation.data.icon}</span>
        <Uuid uuid={this.props.annotation.data.loanRequestId} />
        <span className="text">{this.props.annotation.data.text}</span>
        <Time time={this.props.annotation.created_at} format="MMMM DD, YYYY" />
      </div>
    );
  }
}

export default compose(AnnotationListItemEvent)(withRouter, withTranslation());
