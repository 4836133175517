import { useDispatch, useSelector } from "react-redux";
import { selectBankStatement } from "../../redux/banks/selectors";
import { revealModalView } from "../../redux/globals/thunks/revealmodalview";
import { Conditional } from "../components";
import { ButtonLabel, ContainedButton } from "../controls/button/button";
import { OutlinedIcon } from "../controls/icon/icon";
import { Card, CardContent, CardHeader } from "../layout/card/card";
import { Section } from "../layout/layout";
import Table, {
  DataCell,
  HeaderCell,
  TableBody,
  TableHeader,
  TableRow,
} from "../layout/table/table";
import "./statement.css";

const StatementRow = ({ transaction }) => {
  const values = Object.values(transaction);
  return (
    <TableRow>
      {values.map((value, key) => (
        <DataCell key={key}>{value}</DataCell>
      ))}
    </TableRow>
  );
};

const StatementTable = ({ statement }) => {
  const headers = Object.keys(statement.transactions[0]);
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {headers.map((header, key) => (
            <HeaderCell key={key}>{header}</HeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {statement.transactions.map((transaction, key) => (
          <StatementRow key={key} transaction={transaction} />
        ))}
      </TableBody>
    </Table>
  );
};

export const Statement = ({ bank, allowStatementUpdate }) => {
  const dispatch = useDispatch();
  const statement = useSelector(selectBankStatement(bank.bankId));
  const onUpdateStatementButtonClick = async () => {
    await dispatch(
      revealModalView({
        component: "banks/uploadstatement",
        props: { bankId: bank.bankId },
      })
    );
  };
  return (
    <Card className="statement" isPending={!statement}>
      <CardHeader>
        <h4>Estado de cuenta</h4>
        <Conditional condition={allowStatementUpdate}>
          <ContainedButton onClick={onUpdateStatementButtonClick} rippleLight>
            <OutlinedIcon icon="upload_file" />
            <ButtonLabel>Actualizar</ButtonLabel>
          </ContainedButton>
        </Conditional>
      </CardHeader>
      <CardContent>
        <Section>
          <Conditional
            condition={
              statement &&
              statement.transactions &&
              statement.transactions.length
            }
          >
            <StatementTable statement={statement} />
          </Conditional>
          <Conditional
            condition={
              statement &&
              statement.transactions &&
              !statement.transactions.length
            }
          >
            <p>No hay transacciones recientes.</p>
          </Conditional>
        </Section>
      </CardContent>
    </Card>
  );
};
