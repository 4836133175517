import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

class Facebook extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  render() {
    const { data, t } = this.props;
    return (
      <FieldsCard
        data={data}
        fields={fields}
        icon="face"
        title={t("Facebook")}
        subtitle={t("Data fetched from the user's facebook account")}
      />
    );
  }
}

export default compose(Facebook)(withTranslation());
