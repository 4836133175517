import { Cell, Grid, Row } from "@material/react-layout-grid";
import "@material/react-layout-grid/dist/layout-grid.css";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "../../../../util/compose";
import { withApp } from "../../../app/app";
import Card from "../../../common/card/card";
import CardContent from "../../../common/card/cardcontent";
import Fields from "../../../common/fields/fields";
import Progress from "../../../common/progress/progress";
import { withDataLayer } from "../../../datalayer/datalayer";
import { Balance } from "../../../loan/balance/balance";
import Flag from "../../../user/flag/flag";
import SideBar from "../../sidebar/sidebar";
import { BankAccount } from "../../user/details/bankaccount/bankaccount";
import Work from "../../user/details/work/work";
import { fields } from "./fields";
import Request from "./request/request";
import State from "./state/state";
import VanaMasLogo from "../../request/details/request/vanamas";
import {
  MODULE_DETAIL_LOAN_ACTIVE,
  LOAN_DETAILS_LMSV2_URL,
} from "../../../globals/variables/variables";

class Details extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onLoan: PropTypes.func,
  };
  static defaultProps = {
    onLoan: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      loan: null,
    };
  }
  componentDidUpdate(props) {
    this.props.match.params.loanRequestId !==
      props.match.params.loanRequestId && this.getLoan();
  }
  componentDidMount() {
    !this.state.loan && this.getLoan();
    if (!MODULE_DETAIL_LOAN_ACTIVE) {
      window.location.href = `${LOAN_DETAILS_LMSV2_URL}/${this.props.match.params.loanRequestId}`;
    }
  }

  getLoan() {
    const { app, dataLayer, match, t } = this.props;
    this.setState({ busy: true });
    return dataLayer.loans
      .getLoan(match.params.loanRequestId, true)
      .then(async (loan) => {
        loan.promissory_note =
          loan.promissory_note ??
          `${process.env.REACT_APP_CONTRACT_CDN_URL}/pn_${loan.user_id}-${loan.loan_id}.pdf`;

        if (!loan.signature) {
          try {
            const signature = await dataLayer.users.getSignature(loan.user_id);
            loan.signature = signature.user_signature;
          } catch (error) {
            app.onError({
              text: t("Error fetching user signature data."),
              data: error.details,
            });
          }
        }
        this.setState({ loan });
        this.setState({ busy: false });
      })
      .catch((error) =>
        app.onError({
          text: t("Error fetching loan data."),
          data: error.details,
        })
      );
  }

  updateStatus(status) {
    const { app, dataLayer, t } = this.props;
    const { loan_request_id, user_id } = this.state.loan;
    return dataLayer.loans
      .updateLoanStatus(loan_request_id, user_id, status)
      .then(() => {
        app.onSnackbar(t("Loan status has been updated."));
        this.getLoan();
      })
      .catch((error) =>
        app.onError({
          text: t("Error updating loan status."),
          data: error.details,
        })
      );
  }
  render() {
    const { t } = this.props;
    const { loan, busy } = this.state;
    const groupData = {};
    const showBalance =
      loan &&
      loan.state &&
      (loan.state.status === "released" || loan.state.status === "fulfilled");

    const dailyFeeData = loan
      ? {
          dailyFee: loan.penalty_rate,
          principalAmount: loan.amount,
          principalSettled: loan.state.settled_principal ?? 0,
          isFlexibleOffer: loan.loan_state.offer_type === "flexible_offer",
          installments: loan.installments,
          late_installments: loan.state.late_installments ?? 0,
        }
      : {
          dailyFee: 0,
          principalAmount: 0,
          principalSettled: 0,
          isFlexibleOffer: false,
          installments: 0,
          late_installments: 0,
        };
    Object.keys(fields).forEach((key) => {
      groupData[key] = Object.assign(
        { value: loan ? loan[fields[key].key] : "" },
        fields[key]
      );
    });
    const user = loan?.user;
    return (
      <Progress
        className="loan details sidebar-layout"
        loading={!loan || busy}
        text={t("Fetching loan details")}
      >
        {() => [
          <div key="content" className="profile content">
            <Grid>
              <Cell columns={12}>
                <Row>
                  <Cell columns={12}>
                    <Row>
                      <Cell columns={12}>
                        <Row>
                          <Cell columns={12}>
                            <div className="title-wrapper">
                              <Flag country={user?.personal?.country} />
                              <span className="title-1">
                                {t("Loan details")}
                              </span>
                              {loan !== null &&
                                loan.state.offer_type ===
                                  "short_term_installments" && <VanaMasLogo />}
                            </div>
                          </Cell>
                          <Cell columns={12}>
                            <Card>
                              <CardContent>
                                <Fields data={groupData} />
                              </CardContent>
                            </Card>
                          </Cell>
                          {showBalance && (
                            <Cell columns={12}>
                              <Balance
                                loanId={loan.loan_id}
                                userId={loan.user_id}
                                dailyFeeData={dailyFeeData}
                              />
                            </Cell>
                          )}
                          <Cell columns={12}>
                            <Request data={loan} />
                          </Cell>
                          <Cell columns={12}>
                            <State
                              data={loan.loan_state}
                              onUpdate={this.updateStatus.bind(this)}
                            />
                          </Cell>
                          <Cell columns={12}>
                            <Work data={loan?.user?.work || {}}></Work>
                          </Cell>
                          <Cell columns={12}>
                            <BankAccount
                              userId={loan && loan.user_id ? loan.user_id : ""}
                            />
                          </Cell>
                        </Row>
                      </Cell>
                    </Row>
                  </Cell>
                </Row>
              </Cell>
            </Grid>
          </div>,
          <SideBar key="sidebar" user={loan.user} loan={loan} />,
        ]}
      </Progress>
    );
  }
}

export default compose(Details)(
  withApp,
  withDataLayer,
  withRouter,
  withTranslation()
);
