const getElementRect = (element) => {
  const rect = element ? element.getBoundingClientRect() : {};
  return {
    right: rect.right || 0,
    top: rect.top || 0,
    bottom: rect.bottom || 0,
    left: rect.left || 0,
    width: rect.width || 0,
    height: rect.height || 0,
  };
};

export const anchorTo = (x, y) => {
  const rect = {
    right: x,
    top: y,
    bottom: y,
    left: x,
    width: 0,
    height: 0,
  };
  return { anchor: { rect, type: "c" } };
};

export const anchorToBottomLeftOf = (element) => {
  const rect = getElementRect(element);
  return { anchor: { rect, type: "bl" } };
};

export const anchorToBottomRightOf = (element) => {
  const rect = getElementRect(element);
  return { anchor: { rect, type: "br" } };
};

export const anchorToCenterOf = (element) => {
  const rect = getElementRect(element);
  return { anchor: { rect, type: "c" } };
};

export const anchorToTopLeftOf = (element) => {
  const rect = getElementRect(element);
  return { anchor: { rect, type: "tl" } };
};

export const anchorToTopRightOf = (element) => {
  const rect = getElementRect(element);
  return { anchor: { rect, type: "tr" } };
};

export const anchorVerticalToRightOf = (element, offset = 0) => {
  const rect = getElementRect(element);
  return { anchor: { offset: { y: offset }, rect, type: "vr" } };
};

export const anchorVerticalToLeftOf = (element, offset = 0) => {
  const rect = getElementRect(element);
  return { anchor: { offset: { y: offset }, rect, type: "vl" } };
};
