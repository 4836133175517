export const paymentStatus = {
  APPROVED: "approved",
  CREATED: "created",
  NOT_FOUND: "not_found",
  PROCESSING: "processing",
  REJECTED: "rejected",
  UPLOADING: "uploading",
};

export const paymentStatusLabels = {
  [paymentStatus.APPROVED]: "Aprobado",
  [paymentStatus.CREATED]: "Creado",
  [paymentStatus.NOT_FOUND]: "No encontrado",
  [paymentStatus.PROCESSING]: "Procesando",
  [paymentStatus.REJECTED]: "Rechazado",
  [paymentStatus.UPLOADING]: "Cargando",
};
