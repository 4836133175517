export const fields = {
  created_at: {
    key: "updated_at",
    label: "Created at",
    icon: "lock",
    readonly: true,
  },
  updated_at: {
    key: "updated_at",
    label: "Updated at",
    icon: "lock",
    readonly: true,
  },
  scope_version: {
    key: "scope_version",
    label: "Scope version",
    icon: "lock",
    readonly: true,
  },
  loan_review: {
    key: "loan_review",
    label: "Loan review",
    icon: "lock",
    type: "select",
    columns: 6,
    defaultValue: "no",
    options: [
      { label: "Yes", value: "yes", from: [] },
      { label: "No", value: "no", from: [] },
    ],
  },
  loan_amount_level: {
    key: "loan_amount_level",
    label: "Loan amount level",
    icon: "stairs",
    readonly: true,
    columns: 6,
    defaultValue: "0",
  },
  interest_rate_level: {
    key: "interest_rate_level",
    label: "Interest rate level",
    icon: "lock",
    readonly: true,
    columns: 6,
  },
};
