import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectViewPort } from "../../../redux/globals/selectors";
import { BlockingView } from "../../layout/blockingview/blockingview";
import "./dropdown.css";
import {
  ModalView,
  ModalViewAnchor,
  ModalViewContainer,
  ModalViewLayout,
  ModalViewScrim,
} from "./modalview";

export const DropDownHeader = ({ children, className }) => {
  return <div className={classNames(className, "header")}>{children}</div>;
};

export const DropDownContent = ({ isPending, children, className }) => {
  return (
    <BlockingView
      isPending={isPending}
      className={classNames(className, "content")}
    >
      {children}
    </BlockingView>
  );
};

export const DropDown = ({
  anchor,
  children,
  className,
  constraint,
  isPending,
  onHide,
  origin,
  offset,
  ...otherProps
}) => {
  const onScrimClick = async () => {
    await onHide();
  };
  const viewPort = useSelector(selectViewPort());
  const style = { container: {}, layout: {} };
  const anchorType = anchor?.type ? anchor.type : "c";
  const viewPortCenterX = viewPort.width / 2;
  const viewPortCenterY = viewPort.height / 2;
  const offsetY = anchor?.offset?.y ? anchor?.offset.y : 0;
  let orientationX = "ttb";
  let orientationY = "rtl";
  if (anchorType === "bl") {
    style.layout.left = anchor.rect.left - origin.left;
    style.layout.top = anchor.rect.bottom - origin.top;
    orientationX = anchor.rect.left > viewPortCenterX ? "rtl" : "ltr";
    orientationY = anchor.rect.bottom > viewPortCenterY ? "btt" : "ttb";
  } else if (anchorType === "br") {
    style.layout.left = anchor.rect.right - origin.left;
    style.layout.top = anchor.rect.bottom - origin.top;
    orientationX = anchor.rect.right > viewPortCenterX ? "rtl" : "ltr";
    orientationY = anchor.rect.bottom > viewPortCenterY ? "btt" : "ttb";
  } else if (anchorType === "c") {
    const centerY = anchor.rect.bottom - anchor.rect.height / 2;
    const centerX = anchor.rect.right - anchor.rect.width / 2;
    style.layout.left = centerX - origin.left;
    style.layout.top = centerY - origin.top;
    orientationX = centerX > viewPortCenterX ? "rtl" : "ltr";
    orientationY = centerY > viewPortCenterY ? "btt" : "ttb";
  } else if (anchorType === "tl") {
    style.layout.left = anchor.rect.left - origin.left;
    style.layout.top = anchor.rect.top - origin.top;
    orientationX = anchor.rect.left > viewPortCenterX ? "rtl" : "ltr";
    orientationY = anchor.rect.top > viewPortCenterY ? "btt" : "ttb";
  } else if (anchorType === "tr") {
    style.layout.left = anchor.rect.right - origin.left;
    style.layout.top = anchor.rect.top - origin.top;
    orientationX = anchor.rect.right > viewPortCenterX ? "rtl" : "ltr";
    orientationY = anchor.rect.top > viewPortCenterY ? "btt" : "ttb";
  } else if (anchorType === "vr") {
    if (anchor.rect.bottom + offsetY < viewPortCenterY) {
      const anchorY = anchor.rect.bottom + offsetY;
      style.layout.top = anchorY - origin.top;
      style.layout.left = anchor.rect.right - origin.left;
      orientationY = "ttb";
      orientationX = anchor.rect.right > viewPortCenterX ? "rtl" : "ltr";
    } else {
      const anchorY = anchor.rect.top - offsetY;
      style.layout.left = anchor.rect.right - origin.left;
      style.layout.top = anchorY - origin.top;
      orientationX = anchor.rect.right > viewPortCenterX ? "rtl" : "ltr";
      orientationY = "btt";
    }
  } else if (anchorType === "vl") {
    if (anchor.rect.bottom + offsetY < viewPortCenterY) {
      const anchorY = anchor.rect.bottom + offsetY;
      style.layout.top = anchorY - origin.top;
      style.layout.left = anchor.rect.left - origin.left;
      orientationY = "ttb";
      orientationX = anchor.rect.left > viewPortCenterX ? "rtl" : "ltr";
    } else {
      const anchorY = anchor.rect.top - offsetY;
      style.layout.left = anchor.rect.left - origin.left;
      style.layout.top = anchorY - origin.top;
      orientationX = anchor.rect.left > viewPortCenterX ? "rtl" : "ltr";
      orientationY = "btt";
    }
  }
  /* compute constraints */
  if (constraint && constraint.width) {
    style.container.maxWidth = constraint.width;
  }
  return (
    <ModalView {...otherProps} className={classNames(className, "drop-down")}>
      <ModalViewScrim onClick={onScrimClick} />
      <ModalViewLayout style={style.layout}>
        <ModalViewAnchor>
          <ModalViewContainer
            className={classNames(orientationX, orientationY)}
            isPending={isPending}
            style={style.container}
          >
            {children}
          </ModalViewContainer>
        </ModalViewAnchor>
      </ModalViewLayout>
    </ModalView>
  );
};
