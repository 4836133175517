import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { compose } from "../../../../util/compose";

class ToolbarButton extends React.Component {
  static propTypes = {
    button: PropTypes.object.isRequired,
    className: PropTypes.string,
    toggle: PropTypes.bool,
    onClick: PropTypes.func,
    t: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
    toggle: false,
    onClick: (tool) => {},
  };
  onClick() {
    this.props.onClick(this.props.button);
  }
  render() {
    const { className, button, toggle, t } = this.props;
    return (
      <button
        className={classNames(className, { toggle })}
        onClick={this.onClick.bind(this)}
      >
        {button.icon && <span className="icon">{button.icon}</span>}
        {button.text && <span className="text">{t(button.text)}</span>}
      </button>
    );
  }
}

export default compose(ToolbarButton)(withTranslation());
