import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkTypes } from "../thunktypes";
import { actions } from "../slice";

export const handleAuth = createAsyncThunk(
  thunkTypes.HANDLE_AUTH,
  async (params, { dispatch, extra }) => {
    const session = await extra.authRepo.handleAuth();
    await dispatch(actions.setSession(session));
    return session;
  }
);
