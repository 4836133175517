import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, withRouter } from "react-router-dom";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import Details from "./details/details";
import Search from "./search/search";
import { compose } from "../../../util/compose";
import "@material/react-layout-grid/dist/layout-grid.css";

class User extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/details/:userId`}>
          <Details />
        </Route>
        <Route path={match.path}>
          <Grid>
            <Row>
              <Cell columns={12}>
                <Search />
              </Cell>
            </Row>
          </Grid>
        </Route>
      </Switch>
    );
  }
}

export default compose(User)(withRouter);
