import { useState } from "react";
import {
  bankValidator,
  createOkResult,
  dateValidator,
  numericValidator,
  paymentAmountValidator,
  paymentBankAgencyValidator,
  paymentBankOriginValidator,
  paymentBankValidator,
  paymentDateValidator,
  paymentReceiptFileValidator,
  paymentReceiptValidator,
  paymentReferenceValidator,
  uuidValidator,
} from "../util/validators";

export const useTextInput = (evaluator, initValue) => {
  const [value, setValue] = useState(initValue);
  const [evaluation, setEvaluation] = useState(createOkResult());
  const evaluate = () => {
    return evaluator ? evaluator(value) : evaluation;
  };
  const init = (value) => {
    setEvaluation(createOkResult());
    setValue(value);
  };
  const update = (value) => {
    const evaluation = evaluator ? evaluator(value) : createOkResult();
    setEvaluation(evaluation);
    setValue(value);
  };
  const validate = () => {
    const evaluation = evaluator ? evaluator(value) : createOkResult();
    setEvaluation(evaluation);
    return evaluation;
  };
  return {
    ...evaluation,
    evaluate,
    init,
    update,
    validate,
    value,
  };
};

/* general text inputs */

export const useBankTextInput = (initValue) => {
  return useTextInput(bankValidator(), initValue);
};

export const useDateTextInput = (initValue) => {
  return useTextInput(dateValidator(), initValue);
};

export const useNumericTextInput = (initValue) => {
  return useTextInput(numericValidator(), initValue);
};

export const useUuidTextInput = (initValue) => {
  return useTextInput(uuidValidator(), initValue);
};

/* payment text inputs */

export const usePaymentBankAgencyTextInput = (initValue) => {
  return useTextInput(paymentBankAgencyValidator(), initValue);
};

export const usePaymentAmountTextInput = (initValue) => {
  return useTextInput(paymentAmountValidator(), initValue);
};

export const usePaymentBankTextInput = (initValue) => {
  return useTextInput(paymentBankValidator(), initValue);
};

export const usePaymentBankOriginTextInput = (initValue) => {
  return useTextInput(paymentBankOriginValidator(), initValue);
};

export const usePaymentDateTextInput = (initValue) => {
  return useTextInput(paymentDateValidator(), initValue);
};

export const usePaymentReferenceTextInput = (initValue) => {
  return useTextInput(paymentReferenceValidator(), initValue);
};

export const usePaymentReceiptTextInput = (initValue) => {
  return useTextInput(paymentReceiptValidator(), initValue);
};

export const usePaymentReceiptFileInput = (initValue) => {
  return useTextInput(paymentReceiptFileValidator(), initValue);
};
