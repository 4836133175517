import React from "react";

function VanaMasLogo() {
  const vanaMas = `/img/vanamas_logo.png`;
  return (
    <div
      className="vana_mas_tag"
      style={{ backgroundImage: `url(${vanaMas})` }}
    />
  );
}

export default VanaMasLogo;
