import { BalanceInstallmentItem } from "./balance-installment-item";

export const BalanceInstallmentList = ({ installments, country }) => {
  const items = installments?.length ? installments : [];
  return (
    <ol className="installments">
      {items.map((installment, key) => (
        <BalanceInstallmentItem
          {...installment}
          country={country}
          installmentNumber={key + 1}
          key={key}
        />
      ))}
    </ol>
  );
};
