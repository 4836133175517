import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { compose } from "../../../util/compose";

import "./calendar.css";

const labels = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  days: ["S", "M", "T", "W", "T", "F", "S"],
};

class Calendar extends React.Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    format: PropTypes.string,
    onDate: PropTypes.func,
    t: PropTypes.func.isRequired,
    type: PropTypes.string,
  };
  static defaultProps = {
    format: "YYYY-MM-DD",
    onDate: ({ from, to }) => {},
    type: "date",
  };
  onMonth() {
    switch (this.props.type) {
      case "range":
        const date = this.props.date.from
          ? Moment(this.props.date.from)
          : Moment();
        this.props.onDate({
          from: date.startOf("month").format(this.props.format),
          to: date.endOf("month").format(this.props.format),
        });
        break;
      default:
        throw new Error(`invalid calendar type ${this.props.type}`);
    }
  }
  onDay(day) {
    switch (this.props.type) {
      case "date":
        this.onDate(day);
        break;
      case "range":
        this.onRange(day);
        break;
      default:
        throw new Error(`invalid calendar type ${this.props.type}`);
    }
  }
  onDate(day) {
    this.props.onDate({ from: day, to: day });
  }
  onRange(day) {
    let from = this.props.date.from;
    let to = this.props.date.to;
    if (!from && !to) {
      from = day;
    } else if (from && !to) {
      to = day;
    } else {
      from = day;
      to = null;
    }
    if (from && to && to < from) {
      const f = from;
      from = to;
      to = f;
    }
    this.props.onDate({ from, to });
  }
  render() {
    const date = this.props.date;
    const from = date.from ? Moment(date.from) : Moment();
    const to = date.to ? Moment(date.to) : from;
    const year = Number(from.format("YYYY"));
    const month = Number(from.format("M"));
    const days = Moment(`${year}-${month}`, "YYYY-M").daysInMonth();
    const first = Moment(`${year}-${month}-1`, "YYYY-M-D");
    const pad = Number(first.format("d"));
    const allMonth =
      from.clone().startOf("month").format("YYYY-MM-DD") ===
        from.format("YYYY-MM-DD") &&
      to.clone().endOf("month").format("YYYY-MM-DD") ===
        to.format("YYYY-MM-DD");
    return (
      <div
        className={classNames("calendar", `calendar-${this.props.type}`, {
          "all-month": allMonth,
        })}
      >
        <div className="title" onClick={this.onMonth.bind(this)}>
          <span>{from.format("MMMM YYYY")}</span>
        </div>
        <div className="days">
          {labels.days.map((d, key) => (
            <span key={`l${key}`} className="day label">
              {this.props.t(d)}
            </span>
          ))}
          {[...Array(pad).keys()].map((d, key) => (
            <span key={`p${key}`} className="day">
              <span>&nbsp;</span>
            </span>
          ))}
          {[...Array(days).keys()].map((d, key) => {
            const pattern = `${year}-${month}-${d + 1}`;
            const value = Moment(pattern, "YYYY-M-D").format(this.props.format);
            const f = from.format(this.props.format);
            const t = to.format(this.props.format);
            const selected = f <= value && value <= t;
            const first = value === f;
            const last = value === t;
            return (
              <button
                key={`d${key}`}
                onClick={() => this.onDay(value)}
                className={classNames("day", { selected, first, last })}
              >
                <span>{d + 1}</span>
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

export default compose(Calendar)(withTranslation());
