export const mergeBanks = (state, action) => {
  if (Array.isArray(action.payload)) {
    for (const payload of action.payload) {
      mergeBank(state, { payload });
    }
  }
};

export const mergeBank = (state, action) => {
  const next = action.payload && action.payload.bankId ? action.payload : "";
  if (next) {
    if (!state.banks) {
      state.banks = {};
    }
    const prev = state.banks[next.bankId] ? state.banks[next.bankId] : {};
    state.banks = { ...state.banks, [next.bankId]: { ...prev, ...next } };
  }
};

export const mergeBankStatement = (state, action) => {
  const next = action.payload && action.payload.bankId ? action.payload : "";
  if (next) {
    if (!state.statements) {
      state.statements = {};
    }
    const prev = state.statements[next.bankId]
      ? state.statements[next.bankId]
      : {};
    state.statements = {
      ...state.statements,
      [next.bankId]: { ...prev, ...next },
    };
  }
};
