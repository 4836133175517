import React from "react";
import { withTranslation } from "react-i18next";
import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

const StateWrap = ({ t, data }) => {
  return (
    <FieldsCard
      key={data.loan_review}
      data={data}
      editable={true}
      fields={fields}
      icon="perm_data_setting"
      title={t("User state")}
    />
  );
};

export const State = compose(StateWrap)(withTranslation());
