import Moment from "moment";

const AnnotationListAdapter = {
  tools: {
    comment: { text: "Comment", icon: "comment" },
    promise: { text: "Promise", icon: "event" },
    review: { text: "Review", icon: "flag" },
  },
  filters: {
    status: {
      text: "Type",
      type: "filter",
      options: {
        promise: { text: "Promise", icon: "event" },
        review: { text: "Review", icon: "flag" },
        comment: { text: "Comment", icon: "comment" },
      },
      onFilter: (items, selected) => {
        console.log(
          "AnnotationListAddapter.filters.status.onFilter",
          items,
          selected
        );
        return items.filter(
          ({ type }) => !selected.length || selected.includes(type)
        );
      },
    },
    sort: {
      text: "Sort by",
      type: "choice",
      options: {
        recent: { text: "Recent", icon: "arrow_upward" },
        oldest: { text: "Oldest", icon: "arrow_downward" },
      },
      onFilter: (items, selected) => {
        switch (selected[0]) {
          case "recent":
            return [...items].sort((a, b) => {
              if (a.created_at < b.created_at) return 1;
              if (a.created_at > b.created_at) return -1;
              return 0;
            });
          case "oldest":
            return [...items].sort((a, b) => {
              if (a.created_at > b.created_at) return 1;
              if (a.created_at < b.created_at) return -1;
              return 0;
            });
          default:
            return items;
        }
      },
    },
  },
  groups: {
    date: {
      icon: "history",
      filters: {
        sort: ["recent", "oldest"],
      },
      onText: (a) => Moment(a.created_at).fromNow(),
      onGroup: (a, b) => {
        const c = Moment(a.created_at).fromNow();
        const d = Moment(b ? b.created_at : 0).fromNow();
        return c !== d;
      },
    },
  },
};

export default AnnotationListAdapter;
