import React from 'react';
import PropTypes from 'prop-types';
import { ChipSet, Chip } from '@material/react-chips';
import MaterialIcon from '@material/react-material-icon';
import { withTranslation } from 'react-i18next';
import { compose } from '../../../util/compose';
import '@material/react-material-icon/dist/material-icon.css';
import '@material/react-chips/dist/chips.css';
import Flag from '../../user/flag/flag';

class ListFilters extends React.Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    selected: PropTypes.object,
    onFilter: PropTypes.func,
    t: PropTypes.func,
  };
  static defaultProps = {
    selected: {},
    onFilter: () => {},
  };
  onFilter(filter, selected) {
    const update = {};
    update[filter] = selected;
    this.props.onFilter(Object.assign({}, this.props.selected, update));
  }
  render() {
    return (
      <div className="list-filters">
        {Object.keys(this.props.filters).map((filter, key) => (
          <div key={key} className="filter">
            <h4>{this.props.filters[filter].text}</h4>
            <ChipSet
              filter={this.props.filters[filter].type === 'filter'}
              choice={this.props.filters[filter].type === 'choice'}
              selectedChipIds={this.props.selected[filter] || []}
              handleSelect={(selected) => this.onFilter(filter, selected)}
            >
              {Object.keys(this.props.filters[filter].options).map((option) => {
                const label = this.props.filters[filter].options[option].text;
                const icon = this.props.filters[filter].options[option].icon;
                const flagIconParts = icon.split('IconFlag');
                const isFlagIcon = flagIconParts.length > 1;
                return (
                  <Chip
                    id={option}
                    label={this.props.t(label)}
                    key={`${key}-${option}`}
                    shouldRemoveOnTrailingIconClick={false}
                    trailingIcon={
                      !isFlagIcon ? (
                        <MaterialIcon icon={icon} />
                      ) : (
                        <span>
                          <span className="flag-filter-icon">
                            <Flag country={flagIconParts[1]} />
                          </span>
                        </span>
                      )
                    }
                  />
                );
              })}
            </ChipSet>
          </div>
        ))}
      </div>
    );
  }
}

export default compose(ListFilters)(withTranslation());
