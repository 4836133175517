import axios from "axios";
import { isString } from "../util/validators";
import Api from "./api";

const METHODS = {
  GET: "get",
  PATCH: "patch",
  POST: "post",
};

export const createApiClient = (authClient) => {
  const client = axios.create({
    baseURL: Api.base,
  });
  const executeApiCall = async (params) => {
    if (!params.method || !Object.values(METHODS).includes(params.method)) {
      throw new Error(`invalid http method ${params.method}`);
    }
    const session = await authClient.getCurrentSession();
    if (!session || !session.token || !session.token.access) {
      throw new Error("invalid session token");
    }
    let result = "";
    try {
      result = await client({
        method: params.method,
        url: params.url,
        params: params.params,
        data: { data: params.data },
        headers: {
          ...params.headers,
          Authorization: session.token.access,
        },
      });
    } catch (error) {
      if (isString(error?.response?.data?.error)) {
        throw new Error(error.response.data.error);
      }
      if (isString(error?.response?.data?.error?.body)) {
        throw new Error(error.response.data.error.body);
      }
      throw error;
    }
    return result && result.data ? result.data : "";
  };
  const get = async (params) => {
    return await executeApiCall({
      method: METHODS.GET,
      url: params.url,
      params: params.params,
      header: params.headers,
    });
  };
  const patch = async (params) => {
    return await executeApiCall({
      method: METHODS.PATCH,
      url: params.url,
      params: params.params,
      data: params.data,
      header: params.headers,
    });
  };
  const post = async (params) => {
    return await executeApiCall({
      method: METHODS.POST,
      url: params.url,
      params: params.params,
      data: params.data,
      header: params.headers,
    });
  };
  return {
    get,
    patch,
    post,
  };
};
