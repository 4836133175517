import React from "react";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { withTranslation } from "react-i18next";

import { compose } from "../../../util/compose";

import "@material/react-layout-grid/dist/layout-grid.css";

class Device extends React.Component {
  render() {
    return (
      <Grid>
        <Row>
          <Cell columns={8}>{this.props.t("Devices")}</Cell>
        </Row>
      </Grid>
    );
  }
}

export default compose(Device)(withTranslation());
