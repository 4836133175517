import classNames from "classnames";
import { BlockingView } from "../../layout/blockingview/blockingview";
import "./modalview.css";

export const ModalView = ({ children, className, ...otherProps }) => {
  return (
    <div {...otherProps} className={classNames(className, "modal-view")}>
      {children}
    </div>
  );
};
export const ModalViewScrim = ({ className, children, ...otherProps }) => {
  return (
    <div {...otherProps} className={classNames(className, "scrim")}>
      {children}
    </div>
  );
};
export const ModalViewLayout = ({ children, ...otherProps }) => {
  return (
    <div {...otherProps} className="layout">
      {children}
    </div>
  );
};
export const ModalViewAnchor = ({ children, ...otherProps }) => {
  return (
    <div {...otherProps} className="anchor">
      {children}
    </div>
  );
};
export const ModalViewContainer = ({
  children,
  className,
  isPending,
  ...otherProps
}) => {
  return (
    <BlockingView
      {...otherProps}
      isPending={isPending}
      className={classNames(className, "container")}
    >
      {children}
    </BlockingView>
  );
};
