import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import List from "../../common/list/list";
import ListItems from "../../common/list/listitems";
import ListToolbar from "../../common/list/toolbar/toolbar";
import RequestListItem from "./item/item";
import { compose } from "../../../util/compose";
import "./list.css";
import { connect } from "react-redux";
import { setLoanRequestfilterReducer } from "../../../redux/request/actions";

class RequestList extends React.Component {
  static propTypes = {
    adapter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    requests: PropTypes.array,
    loading: PropTypes.bool,
    selected: PropTypes.string,
    onReload: PropTypes.func,
    onFilter: PropTypes.func,
    onFilterCollection: PropTypes.string,
    type: PropTypes.string
  };
  static defaultProps = {
    loading: false,
    onRequest: (loan) => {},
    onReload: () => {},
    onFilter: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      filters: this.props.filtersSaved?.[this.props.onFilterCollection] ?? {
        sort: ["recent"],
      },
      rows: null,
    };
  }
  componentDidMount() {
    !this.props.requests && this.props.onReload();
    this.onAfterFilter();
  }

  filter() {
    if (!this.props.requests) {
      return this.props.requests;
    }
    const adapter = this.props.adapter;
    const filters = this.state.filters;
    let sorted = this.props.requests;
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filters[filter].length) {
        sorted = adapter.filters[filter].onFilter(sorted, filters[filter]);
      }
    });
    return sorted;
  }
  onFilter(filters) {
    this.setState({ filters });

    // Save filter to reuse it when re-enter again to page
    const payload = {
      source: this.props.onFilterCollection,
      filters: filters,
    };
    this.props.setLoanRequestfilterReducer(payload);
  }
  onAfterFilter(rows) {
    if (this.state.length !== rows) {
      this.setState({ length: rows });
      this.props.onFilter(this.props.onFilterCollection, rows);
    }
  }
  onRequest(request) {
    this.props.history.push(`/requests/details/${request.loan_request_id}`);
  }
  render() {
    
    return (
      
      <List className="requests">
        <ListToolbar
          filters={this.props.adapter.filters}
          loading={this.props.loading}
          selected={{ filters: this.state.filters }}
          onFilter={this.onFilter.bind(this)}
          onReload={this.props.onReload}
        />

        <ListItems
          adapter={this.props.adapter}
          filters={this.state.filters}
          loading={this.props.loading}
          items={this.filter()}
          onUpdate={this.onAfterFilter.bind(this)}
        >
         
        
          {(request, key) => (

            <RequestListItem
              key={key}
              request={request}
              selected={request.loan_request_id === this.props.selected}
              onSelect={this.onRequest.bind(this)}
              linkRef={`/requests/details/${request.loan_request_id}`}
              type={`${this.props.type}`}
            />
          )}
        </ListItems>
      </List>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filtersSaved: state.request,
  };
};

const mapDispatchToProps = {
  setLoanRequestfilterReducer,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(RequestList)
)(withRouter);
