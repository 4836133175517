import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { compose } from "../../../../util/compose";

class AnnotationListItemReview extends React.Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };
  render() {
    return (
      <div className="annotation-type">
        <div className="type-data review">
          <h4>{this.props.t("Review")}</h4>
          <span>{this.props.t(this.props.annotation.data.review ? "Yes" : "No")}</span>
        </div>
      </div>
    );
  }
}

export default compose(AnnotationListItemReview)(withTranslation());
