import { actions } from "./slice";

export const {
  mergePayment,
  mergePayments,
  mergePendingPayment,
  mergeFulfilledPayment,
  mergeFulfilledPayments,
  mergeRejectedPayment,
} = actions;
