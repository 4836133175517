import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MODULE_ANNOTATIONS_ACTIVE } from "../../../components/globals/variables/variables";
import AnnotationListInput from "./listinput";
import AnnotationListItem from "./item/item";
import AnnotationListItems from "./listitems";
import ArrayUtils from "../../../util/arrayutils";
import List from "../../common/list/list";
import ListToolbar from "../../common/list/toolbar/toolbar";
import "./list.css";

function AnnotationList(props) {
  const [tools, setTools] = useState([]);
  const [filters, setFilters] = useState({ sort: ["recent"] });

  useEffect(() => {
    if (!props.annotations) {
      props.onReload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filter = () => {
    if (!props.annotations) {
      return props.annotations;
    }
    const adapter = props.adapter;
    const events = getEvents();
    let sorted = [...props.annotations, ...events];
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filters[filter].length) {
        sorted = adapter.filters[filter].onFilter(sorted, filters[filter]);
      }
    });
    return sorted;
  };

  const onFilter = (filters) => {
    setFilters(filters);
  };

  const onTool = (tools) => {
    setTools(tools);
  };

  const getEvents = () => {
    const events = [];
    const filt = filters.sort || [];
    const includes = ArrayUtils.containsAll(["recent", "oldest"], filt);
    if (!props.loans || !filt.length || !includes) {
      return events;
    }
    for (const { state, loan_request_id } of props.loans) {
      if (state.released_datetime) {
        events.push({
          created_at: state.released_datetime,
          type: "event",
          data: {
            icon: "sync_alt",
            text: `released`,
            loanRequestId: loan_request_id,
          },
        });
      }
      if (state.fulfilled_datetime) {
        events.push({
          created_at: state.fulfilled_datetime,
          type: "event",
          data: {
            icon: "done_all",
            text: `fulfilled`,
            loanRequestId: loan_request_id,
          },
        });
      }
    }
    return events;
  };

  return (
    <List className="annotations">
      <AnnotationListItems
        adapter={props.adapter}
        loading={props.loading}
        filters={filters}
        events={getEvents()}
        items={filter()}
      >
        {(annotation, key) => (
          <AnnotationListItem
            key={key}
            annotation={annotation}
            onSelect={props.onAnnotation}
          />
        )}
      </AnnotationListItems>
      {MODULE_ANNOTATIONS_ACTIVE && (
        <ListToolbar
          loading={props.loading}
          selected={{ tools, filters }}
          tools={props.adapter.tools}
          filters={props.adapter.filters}
          onTool={onTool}
          onFilter={onFilter}
          onReload={props.onReload}
        />
      )}

      {!tools.length ? null : (
        <AnnotationListInput
          userId={props.userId}
          loanId={props.loanId}
          posting={props.posting}
          loanRequestId={props.loanRequestId}
          type={tools[0]}
          onPost={props.onPost}
          comment={props.comment}
          onChangeComment={props.onChangeComment}
        />
      )}
    </List>
  );
}

AnnotationList.propTypes = {
  adapter: PropTypes.object,
  userId: PropTypes.string.isRequired,
  loanId: PropTypes.string,
  loanRequestId: PropTypes.string,
  loans: PropTypes.array,
  annotations: PropTypes.array,
  loading: PropTypes.bool,
  posting: PropTypes.bool,
  selected: PropTypes.string,
  onAnnotation: PropTypes.func,
  onReload: PropTypes.func,
  onPost: PropTypes.func,
};

AnnotationList.defaultProps = {
  loanId: "",
  loanRequestId: "",
  loading: false,
  posting: false,
  loans: [],
  onAnnotation: (annotation) => Promise.resolve(),
  onPost: (annotation) => Promise.resolve(),
  onReload: () => Promise.resolve(),
};

export default AnnotationList;
