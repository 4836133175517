import classNames from "classnames";
import "./table.css";

export const TableRow = ({ className, children }) => (
  <tr className={classNames("table-row", className)}>{children}</tr>
);

export const TableHeader = ({ className, children }) => (
  <thead className={classNames("table-header", className)}>{children}</thead>
);

export const TableBody = ({ className, children }) => (
  <tbody className={classNames("table-body", className)}>{children}</tbody>
);

export const HeaderCell = ({ className, children, ...otherProps }) => (
  <th {...otherProps} className={classNames("header-cell", className)}>
    {children}
  </th>
);

export const DataCell = ({ className, children }) => (
  <td className={classNames("data-cell", className)}>{children}</td>
);

const Table = ({ className, children }) => (
  <table className={classNames("table", className)}>{children}</table>
);

export default Table;
