import classNames from "classnames";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllModalViews } from "../../../redux/globals/selectors";
import { hideModalView } from "../../../redux/globals/thunks/hidemodalview";
import { updateModalView } from "../../../redux/globals/thunks/updatemodalview";
import { updateViewPort } from "../../../redux/globals/thunks/updateviewport";
import { Lazy } from "../../components";
import "./modalviews.css";

const ModalViewRenderer = ({ id, origin, ...modalView }) => {
  const dispatch = useDispatch();
  const onHide = async () => {
    await dispatch(hideModalView({ id }));
  };
  const onMerge = async (modalView) => {
    await dispatch(updateModalView({ id, ...modalView }));
  };
  const props = modalView.props ? modalView.props : {};
  return (
    <Lazy
      fallback={null}
      {...props}
      anchor={modalView.anchor}
      className={classNames({ visible: modalView.isVisible })}
      component={modalView.component}
      constraint={modalView.constraint}
      id={id}
      onHide={onHide}
      onMerge={onMerge}
      origin={origin}
    />
  );
};

export const ModalViews = ({ className }) => {
  const dispatch = useDispatch();
  const element = useRef(null);
  const modalViews = useSelector(selectAllModalViews());
  const origin = element.current
    ? element.current.getBoundingClientRect()
    : { left: 0, top: 0 };
  useEffect(() => {
    /* TODO: move this into top level app component */
    const onResize = () => {
      const rect = window.document.body.getBoundingClientRect();
      dispatch(
        updateViewPort({
          height: rect.height,
          width: rect.width,
        })
      );
    };
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [dispatch]);
  return (
    <div ref={element} className={classNames(className, "modal-views")}>
      {modalViews.map((modalView) => {
        return (
          <ModalViewRenderer
            {...modalView}
            origin={origin}
            key={modalView.id}
          />
        );
      })}
    </div>
  );
};
