import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import ListItemTags from "../../../common/list/item/tags/tags";
import { compose } from "../../../../util/compose";

class PaymentListItemTags extends React.Component {
  static propTypes = {
    payment: PropTypes.object.isRequired,
  };
  render() {
    const { payment, t } = this.props;
    const tags = [];
    payment.status === "processing" &&
      tags.push({
        className: "processing",
        icon: "sync",
        text: t("Processing"),
      });
    payment.status === "approved" &&
      tags.push({
        className: "approved",
        icon: "done",
        text: t("Approved"),
      });
    payment.status === "rejected" &&
      tags.push({
        className: "rejected",
        icon: "clear",
        text: t("Rejected"),
      });
    return <ListItemTags tags={tags} />;
  }
}

export default compose(PaymentListItemTags)(withTranslation());
