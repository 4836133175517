import ObjectUtils from '../../../util/objectutils';
import {
  countryFilter,
  countryFilterOptions,
} from '../../common/listfilters/country';

const UserListAdapter = {
  tools: {},
  filters: {
    loanReleases: {
      text: 'Loan releases',
      type: 'filter',
      options: {
        onePlus: { text: '1+', icon: 'looks_one' },
        twoPlus: { text: '2+', icon: 'looks_two' },
      },
      onFilter: (items, selected) =>
        items.filter(({ state }) => {
          const loanReleases = state ? state.loan_releases : 0;
          let max = 0;
          if (selected.includes('twoPlus')) max = 2;
          if (selected.includes('onePlus')) max = 1;
          return loanReleases >= max;
        }),
    },
    country: {
      text: 'Country',
      type: 'filter',
      options: countryFilterOptions,
      onFilter: countryFilter,
    },
    sort: {
      text: 'Sort by',
      type: 'choice',
      options: {
        firstName: { text: 'First name', icon: 'arrow_downward' },
        lastName: { text: 'Last name', icon: 'arrow_downward' },
      },
      onFilter: (items, selected) => {
        switch (selected[0]) {
          case 'firstName':
            return [...items].sort((a, b) => {
              const path = 'personal.first_name';
              const firstNameA = ObjectUtils.navigate(a, path);
              const firstNameB = ObjectUtils.navigate(b, path);
              const lowerNameA = firstNameA ? firstNameA.toLowerCase() : '';
              const lowerNameB = firstNameB ? firstNameB.toLowerCase() : '';
              if (lowerNameA > lowerNameB) return 1;
              if (lowerNameA < lowerNameB) return -1;
              return 0;
            });
          case 'lastName':
            return [...items].sort((a, b) => {
              const path = 'user.personal.last_na,e';
              const lastNameA = ObjectUtils.navigate(a, path);
              const lastNameB = ObjectUtils.navigate(b, path);
              const lowerNameA = lastNameA ? lastNameA.toLowerCase() : '';
              const lowerNameB = lastNameB ? lastNameB.toLowerCase() : '';
              if (lowerNameA > lowerNameB) return 1;
              if (lowerNameA < lowerNameB) return -1;
              return 0;
            });
          default:
            return null;
        }
      },
    },
  },
  groups: {
    firstName: {
      icon: 'sort_by_alpha',
      filters: {
        status: '*',
        sort: ['firstName'],
      },
      onText: (a) => {
        const path = 'personal.first_name';
        const firstNameA = ObjectUtils.navigate(a, path);
        return firstNameA ? firstNameA.substring(0, 1).toUpperCase() : '';
      },
      onGroup: (a, b) => {
        const path = 'personal.first_name';
        const firstNameA = ObjectUtils.navigate(a, path);
        const firstNameB = ObjectUtils.navigate(b, path);
        const lowerNameA = firstNameA ? firstNameA.toLowerCase() : '';
        const lowerNameB = firstNameB ? firstNameB.toLowerCase() : '';
        return lowerNameA.substring(0, 1) !== lowerNameB.substring(0, 1);
      },
    },
    lastName: {
      icon: 'sort_by_alpha',
      filters: {
        status: '*',
        sort: ['lastName'],
      },
      onText: (a) => {
        const path = 'personal.lastName';
        const lastNameA = ObjectUtils.navigate(a, path);
        return lastNameA ? lastNameA.substring(0, 1).toUpperCase() : '';
      },
      onGroup: (a, b) => {
        const path = 'personal.last_name';
        const lastNameA = ObjectUtils.navigate(a, path);
        const lastNameB = ObjectUtils.navigate(b, path);
        const lowerNameA = lastNameA ? lastNameA.toLowerCase() : '';
        const lowerNameB = lastNameB ? lastNameB.toLowerCase() : '';
        return lowerNameA.substring(0, 1) !== lowerNameB.substring(0, 1);
      },
    },
  },
};

export default UserListAdapter;
