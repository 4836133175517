import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./circle.css";

class Circle extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    light: PropTypes.bool,
    dark: PropTypes.bool,
    small: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    light: false,
    dark: false,
    small: false,
    style: {},
  };

  render() {
    const { className, children, light, dark, small, style } = this.props;
    return (
      <div
        style={style}
        className={classNames("circle", className, { light, dark, small })}
      >
        {children}
      </div>
    );
  }
}

export default Circle;
