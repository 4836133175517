import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './drawer.css';

class Drawer extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    orientation: PropTypes.string,
    toggle: PropTypes.bool,
  };
  static defaultProps = {
    children: null,
    className: '',
    orientation: 'left',
    toggle: true,
  };
  render() {
    return (
      <div
        className={classNames(
          this.props.className,
          'drawer',
          `${this.props.orientation}`,
          `toggle-${this.props.toggle ? 'on' : 'off'}`
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Drawer;
