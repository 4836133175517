import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Drawer from "../../../common/drawer/drawer";
import DrawerHeader from "../../../common/drawer/drawerheader";
import DrawerContent from "../../../common/drawer/drawercontent";
import DrawerActions from "../../../common/drawer/draweractions";
import MenuItems from "./items";
import AppBarMenu from "../../appbar/appbarmenu";
import { compose } from "../../../../util/compose";
import { withAuth } from "../../../auth/auth";
import { ReactComponent as LogoBlue } from "../../../../assets/vector/logo-blue.svg";
import { ReactComponent as LogoBlueSmall } from "../../../../assets/vector/logo-sm-blue.svg";
import MaterialIcon from "@material/react-material-icon";
import "@material/react-list/dist/list.css";
import "@material/react-material-icon/dist/material-icon.css";
import "./menu.css";
import { selectSidebarMenu } from "../../../../redux/sidebar/selectors";
import { actions } from "../../../../redux/sidebar/slice";
import { MODULE_DISBURSAL_ACTIVE } from "../../../globals/variables/variables";
import List, {
  ListItem,
  ListItemGraphic,
  ListItemText,
} from "@material/react-list";
import "@material/react-list/dist/list.css";
import "@material/react-material-icon/dist/material-icon.css";
import "./menu.css";

function Menu(props) {
  const menu = useSelector(selectSidebarMenu());
  const dispatch = useDispatch();
  const name = props.auth.name;

  const removeDisbursalsIfConditionMet = (items, condition) => {
    if (!MODULE_DISBURSAL_ACTIVE) {
      const { disbursals, ...remainingItems } = items;
      return remainingItems;
    }
    return items;
  };

  const updatedMenuItems = removeDisbursalsIfConditionMet(
    MenuItems,
    MODULE_DISBURSAL_ACTIVE
  );

  const onMenuItem = (menuItem) => {
    const item = Object.values(MenuItems)[menuItem];
    if (!item.active && item.redirectURL !== undefined) {
      window.location.href = item.redirectURL;
    } else {
      props.history.push(item.path);
    }
  };

  const onAction = (action) => {
    switch (action) {
      case 0:
        dispatch(actions.toggleMenu());
        break;
      default:
        throw new Error(`Illegal toggle index '${action}'`);
    }
  };

  return (
    <Drawer className="drawer-menu" toggle={menu}>
      <DrawerHeader>
        <div className="logo">{menu ? <LogoBlue /> : <LogoBlueSmall />}</div>
        <h2 className="title">{props.t("{{username}}", { username: name })}</h2>
        <div className="user-menu">
          <span className="email">{props.auth.email}</span>
          <AppBarMenu />
        </div>
      </DrawerHeader>
      <DrawerContent>
        <List handleSelect={onMenuItem}>
          {Object.keys(updatedMenuItems).map((menuItem) => (
            <ListItem key={updatedMenuItems[menuItem].text}>
              <ListItemGraphic
                className="graphic"
                graphic={
                  <MaterialIcon icon={updatedMenuItems[menuItem].icon} />
                }
              />
              {menu && (
                <ListItemText
                  className="text"
                  primaryText={props.t(updatedMenuItems[menuItem].text)}
                />
              )}
            </ListItem>
          ))}
        </List>
      </DrawerContent>
      <DrawerActions>
        <List handleSelect={onAction}>
          <ListItem>
            <ListItemGraphic
              className="graphic"
              graphic={
                <MaterialIcon icon={menu ? "chevron_left" : "chevron_right"} />
              }
            />
            {menu && (
              <ListItemText className="text" primaryText={props.t("Close")} />
            )}
          </ListItem>
        </List>
      </DrawerActions>
    </Drawer>
  );
}

Menu.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};
Menu.defaultProps = {
  onToggle: () => {},
  toggle: {},
};

export default compose(Menu)(withAuth, withRouter, withTranslation());
