import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkTypes } from "../thunktypes";
import { actions } from "../slice";

export const getBankStatement = createAsyncThunk(
  thunkTypes.GET_BANK_STATEMENT,
  async (params, { dispatch, extra }) => {
    if (!params.bankId) {
      throw new Error("invalid bank id");
    }
    
    const statement = await extra.bankRepo.getBankStatement(
      params.bankId,
      params.date,
      params.days,
    );
    if (!statement) {
      throw new Error("error loading bank statement");
    }
    /* merge into store */
    await dispatch(actions.mergeBankStatement(statement));
    /* prob not needed, but still... */
    return statement;
  }
);
