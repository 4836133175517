import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllBanks } from "../../redux/banks/selectors";
import { listBanks } from "../../redux/banks/thunks/listbanks";
import "./list.css";
import { ListItem } from "./listitem";

export const List = ({ bankId, allowStatementUpdate }) => {
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const banks = useSelector(selectAllBanks());
  const [hasToGetBanks, setHasToGetBanks] = useState(true);
  const totalItems =
    (banks || []).length === 1 ? "1 item" : `${banks.length} items`;
  useEffect(() => {
    if (hasToGetBanks && !store.banks.isPending && !store.banks.isRejected) {
      setHasToGetBanks(false);
      dispatch(listBanks());
    }
  }, [
    dispatch,
    hasToGetBanks,
    setHasToGetBanks,
    store.banks.isPending,
    store.banks.isRejected,
  ]);
  return (
    <div className="list">
      <div className="header">
        <h2>Bancos</h2>
        <p>{totalItems}</p>
      </div>
      <ul>
        {banks.map((bank, key) => (
          <ListItem
            allowStatementUpdate={allowStatementUpdate}
            bank={bank}
            isActive={bankId === bank.bankId}
            key={key}
          />
        ))}
      </ul>
    </div>
  );
};
