import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialstate";
import { extraReducers } from "./extrareducers";
import * as reducers from "./reducers";

export const name = "globals";
export const slice = createSlice({
  initialState,
  extraReducers,
  name,
  reducers,
});
export const actions = slice.actions;
export const reducer = slice.reducer;
