import { mergeItemByIndex, mergeItemsByIndex } from "../../util/stateutils";

export const mergeBankAccount = (state, action) => {
  const next = action.payload && action.payload.userId ? action.payload : "";
  if (next) {
    if (!state.bankAccounts) {
      state.bankAccounts = {};
    }
    const prev = state.bankAccounts[next.userId]
      ? state.bankAccounts[next.userId]
      : {};
    state.bankAccounts[next.userId] = { ...prev, ...next };
  }
};

export const mergeUser = (state, action) => {
  state.users = mergeItemByIndex(
    (user) => user.userId,
    action.payload,
    state.users
  );
};

export const mergeUsers = (state, action) => {
  state.users = mergeItemsByIndex(
    (user) => user.userId,
    action.payload,
    state.users
  );
};

export const mergePendingUser = (state, action) => {
  state.users = mergeItemByIndex(
    (user) => user.userId,
    {
      userId: action.payload,
      $isPending: true,
      $isFulfilled: false,
      $isRejected: false,
    },
    state.users
  );
};

export const mergeFulfilledUser = (state, action) => {
  state.users = mergeItemByIndex(
    (user) => user.userId,
    {
      ...action.payload,
      $isPending: false,
      $isFulfilled: true,
      $isRejected: false,
    },
    state.users
  );
};

export const mergeFulfilledUsers = (state, action) => {
  state.users = mergeItemsByIndex(
    (user) => user.userId,
    action.payload.map((user) => ({
      ...user,
      $isPending: false,
      $isFulfilled: true,
      $isRejected: false,
    })),
    state.users
  );
};

export const mergeRejectedUser = (state, action) => {
  state.users = mergeItemByIndex(
    (user) => user.userId,
    {
      userId: action.payload,
      $isPending: false,
      $isFulfilled: false,
      $isRejected: true,
    },
    state.users
  );
};
