export const mergeFragment = (state, { payload }) => {
  /* fragments are little pieces of data stored globally. miscellany. */
  const next = payload && payload.id ? payload : "";
  if (!next) {
    return;
  }
  if (!state.fragments) {
    state.fragments = {};
  }
  const prev = state.fragments[next.id] ? state.fragments[next.id] : {};
  state.fragments = {
    ...state.fragments,
    [next.id]: { ...prev, ...next },
  };
};

export const mergeModalView = (state, { payload }) => {
  const next = payload && payload.id ? payload : "";
  if (!next) {
    return;
  }
  if (!state.modalViews) {
    state.modalViews = {};
  }
  const prev = state.modalViews[next.id] ? state.modalViews[next.id] : {};
  state.modalViews = {
    ...state.modalViews,
    [next.id]: { ...prev, ...next },
  };
};

export const mergeViewPort = (state, { payload }) => {
  if (!state.viewPort) {
    state.viewPort = { width: 0, height: 0 };
  }
  state.viewPort = {
    ...state.viewPort,
    ...payload,
  };
};

export const disposeModalView = (state, { payload }) => {
  if (payload && state.modalViews && state.modalViews[payload]) {
    delete state.modalViews[payload];
  }
};
