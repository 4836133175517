import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFragment } from "../redux/globals/selectors";
import { updateFragment } from "../redux/globals/thunks/updatefragment";

export const useFragment = (fragmentId, initValue) => {
  const dispatch = useDispatch();
  const d1 = initValue ? initValue : {};
  const f1 = useSelector(selectFragment(fragmentId));
  const f2 = f1 ? f1 : "";
  const fragment = f2 ? { ...d1, ...f2 } : d1;
  const update = useCallback(
    async (fragmentValue) => {
      const fragment = fragmentValue ? fragmentValue : {};
      await dispatch(
        updateFragment({
          ...fragment,
          id: fragmentId,
        })
      );
    },
    [dispatch, fragmentId]
  );
  return [fragment, update];
};
