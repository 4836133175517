import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import AnnotationListItemPromise from "./itempromise";
import AnnotationListItemEvent from "./itemevent";
import AnnotationListItemReview from "./itemreview";
import AnnotationType from "../../../../domain/annotation/type";
import CircleIcon from "../../../common/circle/circleicon";
import ListItem from "../../../common/list/item/item";
import ListItemGraphic from "../../../common/list/item/itemgraphic";
import ListItemContent from "../../../common/list/item/itemcontent";
import Time from "../../../common/time/time";
import { compose } from "../../../../util/compose";
import "./item.css";

class AnnotationListItem extends React.Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    t: PropTypes.func.isRequired,
  };
  static defaultProps = {
    selected: false,
    onSelect: (annotation) => {},
  };
  onSelect() {
    this.props.onSelect(this.props.annotation);
  }
  render() {
    const { data, type, created_at, created_by } = this.props.annotation;
    const createdBy = created_by || this.props.t("Unknown user");
    const icons = {
      review: "flag",
      promise: "event",
      comment: "comment",
      event: "info",
    };
    return (
      <ListItem
        className={`annotation type-${type}`}
        item={this.props.annotation}
      >
        <ListItemGraphic>
          <CircleIcon icon={icons[type] ? icons[type] : "note"} />
        </ListItemGraphic>
        <ListItemContent>
          <h4>{this.props.t("{{user}} wrote", { user: createdBy })}:</h4>
          <div className="text-area">{data.text}</div>
          <Time time={created_at || ""} format="MMMM DD, YYYY H:mm[hrs]" />
        </ListItemContent>
        {type !== AnnotationType.Promise ? null : (
          <AnnotationListItemPromise annotation={this.props.annotation} />
        )}
        {type !== AnnotationType.Event ? null : (
          <AnnotationListItemEvent annotation={this.props.annotation} />
        )}
        {type !== AnnotationType.Review ? null : (
          <AnnotationListItemReview annotation={this.props.annotation} />
        )}
      </ListItem>
    );
  }
}

export default compose(AnnotationListItem)(withTranslation());
