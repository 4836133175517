import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Card from "../../common/card/card";
import CardContent from "../../common/card/cardcontent";
import CircleIcon from "../../common/circle/circleicon";
import Heading from "../../common/heading";
import Table from "./table/table";
import { withDataLayer } from "../../datalayer/datalayer";
import { compose } from "../../../util/compose";
import { withApp } from "../../app/app";
import Dialog from '../../common/dialog/dialog';

import "./disbursal.css";

class Summary extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    disbursalDetails: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSettle: false,
      isFallBack: false,
    };
  }

  fallbackLoad(data) {
    const { disbursalDetails } = this.props;
    this.props.dataLayer.disbursals
      .fallbackLoad(disbursalDetails.service, disbursalDetails.handler, disbursalDetails.type)
      .then((services) => {
        this.setState({
          services: services.data.data.items,
          isFallBack: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        this.props.app.onError({
          text: this.props.t("Error generating process."),
          data: error.details,
        });
      });
  }
  onFallbackDismiss() {
    this.setState({
      isFallBack: false,
    })
  }

  onViewAll() {
    const { disbursalDetails, history } = this.props;
    history.push(
      `/disbursals/${disbursalDetails.service}/${disbursalDetails.handler}/${disbursalDetails.type}`
    );
  }

  onSettleClose() {
    this.setState({
      isSettle: false,
    })
  }
  onSettleConfirm(data) {
    const { disbursalDetails } = this.props;
    this.props.dataLayer.disbursals
      .settleFallback(data.data.inputValue, disbursalDetails.service, disbursalDetails.type)
      .then(() => {
        this.setState({
          isSettle: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        this.props.app.onError({
          text: this.props.t("Error settling fallback."),
          data: error.details,
        });
      });
  }

  render() {
    const { disbursalDetails } = this.props;
    const { isSettle, isFallBack } = this.state;

    return (
      <Card>
        <Heading
          title={disbursalDetails.description}
          subtitle={disbursalDetails.type}
          graphic={<CircleIcon light={true} icon="account_balance" />}
        />
        <CardContent>
          <div className="details-container">
            <Table
              handler={disbursalDetails.handler}
              service={disbursalDetails.service}
              failures={disbursalDetails.failures}
              signed_loans={disbursalDetails.signed_loans}
              transferring_loans={disbursalDetails.transferring_loans}
              total={disbursalDetails.total}
            />
            <div className="buttons-container">
              <button onClick={() => this.setState({
                isFallBack: true,
              })} className="button">
                Generar proceso
              </button>
              <button className="button" onClick={() => this.setState({
                isSettle: true,
              })}>Cerrar proceso</button>
              <button onClick={this.onViewAll.bind(this)} className="button">
                Ver
              </button>
            </div>
          </div>
          <Dialog
            open={isSettle}
            onDismiss={this.onSettleClose.bind(this)}
            onConfirm={this.onSettleConfirm.bind(this)}
            dialogType="input-confirm"
            inputText="Execution id"
            title="Enter a execution id"
          />
          <Dialog
            open={isFallBack}
            onDismiss={this.onFallbackDismiss.bind(this)}
            onConfirm={this.fallbackLoad.bind(this)}
          />
        </CardContent>
      </Card>
    );
  }
}
export default compose(Summary)(
  withDataLayer,
  withApp,
  withRouter,
  withTranslation()
);
