import React from "react";
import PropTypes from "prop-types";
import Tab from "@material/react-tab";
import TabBar from "@material/react-tab-bar";
import { withTranslation } from "react-i18next";
import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-indicator/dist/tab-indicator.css";

import Drawer from "../../common/drawer/drawer";
import DrawerContent from "../../common/drawer/drawercontent";

import AnnotationList from "../../annotation/list/list";
import AnnotationListAdapter from "../../annotation/list/listadapter";
import LoanList from "../../loan/list/list";
import LoanListAdapter from "../../loan/list/listadapter";
import PaymentList from "../../payment/list/list";
import PaymentListAdapter from "../../payment/list/listadapter";
import { compose } from "../../../util/compose";

class SideBarTabs extends React.Component {
  static propTypes = {
    annotations: PropTypes.array,
    loadingAnnotations: PropTypes.bool,
    loadingLoans: PropTypes.bool,
    loadingPayments: PropTypes.bool,
    loan: PropTypes.object,
    loans: PropTypes.array,
    payment: PropTypes.object,
    payments: PropTypes.array,
    t: PropTypes.func.isRequired,
    onAnnotations: PropTypes.func.isRequired,
    onAnnotationPost: PropTypes.func.isRequired,
    onLoans: PropTypes.func.isRequired,
    onPayments: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      comment: "",
    };
  }
  onTab(tab) {
    this.setState({ tab });
  }
  onChangeComment(comment) {
    this.setState({ comment });
  }
  render() {
    const { user, loan, loans } = this.props;

    const tabs = [];
    tabs.push({
      handler: "Annotations",
      child: (
        <AnnotationList
          adapter={AnnotationListAdapter}
          userId={user.user_id}
          loans={loans}
          loan={loan ? loan.loan_id : null}
          loanRequestId={loan ? loan.loan_request_id : null}
          annotations={loans ? this.props.annotations : null}
          loading={this.props.loadingAnnotations}
          posting={this.props.postingAnnotations}
          onPost={this.props.onAnnotationPost}
          onReload={this.props.onAnnotations}
          comment={this.state.comment}
          onChangeComment={this.onChangeComment.bind(this)}
        />
      ),
    });
    tabs.push({
      handler: "Loans",
      child: (
        <LoanList
          adapter={LoanListAdapter}
          loans={this.props.loans}
          loading={this.props.loadingLoans}
          selected={this.props.loan && this.props.loan.loan_id}
          onReload={this.props.onLoans}
        />
      ),
    });
    this.props.loan &&
      tabs.push({
        handler: "Payments",
        child: (
          <PaymentList
            country={user.personal.country}
            adapter={PaymentListAdapter}
            payments={this.props.payments}
            loading={this.props.loadingPayments}
            selected={this.props.payment && this.props.payment.payment_id}
            onReload={this.props.onPayments}
          />
        ),
      });
    return (
      <Drawer className="sidebar" orientation="right" toggable={false}>
        <DrawerContent className="sidebar-tabs">
          <div className="tab-handlers">
            <TabBar
              activeIndex={this.state.tab}
              handleActiveIndexUpdate={this.onTab.bind(this)}
            >
              {tabs.map((tab, key) => (
                <Tab key={key}>{this.props.t(tab.handler)}</Tab>
              ))}
            </TabBar>
          </div>
          <div className="tab-contents">{tabs[this.state.tab].child}</div>
        </DrawerContent>
      </Drawer>
    );
  }
}

export default compose(SideBarTabs)(withTranslation());
