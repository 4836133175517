const es_ES = {
  translation: {
    /* Dashboard */
    "Hello, {{username}}!": "¡Hola, {{username}}!",
    "Redirecting to login page": "Redirigiendo a la página de autenticación",
    Logout: "Cerrar sesión",
    Search: "Buscar",
    "User profile": "Perfil de usuario",
    "User personal": "Personal",
    "User document": "Documentos",
    "Personal address": "Dirección de residencia",
    Documents: "Documentos",
    "User work": "Perfil de empleo",
    Employer: "Empresa",
    "Employment status": "Situación laboral",
    "Monthly income amount": "Ingreso mensual",
    "Payment frequency": "Frecuencia de pago",
    "Primary income source": "Fuente primaria de ingresos",
    Profession: "Profesión",
    "Income periodicity": "Periodicidad de ingreso",
    "By ID, DPI, email or phone number": "Por ID, DPI, Email o teléfono",
    "User Id, DPI, email or phone number":
      "ID, DPI, Email o teléfono del usuario",
    "Searching users": "Buscando usuarios",
    "No users found": "No se encontraron usuarios",
    /* Users */
    Users: "Usuarios",
    "User ID": "ID de usuario",
    Personal: "Personal",
    Name: "Nombre",
    "Last name": "Apellido",
    Email: "Email",
    DPI: "DPI",
    "Phone number": "Número telefónico",
    Birthdate: "Fecha de nacimiento",
    State: "Estado",
    Gender: "Género",
    Country: "Pais",
    City: "Ciudad",
    Street: "Calle o avenida",
    Number: "Número",
    "Civil status": "Estado civil",
    "Spiritual guide": "Guía espiritual",
    "Scope version": "Versión de producto",
    "Active device ID": "ID de dispositivo activo",
    "Active loan ID": "ID de crédito activo",
    "Fetching user data": "Descargando perfil de usuario",
    Male: "Hombre",
    Female: "Mujer",
    Married: "Casado",
    Single: "Soltero",
    Divorced: "Divorciado",
    United: "Unido",
    "Work address": "Dirección de trabajo",
    "User state": "Estado de usuario",
    /* Loans */
    "Loan details": "Detalles de crédito",
    "Loan requested amount and other data":
      "Cantidad que se prestó y otros datos",
    "Stores the current state of this loan": "Estado actual del crédito",
    Loans: "Créditos",
    "Fetching loan details": "Obteniendo los detalles del crédito",
    Settled: "Pagado",
    Status: "Estado",
    "User snapshot ID": "ID de histórico del usuario",
    "Active due date": "Fecha de pago activa",
    "Due date": "Fecha de vencimiento",
    "Created at": "Fecha de creación en base de datos",
    "Rejected until": "Denegado hasta",
    "Created date time": "Fecha de creación por usuario",
    "Estimated release": "Fecha estimada de desenbolso",
    "Release datetime": "Fecha de desenbolso",
    "Released date time": "Fecha de desenbolso",
    "Updated at": "Última actualización",
    "Loan ID": "ID del crédito",
    "Loan request ID": "ID de solicitud",
    "Interest amount": "Cantidad de interés",
    "Interest rate": "Porcentaje de interés",
    "Gastos administrativos": "Administrative expenses",
    Term: "Duración",
    Subcategory: "Subcategoría",
    "Promissory note": "Pagaré",
    Signature: "Firma",
    "Loan request": "Solicitud de crédito",
    "Loan state": "Estado de crédito",
    Amount: "Cantidad",
    Released: "Depositado",
    Fulfilled: "Pagado",
    /* Details */
    Devices: "Dispositivos",
    /* General */
    Edit: "Editar",
    Save: "Guardar cambios",
    Cancel: "Cancelar",
    "Are you sure you want to continue?": "¿Estás seguro de continuar?",
    Continue: "Continuar",
    Requests: "Solicitudes",
    Payments: "Pagos",
    Releases: "Transferencias",
    Reports: "Reportes",
    Selfie: "Selfie",
    Front: "Frente",
    Back: "Reverso",
    /* Releases */
    "Interbank releases": "Tranferencias interbancarias",
    "Create a release for non-BAM accounts":
      "Realizar transferencias a cuentas de terceros",
    Signed: "Firmado",
    "No items found": "No se hallaron ítems",
    Transferring: "Transfiriendo",
    "Create batch": "Crear",
    "Update batch": "Finalizar",
    "Intrabank releases": "Transferencias intrabancarias",
    "Create a release for BAM accounts":
      "Realizar transferencias a cuentas del BAM",
    "Failed releases": "Transferencias fallidas",
    "Mark a release as failed": "Marcar una transferencia como fallida",
    /* Payments */
    "Processing payments": "Pagos en proceso",
    "Waiting to be automatically approved":
      "Pendientes de aprobación automática",
    "Fetching payments": "Cargando pagos",
    "Fetching loans": "Cargando créditos",
    "Signed loans": "Créditos firmados",
    "Transferring loans": "Créditos en transferencia",
    "Loans waiting to be transferred": "Créditos esperando a ser transferidos",
    "Loans currently being transferred":
      "Créditos con transferencia en proceso",
    Approved: "Aprobado",
    Rejected: "Rechazado",
    "Hard Rejected": "Rechazo Permanente",
    "Soft Rejected": "Rechazo Temporal",
    Archived: "Archivado",
    "Failed transfer": "Error transferencia",
    /* Errors */
    "Oops! Something went wrong": "Oops! Ha ocurrido un error",
    "Error message": "Mensaje de error",
    "View details": "Ver detalles",
    "Error fetching user data.":
      "Ha ocurrido un error descargando los datos del usuario.",
    "Error fetching request data":
      "Ha ocurrido un error descargando los datos de la solicitud.",
    Dismiss: "Cerrar",
    "Error adding failed transfer.":
      "Error al marcar la transferencia como fallida.",
    Username: "Nombre",
    "Most recent": "Más recientes",
    "User name": "Nombre",
    Recent: "Recientes",
    Oldest: "Antiguos",
    /* Balance */
    Installments: "Pagos",
    "Details about loan installments.": "Detalles de los pagos del crédito.",
    "Fetching loan balance": "Descargando el detalle del crédito",
    "Installment amount": "Total del pago",
    "Late fee": "Mora",
    "Daily Late fee": "Mora Diaria",
    "Late fee accumulated": "Mora Acumulada",
    "Late fee first day": "Mora Primer día",
    "[Late since] MMMM DD": "[Atrasado desde el] DD [de] MMMM",
    "{{days}} late": "{{days}} días de atraso",
    "MMMM DD": "DD [de] MMMM",
    "Installment of Q{{amount}}": "Pago de Q{{amount}}",
    "{{installments}} installments(s)": "{{installments}} pago(s)",
    "Late installments(s)": "Pago(s) atrasados",
    /* Annotations */
    Annotations: "Anotaciones",
    Comment: "Comentario",
    Comments: "Comentarios",
    Promise: "Convenio de pago",
    Review: "Revisión",
    Refresh: "Actualizar",
    Filter: "Filtrar",
    "Early collection": "Pre Mora",
    "{{user}} wrote": "{{user}} escribió",
    Type: "Tipo",
    Category: "Categoría",
    Post: "Enviar",
    Date: "Fecha",
    "Fetching annotations": "Descargando anotaciones",
    "Sort by": "Ordenar por",
    "Total payed": "Total pagado",
    "Active loan": "Crédito activo",
    "User's active loan summary.": "Detalles del crédito actual del usuario.",
    "Select a date": "Selecciona una fecha",
    Select: "Seleccionar",
    Previous: "Anterior",
    Next: "Siguiente",
    /* Collections */
    Yes: "Si",
    No: "No",
    "First name": "Nombre",
    "Loading items": "Descargando items",
    Late: "Tarde",
    "On time": "Al día",
    Close: "Cerrar",
    Reload: "Cargar",
    Collections: "Cobranza",
    "{{installments}} installment(s)": "{{installments}} pago(s)",
    "Release date": "Fecha que se le depositó",
    "Cash pickup": "Efectivo",
    "Bank transfer": "Transferencia",
    "Disbursal method": "Método de desembolso",
    "Loan amount level": "Nivel de créditos",
    "Interest rate level": "Nivel de intereses",
    Disbursals: "Transferencias",

    "First Income Date Main Source": "Fecha que recibió el primer pago",
    "Latest Income Date Main Source": "Fecha que recibió el último pago",
    "Main Income Source Description": "Descripción de fuente de ingresos",
    "Main Income Main Source": "Cuanto recibe al mes de esta fuente de ingreso",

    /* BankAccount*/
    "User bank account": "Cuenta bancaria del usuario",
    Bank: "Banco",
    "Account number": "Número de cuenta",
    "Account type": "Tipo de cuenta",

    /*Requests*/
    "Request details": "Detalles de solicitud",
  },
};

export default es_ES;
