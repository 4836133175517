import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import CircleIcon from "../../../common/circle/circleicon";
import ListItem from "../../../common/list/item/item";
import ListItemGraphic from "../../../common/list/item/itemgraphic";
import ListItemContent from "../../../common/list/item/itemcontent";
import ListItemMeta from "../../../common/list/item/meta/meta";
import LoanListItemTags from "./itemtags";
import { Currency } from "../../../globals/currency/currency";
import Time from "../../../common/time/time";
import Uuid from "../../../common/uuid/uuid";
import UserName from "../../../user/name/name";
import { compose } from "../../../../util/compose";
import { dateFormats } from "../../../../util/dateutils";
import "./item.css";

class LoanListItem extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    loan: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
  };
  static defaultProps = {
    selected: false,
    onSelect: (loan) => {},
  };
  onLoan() {
    const loan = this.props.loan;
    if (loan.user)
      this.props.history.push(`/loans/details/${loan.loan_request_id}`);
  }
  render() {
    const { loan, selected } = this.props;
    return (
      <ListItem
        className="loan"
        item={loan}
        selected={selected}
        onSelect={this.onLoan.bind(this)}
        linkRef={"/loans/details/" + loan.loan_request_id}
      >
        <ListItemGraphic>
          <CircleIcon icon="credit_card" />
        </ListItemGraphic>
        <ListItemContent>
          <h4>
            <UserName user={loan.user} />
          </h4>
          <Uuid text="ID:" uuid={loan.loan_request_id} />
          <Time
            time={loan.state.updated_at}
            format={dateFormats.MMMM_D_YYYY_H_mm}
          />
        </ListItemContent>
        <ListItemMeta>
          <h4>
            <Currency
              amount={loan.amount}
              country={loan.user?.personal?.country}
            />
          </h4>
          <h5>{`${loan.installments} installments`}</h5>
        </ListItemMeta>
        <LoanListItemTags loan={loan} />
      </ListItem>
    );
  }
}

export default compose(LoanListItem)(withRouter, withTranslation());
