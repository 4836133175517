import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "../slice";
import { thunkTypes } from "../thunktypes";

const DISPOSE_TIME = 400;

export const hideModalView = createAsyncThunk(
  thunkTypes.HIDE_MODAL_VIEW,
  async (params, { dispatch }) => {
    const modalView = { id: params.id, isVisible: false };
    await dispatch(actions.mergeModalView(modalView));
    setTimeout(() => {
      /* remove from state after delay */
      dispatch(actions.disposeModalView(modalView.id));
    }, DISPOSE_TIME);
    return modalView.id;
  }
);
