export const fields = {
  loan_request_id: {
    key: "loan_request_id",
    label: "Loan request ID",
    columns: 12,
  },
  user_id: {
    key: "user_id",
    label: "User ID",
    columns: 12,
    type: "anchor",
    url: (value) => `/users/details/${value}`,
  },
};
