import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPaymentById } from "../../../redux/payments/selectors";
import { getPaymentById } from "../../../redux/payments/thunks/getpaymentbyid";
import { selectUserById } from "../../../redux/users/selectors";
import { getUserById } from "../../../redux/users/thunks/getuserbyid";
import { Conditional } from "../../components";
import { Balance } from "../../loan/balance/balance";
import { Details } from "./details";
import "./payment.css";
import { Statement } from "./statement";
import { MODULE_PAYMENT_ACTIVE } from "../../globals/variables/variables";

export const Payment = ({ paymentId }) => {
  const dispatch = useDispatch();
  const payment = useSelector(selectPaymentById(paymentId));
  const user = useSelector(selectUserById(payment.userId));
  useEffect(() => {
    if (
      paymentId &&
      !payment.$isPending &&
      !payment.$isRejected &&
      !payment.$isFulfilled
    ) {
      /* fetch payment if not in store and not fetching */
      dispatch(getPaymentById({ paymentId }));
    }
  }, [
    dispatch,
    paymentId,
    payment.$isPending,
    payment.$isRejected,
    payment.$isFulfilled,
  ]);
  useEffect(() => {
    if (
      payment.userId &&
      !user.$isPending &&
      !user.$isRejected &&
      !user.$isFulfilled
    ) {
      /* fetch user if not in store and not fetching */
      dispatch(getUserById({ userId: payment.userId }));
    }
  }, [
    dispatch,
    payment.userId,
    user.$isPending,
    user.$isRejected,
    user.$isFulfilled,
  ]);
  return (
    <div className="payment">
      <Conditional condition={paymentId}>
        <Details payment={payment} user={user} />
        {!MODULE_PAYMENT_ACTIVE && <Statement />}
        <Balance loanId={payment?.loanId} userId={payment?.userId} />
      </Conditional>
    </div>
  );
};
