import React from "react";
import Button from "@material/react-button";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import AnnotationType from "../../../domain/annotation/type";
import AnnotationListInputComment from "./listinputcomment";
import AnnotationListInputReview from "./listinputreview";
import AnnotationListInputPromise from "./listinputpromise";
import ListInput from "../../common/list/listinput";
import Text from "../../common/fields/text/text";
import { compose } from "../../../util/compose";
import { withApp } from "../../app/app";
import { withDataLayer } from "../../datalayer/datalayer";

import MaterialIcon from "@material/react-material-icon";
import "@material/react-text-field/dist/text-field.css";
import "@material/react-button/dist/button.css";

class AnnotationListInput extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    loanId: PropTypes.string,
    loanRequestId: PropTypes.string,
    posting: PropTypes.bool,
    t: PropTypes.func.isRequired,
    onPost: PropTypes.func,
    comment: PropTypes.string,
    onChangeComment: PropTypes.func,
  };
  static defaultProps = {
    loanId: "",
    loanRequestId: "",
    posting: false,
    type: AnnotationType.Comment,
    onPost: (annotation) => Promise.resolve(),
  };
  state = {
    text: this.props.comment ?? "",
    data: {},
  };
  onPost() {
    const { data, text } = this.state;
    if (AnnotationType.Review === this.props.type) {
      data.review = data.review === "yes" ? true : false;
    }
    const annotation = {
      loan_id: this.props.loanId,
      loan_request_id: this.props.loanRequestId,
      user_id: this.props.userId,
      type: this.props.type,
      data: Object.assign({}, data, { text: text || "" }),
    };
    if (!annotation.data.text.trim()) {
      console.log(this.props.t("Annotations must contain text"));
      return;
    }
    if (AnnotationType.Promise === this.props.type) {
      if (!annotation.data.date) {
        console.log(this.props.t("Promises must contain a date"));
        return;
      }
      if (!annotation.data.amount) {
        console.log(this.props.t("Promises must contain an amount"));
        return;
      }
    }
    return this.props
      .onPost(annotation)
      .then(() => this.setState({ text: "", data: {} }));
  }
  onText(text) {
    this.setState({ text });
    this.props.onChangeComment(text);
  }
  onData(data) {
    this.setState({ data });
  }
  render() {
    const { loanId, t, userId, type, posting } = this.props;
    const { data } = this.state;
    return (
      <ListInput posting={posting}>
        <Text
          className="text"
          label={t("Comment")}
          value={this.state.text}
          readonly={false}
          onChange={this.onText.bind(this)}
          textArea={true}
        />
        {(() => {
          switch (type) {
            case AnnotationType.Review:
              return (
                <AnnotationListInputReview
                  userId={userId}
                  data={data}
                  onData={this.onData.bind(this)}
                />
              );
            case AnnotationType.Promise:
              return (
                <AnnotationListInputPromise
                  userId={userId}
                  loanId={loanId}
                  data={data}
                  onData={this.onData.bind(this)}
                />
              );
            case AnnotationType.Comment:
              return (
                <AnnotationListInputComment
                  userId={userId}
                  loanId={loanId}
                  data={data}
                  onData={this.onData.bind(this)}
                />
              );
            default:
              return null;
          }
        })()}
        <div className="input-actions">
          <Button
            raised
            className="input-post"
            trailingIcon={<MaterialIcon icon="send" />}
            onClick={this.onPost.bind(this)}
          >
            {t("Post")}
          </Button>
        </div>
      </ListInput>
    );
  }
}

export default compose(AnnotationListInput)(
  withApp,
  withDataLayer,
  withTranslation()
);
