export const fields = {
  user_id: {
    key: "user_id",
    label: "User ID",
    columns: 12
  },
  active_loan_id: {
    key: "active_loan_id",
    label: "Active loan ID",
    columns: 12,
    type: "anchor",
    url: (value) => `/requests/details/${value}`
  },
  active_device_id: {
    key: "active_device_id",
    label: "Active device ID",
    columns: 12,
    type: "anchor",
    url: (value) => `/device/details/${value}`
  }
};
