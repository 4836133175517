import jwt from "jsonwebtoken";
import Cognito from "./cognito";

const PARAMS_ID_TOKEN = "id_token";
const PARAMS_ACCESS_TOKEN = "access_token";
const PARAMS_TOKEN_TYPE = "token_type";

const PARAMS_RESPONSE_TYPE = "response_type";
const PARAMS_CLIENT_ID = "client_id";
const PARAMS_REDIRECT_URI = "redirect_uri";
const PARAMS_STATE = "state";

const LOCAL_KEY_REDIRECT = "redirect";

export const createAuthClient = () => {
  const clearRedirect = async () => {
    localStorage.removeItem(LOCAL_KEY_REDIRECT);
  };
  const clearSession = async () => {
    localStorage.removeItem(Cognito.storageKey);
  };
  const getCurrentSession = async () => {
    const session = await getSessionFromLocalStorage();
    return {
      auth: jwt.decode(session.id),
      token: session,
    };
  };
  const getSessionFromUrl = async () => {
    const hash = window.location.hash
      ? window.location.hash.replace(new RegExp("^[#]+"), "")
      : "";
    const params = new URLSearchParams(hash);
    const session =
      params.has(PARAMS_ID_TOKEN) &&
      params.has(PARAMS_ACCESS_TOKEN) &&
      params.has(PARAMS_TOKEN_TYPE)
        ? {
            id: params.get(PARAMS_ID_TOKEN),
            access: params.get(PARAMS_ACCESS_TOKEN),
            bearer: params.get(PARAMS_TOKEN_TYPE),
          }
        : "";
    return session && !hasExpired(session) ? session : "";
  };
  const getSessionFromLocalStorage = async () => {
    let session = "";
    try {
      const storedSession = await retrieveSession();
      if (storedSession && !hasExpired(storedSession)) {
        session = storedSession;
      }
    } catch (error) {
      /* no authorization */
    }
    return session;
  };
  const handleAuth = async () => {
    const urlSession = await getSessionFromUrl();
    if (urlSession) {
      await storeSession(urlSession);
      const redirect = await retrieveRedirect();
      await clearRedirect();
      window.location.href = redirect;
      return {
        auth: jwt.decode(urlSession.id),
        token: urlSession,
      };
    }
    const storedSession = await getSessionFromLocalStorage();
    if (storedSession) {
      return {
        auth: jwt.decode(storedSession.id),
        token: storedSession,
      };
    }
    await clearSession();
    const redirect = window.location.href;
    await storeRedirect(redirect);
    await signIn();
  };
  const hasExpired = (session) => {
    const decoded = jwt.decode(session.id);
    const expire = (decoded.exp - 120) * 1000;
    const now = new Date().getTime();
    return now >= expire;
  };
  const retrieveRedirect = async () => {
    const redirect = localStorage.getItem(LOCAL_KEY_REDIRECT);
    return redirect ? redirect : "";
  };
  const retrieveSession = async () => {
    const storedToken = localStorage.getItem(Cognito.storageKey);
    return storedToken ? JSON.parse(storedToken) : "";
  };
  const storeRedirect = async (redirect) => {
    localStorage.setItem(LOCAL_KEY_REDIRECT, redirect);
  };
  const storeSession = async (session) => {
    localStorage.setItem(Cognito.storageKey, JSON.stringify(session));
  };
  const signIn = async () => {
    const path = window.location.pathname;
    const url = new URL(`${Cognito.protocol}//${Cognito.hostname}/`);
    url.pathname = Cognito.pathname;
    url.searchParams.append(PARAMS_RESPONSE_TYPE, "token");
    url.searchParams.append(PARAMS_CLIENT_ID, Cognito.client);
    url.searchParams.append(PARAMS_REDIRECT_URI, Cognito.redirect);
    url.searchParams.append(PARAMS_STATE, path);
    window.location.href = url.toString();
  };
  const signOut = async () => {
    await clearSession();
    await signIn();
  };
  return {
    getCurrentSession,
    handleAuth,
    signOut,
  };
};
