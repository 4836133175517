import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./phone.css";
class UserPhone extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    className: PropTypes.string,
  };
  static defaultProps = {
    user: {},
  };
  render() {
    const { className } = this.props;
    const user = this.props.user || {};
    const personal = user.personal || {};
    const phone = (personal.phone || "").replace("+502", "(+502) ").trim();
    return (
      <span className={classNames("user-phone", className)}>
        <span className="icon">phone</span>
        <span className="text">{phone ? `${phone}` : "Unknown user"}</span>
      </span>
    );
  }
}

export default UserPhone;
