import classNames from "classnames";
import { withRouter } from "react-router";
import { compose } from "../../util/compose";
import { FlagIcon } from "../controls/icon/icon";
import { Ripple } from "../controls/ripple/ripple";
import "./listitem.css";

const Item = ({ isActive, bank, history, allowStatementUpdate }) => {
  const onClick = () => {
    history.push(
      allowStatementUpdate
        ? `/banks/update/${bank.bankId}`
        : `/banks/${bank.bankId}`
    );
  };
  return (
    <Ripple
      className={classNames("list-item", { active: isActive })}
      onClick={onClick}
    >
      <div>
        <h4>
          <span>{bank.name}</span>
          <FlagIcon country={bank.country} />
        </h4>
        <dl>
          <div>
            <dt>Swift</dt>
            <dd>{bank.swift}</dd>
          </div>
        </dl>
      </div>
    </Ripple>
  );
};

export const ListItem = compose(Item)(withRouter);
