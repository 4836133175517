export const transactionStatus = {
  PAYMENT: "PAYMENT",
  UNLINKED: "UNLINKED",
};

export const transactionStatusLabels = {
  [transactionStatus.PAYMENT]: `Linked`,
  [transactionStatus.UNLINKED]: `Unlinked`,
};

export const transactionStatusOptions = Object.values(transactionStatus).map(
  (value) => ({
    value,
    label: transactionStatusLabels[value],
  })
);
