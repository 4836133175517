import React from 'react';
import { Subtitle2 } from '@material/react-typography';
import '@material/react-typography/dist/typography.css';
import PropTypes from 'prop-types';

function Heading(props) {
  return (
    <div className="heading">
      <div className="heading-content">
        <div>{props.graphic}</div>
        <div className="title">
          {props.title}{' '}
          {props.titleNote ? (
            <span className="title-note">{props.titleNote}</span>
          ) : (
            ''
          )}
          <Subtitle2 className="handler" style={{ display: 'none' }}>
            {props.handler}
          </Subtitle2>
          <Subtitle2 className="subtitle" style={{ display: 'none' }}>
            {props.subtitle}
          </Subtitle2>
        </div>
      </div>
    </div>
  );
}

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  titleNote: PropTypes.string,
  handler: PropTypes.string,
  subtitle: PropTypes.string,
  graphic: PropTypes.node,
};

export default Heading;
