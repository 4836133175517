import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Cell, Grid, Row } from "@material/react-layout-grid";

import "@material/react-layout-grid/dist/layout-grid.css";

class Reports extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const { t } = this.props;
    return (
      <Grid>
        <Row>
          <Cell columns={12}>{t("Reports")}</Cell>
        </Row>
      </Grid>
    );
  }
}

export default withTranslation()(Reports);
