import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./meta.css";

class ItemMeta extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
  };
  render() {
    return (
      <div className={classNames("item-meta", this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

export default ItemMeta;
