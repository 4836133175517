import React from "react";
import PropTypes from "prop-types";
import { Cell, Row } from "@material/react-layout-grid";

import AnchorField from "./anchor/anchor";
import LinkField from "./link/link";
import ImageField from "./image/image";
import SelectField from "./select/select";
import TextField from "./text/text";

import "@material/react-layout-grid/dist/layout-grid.css";

class Fields extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    readonly: PropTypes.bool,
    onChange: PropTypes.func,
    prevValue: PropTypes.string,
  };

  static defaultProps = {
    readonly: true,
    onChange: (update, data) => {},
  };

  render() {
    const { data, readonly, prevValue } = this.props;
    return (
      <Row>
        {Object.keys(data).map((key) => (
          <Cell key={key} columns={data[key].columns || 6}>
            <div className="fields">
              {(() => {
                switch (data[key].type) {
                  case "link":
                    return <LinkField {...data[key]} />;
                  case "anchor":
                    return <AnchorField {...data[key]} />;
                  case "image":
                    return <ImageField {...data[key]} />;
                  case "select":
                    return (
                      <SelectField
                        {...data[key]}
                        data={{ ...data[key] }}
                        readonly={data[key].readonly || readonly}
                        onChange={this.props.onChange}
                        prevValue={prevValue}
                      />
                    );
                  default:
                    return (
                      <TextField
                        {...data[key]}
                        data={{ ...data[key] }}
                        readonly={data[key].readonly || readonly}
                        onChange={this.props.onChange}
                      />
                    );
                }
              })()}
            </div>
          </Cell>
        ))}
      </Row>
    );
  }
}

export default Fields;
