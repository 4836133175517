import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import SelectField from "../../common/fields/select/select";
import { compose } from "../../../util/compose";

/**
 * @extends {React.Component}
 */
class AnnotationListInputReview extends React.Component {
  /**
   * @type {Object}
   */
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object,
    onData: PropTypes.func,
  };
  /**
   * @type {Object}
   */
  static defaultProps = {
    onData: (data) => {},
  };
  /**
   * @param {Boolean} review
   */
  onToggle(review) {
    const { data, onData } = this.props;
    const change = Object.assign({}, data, { review });
    onData(change);
  }
  /**
   * @return {React.ReactNode}
   */
  render() {
    const { data, t } = this.props;
    const options = [
      { label: t("Yes"), value: "yes" },
      { label: t("No"), value: "no" },
    ];
    return (
      <div className="input-review">
        <SelectField
          label={t("User review")}
          value={data.review}
          readonly={false}
          options={options}
          onChange={this.onToggle.bind(this)}
        />
      </div>
    );
  }
}

export default compose(AnnotationListInputReview)(withTranslation());
