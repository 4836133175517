import { actions } from "./slice";

export const {
  mergeBankAccount,
  mergeUser,
  mergeUsers,
  mergePendingUser,
  mergeFulfilledUser,
  mergeFulfilledUsers,
  mergeRejectedUser,
} = actions;
