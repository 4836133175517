import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Time from "../../../common/time/time";
import Uuid from "../../../common/uuid/uuid";
import UserPhone from "../../../user/phone/phone";
import UserName from "../../../user/name/name";
import UserAvatar from "../../../user/avatar/avatar";
import ListItem from "../../../common/list/item/item";
import ListItemGraphic from "../../../common/list/item/itemgraphic";
import ListItemContent from "../../../common/list/item/itemcontent";
import ListItemMeta from "../../../common/list/item/meta/meta";
import UserListItemTags from "./itemtags";
import { compose } from "../../../../util/compose";
import "./item.css";

class UserListItem extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    user: PropTypes.object.isRequired,
  };
  static defaultProps = {
    selected: false,
    onSelect: (user) => {},
  };
  onUser() {
    this.props.history.push(`/users/details/${this.props.user.user_id}`);
  }
  render() {
    const { user, selected, t } = this.props;
    const personal = user.personal || {};
    const address = personal.address || {};
    const gender = personal.gender === "male" ? t("Hombre") : t("Mujer");
    const state = user.state || {};
    return (
      <ListItem
        className="user"
        item={user}
        selected={selected}
        onSelect={this.onUser.bind(this)}
        linkRef={"/users/details/" + user.user_id}
      >
        <ListItemGraphic>
          <UserAvatar user={user} />
        </ListItemGraphic>
        <ListItemContent>
          <h4>
            <UserName user={user} />
          </h4>
          {personal.email && <h5 className="email">{personal.email}</h5>}
          {personal.id_number && <Uuid text="DPI:" uuid={personal.id_number} />}
          <Uuid text="ID:" uuid={user.user_id} />
          <Time time={state.created_at} format="MMMM DD, YYYY H:mm:ss" />
        </ListItemContent>
        <ListItemMeta>
          <UserPhone user={user} />
          {address.city && <h5>{address.city}</h5>}
          {personal.gender && <h5>{gender}</h5>}
        </ListItemMeta>
        <UserListItemTags user={user} />
      </ListItem>
    );
  }
}

export default compose(UserListItem)(withRouter, withTranslation());
