import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Time from "../../../common/time/time";
import { compose } from "../../../../util/compose";

class AnnotationListItemPromise extends React.Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };
  render() {
    return (
      <div className="annotation-type">
        <div className="type-data amount">
          <h4>{this.props.t("Amount")}</h4>
          <span>{`Q ${this.props.annotation.data.amount}`}</span>
        </div>
        <div className="type-data date">
          <h4>{this.props.t("Date")}</h4>
          <Time time={this.props.annotation.data.date} format="MMMM DD, YYYY" />
        </div>
      </div>
    );
  }
}

export default compose(AnnotationListItemPromise)(withTranslation());
