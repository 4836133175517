import Axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import Api from "../../infrastructure/api";
import Disbursals from "../../repository/disbursals";
import Loans from "../../repository/loans";
import Requests from "../../repository/requests";
import Users from "../../repository/users";
import { withAuth } from "../auth/auth";
import Context from "./context";

/** @deprecated */
class DataLayer extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
  };
  dataLayer = {};
  constructor(props) {
    super(props);
    const { token } = this.props;
    const axios = Axios.create({
      baseURL: Api.base,
      headers: { Authorization: this.props.token.access },
    });
    this.dataLayer.users = new Users(token, axios, this.dataLayer);
    this.dataLayer.loans = new Loans(token, axios, this.dataLayer);
    this.dataLayer.requests = new Requests(token, axios, this.dataLayer);
    this.dataLayer.disbursals = new Disbursals(token, axios);
  }
  render() {
    return (
      <Context.Provider value={this.dataLayer}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default withAuth(DataLayer);

export function withDataLayer(Component) {
  return (props) => (
    <Context.Consumer>
      {(dataLayer) => <Component dataLayer={dataLayer} {...props} />}
    </Context.Consumer>
  );
}
