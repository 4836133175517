/* private utils */

import { bankAgencyTypes } from "../domain/banks/bankagencytype";
import { banks } from "../domain/banks/banks";
import {
  paymentBanks,
  paymentBanksOrigin,
} from "../domain/payments/paymentbanks";

const DATE_REGEXP = /^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}$/g;

export const createOkResult = () => ({
  errorMessage: "",
  hasError: false,
  isOk: true,
});

export const createErrorResult = (errorMessage) => ({
  errorMessage: errorMessage,
  hasError: true,
  isOk: false,
});


/* primitive validators */

export const isAlphanumeric = (value) => {
  return value ? value.replace(/[a-zA-Z0-9]+/gi, "") === "" : false;
};

export const isNotInObjectValues = (value, obj) => {
  return !Object.values(obj).includes(value);
};

export const isString = (value) => {
  return typeof value === "string" || value instanceof String;
};

export const isEmpty = (value) => {
  if (isString(value)) {
    return value.trim() === "";
  }
  if (Array.isArray(value)) {
    return value.length <= 0;
  }
  return !value;
};

export const isNumeric = (value) => {
  return !isNaN(value);
};

export const isAboveMinLength = (value, minLength) => {
  return value && value.length && value.length >= minLength;
};

export const isBelowMaxLength = (value, maxLength) => {
  return value && value.length && value.length <= maxLength;
};

export const isOfLength = (value, length) => {
  return value && value.length && value.length === length;
};

export const isMatchOf = (value, pattern) => {
  return !isString(value) || isEmpty(value) ? false : value.match(pattern);
};

export const isDate = (value) => {
  return isString(value) && !isEmpty(value)
    ? isMatchOf(value, DATE_REGEXP)
    : false;
};

export const containsWhiteSpace = (value) => {
  return /\s/g.test(value);
};

/* general validators */

export const okValidator = () => () => {
  return createOkResult();
};

export const errorValidator = () => (errorMessage) => {
  return createErrorResult(errorMessage);
};

export const bankValidator = () => (bank) => {
  return isEmpty(bank) || isNotInObjectValues(bank, banks)
    ? createErrorResult("Banco no reconocido")
    : createOkResult();
};

export const dateValidator = () => (date) => {
  return isEmpty(date) || !isDate(date)
    ? createErrorResult("Fecha incorrecta")
    : createOkResult();
};

export const numericValidator = () => (numeric) => {
  return isEmpty(numeric) || !isNumeric(numeric)
    ? createErrorResult("Valor debe ser un número")
    : createOkResult();
};

export const uuidValidator = () => (numeric) => {
  return isEmpty(numeric) || containsWhiteSpace(numeric)
    ? createErrorResult("Id incorrecto")
    : createOkResult();
};

/* payment validators */

export const paymentBankAgencyValidator = () => (agencyType) => {
  return isEmpty(agencyType) || isNotInObjectValues(agencyType, bankAgencyTypes)
    ? createErrorResult("Tipo de agencia no reconocido")
    : createOkResult();
};

export const paymentAmountValidator = () => (amount) => {
  return isEmpty(amount) || !isNumeric(amount)
    ? createErrorResult("Cantidad de pago inválida")
    : createOkResult();
};

export const paymentBankValidator = () => (bank) => {
  return isEmpty(bank) || isNotInObjectValues(bank, paymentBanks)
    ? createErrorResult("Banco no reconocido")
    : createOkResult();
};

export const paymentBankOriginValidator = () => (bank) => {
  if (bank) {
    return isNotInObjectValues(bank, paymentBanksOrigin)
      ? createErrorResult("Banco de origen inválido")
      : createOkResult();
  } else {
    return createOkResult();
  }
};

export const paymentDateValidator = () => (date) => {
  return isEmpty(date) || !isDate(date)
    ? createErrorResult("Fecha de pago inválida")
    : createOkResult();
};

export const paymentReferenceValidator = () => (reference) => {
  return isEmpty(reference) || containsWhiteSpace(reference)
    ? createErrorResult("Número de referencia inválido")
    : createOkResult();
};

export const paymentReceiptValidator = () => (receiptUri) => {
  return isEmpty(receiptUri) || containsWhiteSpace(receiptUri)
    ? createErrorResult("Link de fotografía inválido")
    : createOkResult();
};

export const paymentReceiptFileValidator = () => (receiptFile) => {
  if (isEmpty(receiptFile)) {
    return createErrorResult("Debes adjuntar una imagen");
  }
  if (receiptFile.size / 1024 / 1024 > 2) {
    return createErrorResult("El tamaño del archivo debe ser menor a 2 MB");
  }
  if (!["image/png", "image/jpg", "image/jpeg"].includes(receiptFile?.type)) {
    return createErrorResult("Debes adjuntar un archivo PNG o JPG");
  }
  return createOkResult();
};
