import classNames from "classnames";
import React from "react";
import { BlockingView } from "./blockingview/blockingview";

import "./layout.css";

export const Article = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "article")}
    component="article"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);

export const Aside = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "aside")}
    component="aside"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);

export const Header = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "header")}
    component="header"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);

export const Layout = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "layout")}
    component="div"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);

export const Main = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "main")}
    component="main"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);

export const Navigation = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "navigation")}
    component="nav"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);

export const Section = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "section")}
    component="section"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);

export const ViewPort = ({ children, className, isPending }) => (
  <BlockingView
    className={classNames(className, "view-port")}
    component="div"
    isPending={isPending}
  >
    {children}
  </BlockingView>
);
