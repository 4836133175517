import React from "react";
import Card from "../../common/card/card";
import { Grid } from "@material/react-layout-grid";
import "./pagenotfound.css";

class PageNotFound extends React.Component {
  render() {
    return (
      <Grid>
        <Card className="user-search">
          <div className="text-main">Página no disponible</div>
        </Card>
      </Grid>
    );
  }
}

export default PageNotFound;
