import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

class Document extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { t, data } = this.props;
    return (
      <FieldsCard
        editable={false}
        data={data}
        fields={fields}
        icon="perm_media"
        title={t("User document")}
        subtitle={t("Identity document images")}
      />
    );
  }
}

export default compose(Document)(withTranslation());
