import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import Progress from "../../common/progress/progress";
import ListItemGroup from "../../common/list/item/itemgroup";
import ArrayUtils from "../../../util/arrayutils";
import { compose } from "../../../util/compose";

class AnnotationListItems extends React.Component {
  static propTypes = {
    adapter: PropTypes.object.isRequired,
    events: PropTypes.array,
    filters: PropTypes.object,
    items: PropTypes.array,
    loading: PropTypes.bool,
    onItem: PropTypes.func,
    t: PropTypes.func.isRequired,
  };
  static defaultProps = {
    filters: {},
    events: {},
    loading: false,
    onItem: (item) => {},
  };
  render() {
    const isEmpty = this.props.items && this.props.items.length === 0;
    return (
      <Progress
        className={classNames("list-items", { "list-empty": isEmpty })}
        loading={!this.props.items && this.props.loading}
        text="Loading items"
      >
        {() => {
          if (isEmpty) {
            return (
              <div className="center">
                <span className="text">{this.props.t("No items found")}</span>
                <span className="icon">cloud_off</span>
              </div>
            );
          }
          const buffer = {};
          const events = [...this.props.events];
          const list = [];
          for (const itemKey in this.props.items) {
            const item = this.props.items[itemKey];
            for (const groupKey in this.props.adapter.groups) {
              let make = true;
              const group = this.props.adapter.groups[groupKey];
              for (const filter in group.filters) {
                const rule = group.filters[filter];
                const selected = this.props.filters[filter] || [];
                if (rule === "*") {
                  continue;
                }
                if (selected.length && ArrayUtils.containsAll(rule, selected)) {
                  continue;
                }
                make = false;
                break;
              }
              if (make && group.onGroup(item, buffer[groupKey])) {
                buffer[groupKey] = item;
                list.push(
                  <ListItemGroup
                    key={`group-${groupKey}-${itemKey}`}
                    className={groupKey}
                    icon={group.icon}
                    text={group.onText(item)}
                  />
                );
              }
            }
            while (events.length && item.created_at >= events[0].date) {
              list.push(
                <ListItemGroup
                  key={`group-event-${events.length}`}
                  className={"event"}
                  icon={events[0].icon}
                  text={events[0].text}
                />
              );
              events.shift();
            }
            list.push(this.props.children(item, `item-${itemKey}`));
          }
          return list;
        }}
      </Progress>
    );
  }
}

export default compose(AnnotationListItems)(withTranslation());
