import React from "react";
import PropTypes from "prop-types";

import ListItemTags from "../../../common/list/item/tags/tags";

class RequestListItemTags extends React.Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
  };
  render() {
    const t = (s) => s;
    const tags = [];
    this.props.request &&
      this.props.request.user &&
      this.props.request.user.state &&
      this.props.request.loan_request_id ===
        this.props.request.user.state.active_loan_id &&
      tags.push({
        className: "active-loan",
        icon: "verified",
        text: t("Active loan"),
      });
    this.props.request.state.status === "created" &&
      tags.push({
        className: "created",
        icon: "add",
        text: t("Created"),
      });
    this.props.request.state.status === "approved" &&
      tags.push({
        className: "approved",
        icon: "done",
        text: t("Approved"),
      });
    this.props.request.state.status === "rejected" &&
      tags.push({
        className: "rejected",
        icon: "clear",
        text: t("Rejected"),
      });
    this.props.request.state.status === "archived" &&
      tags.push({
        className: "archived",
        icon: "delete",
        text: t("Archived"),
      });
    this.props.request.state.status === "offer_expired" &&
      tags.push({
        className: "offer-expired",
        icon: "watch_later",
        text: t("Offer expired"),
      });
    this.props.request.state.status === "offer_accepted" &&
      tags.push({
        className: "offer-accepted",
        icon: "done",
        text: t("Offer accepted"),
      });
    this.props.request.state.status === "offer_declined" &&
      tags.push({
        className: "offer-declined",
        icon: "clear",
        text: t("Offer declined"),
      });
    this.props.request.state.status === "offer_declined" &&
      tags.push({
        className: "offer-declined",
        icon: "clear",
        text: t("Offer declined"),
      });
    this.props.request.state.status === "document_sent" &&
      tags.push({
        className: "document-sent",
        icon: "send",
        text: t("Document sent"),
      });
    this.props.request.state.status === "document_approved" &&
      tags.push({
        className: "document-approved",
        icon: "done",
        text: t("Document approved"),
      });
    this.props.request.state.status === "document_rejected" &&
      tags.push({
        className: "document-rejected",
        icon: "clear",
        text: t("Document rejected"),
      });
    this.props.request.state.status === "signed" &&
      tags.push({
        className: "signed",
        icon: "gesture",
        text: t("Signed"),
      });
    this.props.request.disbursal_method &&
      tags.push({
        className: "disbursal-method",
        icon: "account_balance",
        title:
          this.props.request.disbursal_method === "gtc_rem"
            ? t("Cash pickup")
            : t("Bank transfer"),
      });
    return <ListItemTags tags={tags} />;
  }
}

export default RequestListItemTags;
