import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import "./list.css";
class List extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };
  render() {
    const { className, children } = this.props;
    return <div className={ClassNames("list", className)}>{children}</div>;
  }
}

export default List;
