export const selectAllBankAccounts = () => (store) => {
  return store.users.bankAccounts
    ? Object.values(store.users.bankAccounts)
    : [];
};

export const selectBankAccount = (userId) => (store) => {
  return userId && store.users.bankAccounts && store.users.bankAccounts[userId]
    ? { ...store.users.bankAccounts[userId] }
    : "";
};

export const selectUserById = (userId) => (store) => {
  return userId && store.users.users && store.users.users[userId]
    ? { ...store.users.users[userId] }
    : "";
};

export const selectUserFullName = (userId) => (store) => {
  const user = selectUserById(userId)(store);
  if (!user) {
    return "";
  }
  const firstName =
    user && user.personal && user.personal.firstName
      ? user.personal.firstName
      : "";
  const lastName =
    user && user.personal && user.personal.lastName
      ? user.personal.lastName
      : "";
  return firstName || lastName
    ? `${firstName} ${lastName}`
    : "Usuario desconocido";
};
