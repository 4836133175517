import classNames from "classnames";
import { useEffect, useState } from "react";
import Lightbox from "react-awesome-lightbox";
import { Conditional } from "../../components";
import { BlockingView } from "../../layout/blockingview/blockingview";
import "./imageviewer.css";

const computeImageRect = (image, maxWidth, maxHeight) => {
  const max = {
    height: maxHeight ? Math.min(maxHeight, image.height) : image.height,
    width: maxWidth ? Math.min(maxWidth, image.width) : image.width,
  };
  const rect = {};
  rect.width = max.width;
  rect.height = (rect.width / image.width) * image.height;
  if (rect.height <= max.height) {
    return rect;
  }
  rect.height = max.height;
  rect.width = (rect.height / image.height) * image.width;
  return rect;
};

export const ImageViewer = ({ className, imageUrl, maxWidth, maxHeight }) => {
  const [imageRect, setImagRect] = useState();
  useEffect(() => {
    if (imageUrl) {
      const image = new Image();
      image.onload = () => {
        setImagRect(computeImageRect(image, maxWidth, maxHeight));
      };
      image.src = imageUrl;
    }
  }, [imageUrl, maxWidth, maxHeight, setImagRect]);
  return (
    <BlockingView
      className={classNames(className, "image-viewer")}
      isPending={!imageUrl || !imageRect}
      style={imageRect}
    >
      <Conditional condition={imageUrl}>
        <Lightbox image={imageUrl} />
      </Conditional>
    </BlockingView>
  );
};
