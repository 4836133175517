import React from "react";
import PropTypes from "prop-types";
import { DialogContent } from "@material/react-dialog";
import './input-confirm.css';

class InputContent extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    inputText: PropTypes.string.isRequired,
  };

  onInputChange(value) {
    this.props.onChange({ data: { inputValue: value } });
  }

  render() {
    return (
      <DialogContent className="input-content">
        <input
          className="text-input"
          onChange={(e) => this.onInputChange(e.target.value)}
          placeholder={this.props.inputText}
        />
      </DialogContent>
    )
  }
}
export default InputContent;
