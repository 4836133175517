import { useDispatch } from "react-redux";
import { bankLabels, bankOptions } from "../../../domain/banks/banks";
import {
  transactionStatusLabels,
  transactionStatusOptions,
} from "../../../domain/banks/transactionstatus";
import { paymentBanks } from "../../../domain/payments/paymentbanks";
import {
  useBankTextInput,
  useDateTextInput,
  useTextInput,
} from "../../../hooks/usetextinput";
import { getBankStatement } from "../../../redux/banks/thunks/getbankstatement";
import {
  containsWhiteSpace,
  createErrorResult,
  createOkResult,
  isEmpty,
  isNumeric,
  okValidator,
} from "../../../util/validators";
import { Button, ContainedButton } from "../../controls/button/button";
import { DatePickerInput } from "../../controls/datepicker/datepickerinput";
import { Icon } from "../../controls/icon/icon";
import { AutoCompleteTextView } from "../../controls/textinput/autocompletetextview";
import {
  TextInputEditText,
  TextInputLabel,
} from "../../controls/textinput/textinput";
import {
  TextInputErrorMessage,
  TextInputLayout,
} from "../../controls/textinput/textinputlayout";
import { getDiffInDays } from "../../../util/dateutils";
import "./statementfilter.css";

export const AmountField = ({ errorMessage, hasError, onChange, value }) => {
  return (
    <TextInputLayout className="amount">
      <TextInputEditText hasError={hasError} onChange={onChange} value={value}>
        <TextInputLabel>Cantidad</TextInputLabel>
      </TextInputEditText>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const BankField = ({ errorMessage, hasError, onChange, value }) => {
  const options = bankOptions.filter(({ value }) => paymentBanks[value]);
  const bank = value && bankLabels[value] ? bankLabels[value] : value;
  return (
    <TextInputLayout className="bank">
      <AutoCompleteTextView
        hasError={hasError}
        options={options}
        onChange={onChange}
        value={bank}
      >
        <TextInputLabel>Banco</TextInputLabel>
      </AutoCompleteTextView>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const DateField = ({
  errorMessage,
  hasError,
  onChange,
  value,
  title,
  label,
}) => {
  return (
    <TextInputLayout className="date">
      <DatePickerInput
        title={title || "Fecha"}
        hasError={hasError}
        onChange={onChange}
        value={value}
      >
        <TextInputLabel>{label || "Fecha"}</TextInputLabel>
      </DatePickerInput>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const ReferenceField = ({ errorMessage, hasError, onChange, value }) => {
  return (
    <TextInputLayout className="reference">
      <TextInputEditText hasError={hasError} onChange={onChange} value={value}>
        <TextInputLabel>No. de referencia</TextInputLabel>
      </TextInputEditText>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const DocumentField = ({ errorMessage, hasError, onChange, value }) => {
  return (
    <TextInputLayout className="document">
      <TextInputEditText hasError={hasError} onChange={onChange} value={value}>
        <TextInputLabel>Documento</TextInputLabel>
      </TextInputEditText>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

export const StatusField = ({ errorMessage, hasError, onChange, value }) => {
  const status =
    value && transactionStatusLabels[value]
      ? transactionStatusLabels[value]
      : value;
  return (
    <TextInputLayout className="status">
      <AutoCompleteTextView
        hasError={hasError}
        options={transactionStatusOptions}
        onChange={onChange}
        value={status}
      >
        <TextInputLabel>Status</TextInputLabel>
      </AutoCompleteTextView>
      <TextInputErrorMessage>{errorMessage}</TextInputErrorMessage>
    </TextInputLayout>
  );
};

const amountValidator = () => (value) => {
  return isEmpty(value) || isNumeric(value)
    ? createOkResult()
    : createErrorResult("Cantidad debe ser un número");
};

const referenceValidator = () => (value) => {
  return isEmpty(value) || !containsWhiteSpace(value)
    ? createOkResult()
    : createErrorResult("Referencia inválida");
};

export const StatementFilter = ({ clearFilter, filter, setFilter }) => {
  const dispatch = useDispatch();
  const amount = useTextInput(amountValidator(), filter.amount);
  const bank = useBankTextInput(filter.bank);
  const dateFrom = useDateTextInput(filter.dateFrom);
  const dateTo = useDateTextInput(filter.dateTo);
  const reference = useTextInput(referenceValidator(), filter.reference);
  const document = useTextInput(filter.document);
  const status = useTextInput(okValidator(), filter.status);
  const validateAll = () =>
    [amount, bank, dateFrom, dateTo, reference].reduce(
      (prev, next) => next.validate().isOk && prev,
      true
    );
  const getStatement = async () => {
    if (!validateAll()) {
      return;
    }
    if (
      bank.value !== filter.bank ||
      dateFrom.value !== filter.dateFrom ||
      dateTo.value !== filter.dateTo
    ) {
      await dispatch(
        getBankStatement({
          bankId: bank.value,
          date: dateTo.value,
          days: getDiffInDays(dateFrom.value, dateTo.value),
        })
      );
    }
    await setFilter({
      amount: amount.value,
      bank: bank.value,
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
      reference: reference.value,
      document: document.value,
      status: status.value,
    });
  };
  const onFormSubmit = async (event) => {
    event.preventDefault();
    await getStatement();
  };
  const onRefreshButtonClick = async () => {
    if (bank.value && dateFrom.value && dateTo.value) {
      await dispatch(
        getBankStatement({
          bankId: bank.value,
          date: dateTo.value,
          days: getDiffInDays(dateFrom.value, dateTo.value),
        })
      );
    }
  };
  const onCleanButtonClick = async () => {
    amount.init("");
    bank.init("");
    dateFrom.init("");
    dateTo.init("");
    reference.init("");
    status.init("");
    await clearFilter();
  };
  const onDateFromChange = async (value) => {
    dateFrom.update(value);
  };
  return (
    <form className="filter" onSubmit={onFormSubmit}>
      <table>
        <tbody>
          <tr>
            <td>
              <BankField
                errorMessage={bank.errorMessage}
                hasError={bank.hasError}
                onChange={bank.update}
                value={bank.value}
              />
            </td>
            <td>
              <StatusField
                errorMessage={status.errorMessage}
                hasError={status.hasError}
                onChange={status.update}
                value={status.value}
              />
            </td>
            <td>
              <ReferenceField
                errorMessage={reference.errorMessage}
                hasError={reference.hasError}
                onChange={reference.update}
                value={reference.value}
              />
            </td>
            <td>
              <DocumentField
                errorMessage={document.errorMessage}
                hasError={document.hasError}
                onChange={document.update}
                value={document.value}
              />
            </td>
          </tr>
          <tr>
            <td>
              <AmountField
                errorMessage={amount.errorMessage}
                hasError={amount.hasError}
                onChange={amount.update}
                value={amount.value}
              />
            </td>
            <td>
              <DateField
                errorMessage={dateFrom.errorMessage}
                hasError={dateFrom.hasError}
                onChange={onDateFromChange}
                value={dateFrom.value}
                title="Fecha desde"
                label="Desde"
              />
            </td>
            <td>
              <DateField
                errorMessage={dateTo.errorMessage}
                hasError={dateTo.hasError}
                onChange={dateTo.update}
                value={dateTo.value}
                title="Fecha hasta"
                label="Hasta"
              />
            </td>
            <td>
              <ContainedButton className="search" rippleLight type="submit">
                <Icon icon="search" />
              </ContainedButton>
              <Button className="refresh" onClick={onRefreshButtonClick}>
                <Icon icon="refresh" />
              </Button>
              <Button className="clean" onClick={onCleanButtonClick}>
                <Icon icon="cleaning_services" />
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};
