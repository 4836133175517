import classNames from "classnames";
import { Ripple } from "../ripple/ripple";
import "./button.css";

export const Button = ({
  children,
  className,
  outlined,
  contained,
  rounded,
  isDisabled,
  rippleLight,
  rippleDark,
  role = "button",
  type = "button",
  ...props
}) => (
  <Ripple
    {...props}
    role={role}
    light={rippleLight}
    dark={rippleDark}
    className={classNames(className, "btn", {
      outlined,
      contained,
      rounded,
      disabled: isDisabled,
    })}
    type={type}
    disabled={isDisabled}
    component="button"
  >
    {children}
  </Ripple>
);

export const ButtonLabel = ({ className, children }) => (
  <span className={classNames(className, "label")}>{children}</span>
);

export const RoundedButton = ({ children, ...props }) => (
  <Button {...props} rounded={true}>
    {children}
  </Button>
);

export const OutlinedButton = ({ children, ...props }) => (
  <RoundedButton {...props} outlined={true}>
    {children}
  </RoundedButton>
);

export const ContainedButton = ({ children, rippleLight = true, ...props }) => (
  <RoundedButton {...props} contained={true} rippleLight={rippleLight}>
    {children}
  </RoundedButton>
);
