import React from "react";
import PropTypes from "prop-types";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { Switch, Route, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MODULE_DISBURSAL_ACTIVE } from "../../globals/variables/variables";
import Details from "./details";
import Summary from "./summary";
import { withDataLayer } from "../../datalayer/datalayer";
import { compose } from "../../../util/compose";
import { withApp } from "../../app/app";
import Progress from "../../../components/common/progress/progress";
import { DISBURSALS_LMSV2_URL } from "../../globals/variables/variables";

class Disbursal extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      services: [],
      isLoading: true,
    };
  }

  getServices() {
    this.props.dataLayer.disbursals
      .getServices()
      .then((services) => {
        this.setState({
          services: services.data.data.items,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        this.props.app.onError({
          text: this.props.t("Error fetching disbursals data."),
          data: error.details,
        });
      });
  }

  componentDidMount() {
    if (!MODULE_DISBURSAL_ACTIVE) {
      window.location.href = DISBURSALS_LMSV2_URL;
    } else {
      if (this.state.services.length === 0) {
        this.getServices();
      }
    }
  }

  render() {
    const { match } = this.props;
    const { services } = this.state;

    return (
      <Switch>
        <Route path={`${match.path}/:service/:handler/:type`}>
          <Details />
        </Route>
        <Route path={match.path}>
          <Progress
            className="disburals full-screen-loader"
            loading={this.state.isLoading}
            text={this.props.t("Loading items")}
          >
            {() => {
              return (
                <Grid>
                  <Row>
                    <Cell columns={12}>
                      <Row>
                        {services.map((detail, index) => (
                          <Cell key={index} columns={6}>
                            <Summary disbursalDetails={detail} />
                          </Cell>
                        ))}
                      </Row>
                    </Cell>
                  </Row>
                </Grid>
              );
            }}
          </Progress>
        </Route>
      </Switch>
    );
  }
}
export default compose(withRouter(Disbursal))(
  withApp,
  withDataLayer,
  withTranslation()
);
