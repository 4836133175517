export const loggerMiddleware = (store) => (next) => (action) => {
  if (
    action.type.endsWith("fulfilled") ||
    action.type.endsWith("rejected") ||
    action.type.endsWith("pending")
  ) {
    // console.log("dispatch", action, store.getState());
  }
  return next(action);
};
