import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import es_ES from "./translations/es";
import en_US from "./translations/en";

const resources = {
  en: en_US,
  es: es_ES,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
