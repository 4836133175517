import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

class State extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
  };



  static defaultProps = {
    onUpdate: (update) => {},
  };

  onUpdate(update) {
    let isSoftReject = false;
    let value = update.status.value;
    if (update.status.value === "soft_rejected") {
      isSoftReject = true;
      value = "rejected";
    }
    this.props.onUpdate(value, isSoftReject);
  }


  render() {
    const { data, t } = this.props;
    const shortTermInstallments = "short_term_installments";
    const fieldsHandled = Object.assign({}, fields);
    if ((data.offer_type === shortTermInstallments && data.status === "document_sent") || ["dev","qa"].includes(process.env.REACT_APP_ENVIRONMENT)) {
      const statusModify = ["document_approved","document_rejected","rejected","soft_rejected"]
      const optionsHandled = fields.status.options.map((item) => {
        if (statusModify.includes(item.value)) {
          item.from.push("document_sent");
        }    
        return item;
      })
      fieldsHandled.status.options = optionsHandled;
    }
    
    return (
      <FieldsCard
        key="textFieldGroup"
        data={data}
        fields={fieldsHandled}
        editable={true}
        icon="perm_data_setting"
        title={t("Loan request state")}
        onUpdate={this.onUpdate.bind(this)}
      />
    );
  }
}

export default compose(State)(withTranslation());
