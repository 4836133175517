import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import DateInput from "../../common/fields/date/date";
import Text from "../../common/fields/text/text";
import { compose } from "../../../util/compose";

class AnnotationListInputPromise extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    onData: PropTypes.func,
  };
  static defaultProps = {
    onData: (data) => {},
    data: { amount: "", date: "" },
  };
  onDate(date) {
    const { data, onData } = this.props;
    const change = Object.assign({}, data, { date: date.from });
    onData(change);
  }
  onAmount(amount) {
    if (!isNaN(amount)) {
      const { data, onData } = this.props;
      const change = Object.assign({}, data, { amount });
      onData(change);
    }
  }
  render() {
    return (
      <div className="input-promise">
        <Text
          readonly={false}
          icon="account_balance_wallet"
          value={this.props.data.amount}
          label={this.props.t("Amount")}
          className={"field field-amount"}
          onChange={this.onAmount.bind(this)}
        />
        <DateInput
          readonly={false}
          label={this.props.t("Fecha")}
          date={{ from: this.props.data.date }}
          onDate={this.onDate.bind(this)}
        />
      </div>
    );
  }
}

export default compose(AnnotationListInputPromise)(withTranslation());
