import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./avatar.css";

class UserAvatar extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    className: PropTypes.string,
  };
  static defaultProps = {
    user: {},
  };
  render() {
    const { className } = this.props;
    const user = this.props.user || {};
    const personal = user.personal || {};
    const document = personal.document || {};
    const selfie = document.selfie;
    return (
      <div
        className={classNames("user-avatar", className)}
        style={{ backgroundImage: selfie ? `url(${selfie})` : "" }}
      />
    );
  }
}

export default UserAvatar;
