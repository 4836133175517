import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import TextField, { Input } from "@material/react-text-field";
import MaterialIcon from "@material/react-material-icon";
import "@material/react-text-field/dist/text-field.min.css";
import "@material/react-material-icon/dist/material-icon.css";

import Card from "../../../common/card/card";
import CircleIcon from "../../../common/circle/circleicon";
import Heading from "../../../common/heading";
import UserList from "../../../user/list/list";
import UserListAdapter from "../../../user/list/listadapter";
import { compose } from "../../../../util/compose";
import { withApp } from "../../../app/app";
import { withDataLayer } from "../../../datalayer/datalayer";
import "./search.css";
import {
  MODULE_SEARCH_CLIENT_ACTIVE,
  SEARCH_CLIENTS_LMSV2_URL,
} from "../../../globals/variables/variables";

class Search extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value: "",
      users: {},
    };
  }

  componentDidMount() {
    if (!MODULE_SEARCH_CLIENT_ACTIVE) {
      window.location.href = SEARCH_CLIENTS_LMSV2_URL;
    }
  }

  onSearch() {
    if (!this.state.value) return Promise.resolve([]);
    this.setState({ loading: true });
    return this.props.dataLayer.users
      .searchUser(this.state.value)
      .then((users) => this.setState({ users }))
      .catch((error) =>
        this.props.app.onError({
          text: this.props.t("Error searching users."),
          error,
        })
      )
      .finally(() => this.setState({ loading: false }));
  }
  onChange(event) {
    this.setState({ value: event.target.value });
  }
  onUser(user) {
    this.props.history.push(`/users/details/${user.user_id}`);
  }
  onKey(event) {
    event.key === "Enter" && this.onSearch();
  }
  render() {
    return (
      <Card className="user-search">
        <Heading
          title={this.props.t("Search")}
          subtitle={this.props.t("By ID, DPI, email or phone number")}
          graphic={<CircleIcon light={true} icon="search" />}
        />
        <div className="search-text">
          <TextField
            label={this.props.t("User Id, DPI, email or phone number")}
            onTrailingIconSelect={this.onSearch.bind(this)}
            trailingIcon={<MaterialIcon role="button" icon="search" />}
          >
            <Input
              value={this.state.value}
              onKeyDown={this.onKey.bind(this)}
              onChange={this.onChange.bind(this)}
            />
          </TextField>
        </div>
        <UserList
          adapter={UserListAdapter}
          users={this.state.users ? Object.values(this.state.users) : null}
          loading={this.state.loading}
          onReload={this.onSearch.bind(this)}
          onUser={this.onUser.bind(this)}
        />
      </Card>
    );
  }
}

export default compose(Search)(
  withApp,
  withDataLayer,
  withRouter,
  withTranslation()
);
