import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { withTranslation } from "react-i18next";
import "@material/react-layout-grid/dist/layout-grid.css";
import LoanListAdapter from "../../loan/list/listadapter";
import LoanList from "../../loan/list/list";
import Card from "../../common/card/card";
import CardContent from "../../common/card/cardcontent";
import CardHeader from "../../common/heading";
import CircleIcon from "../../common/circle/circleicon";
import { compose } from "../../../util/compose";
import { withApp } from "../../app/app";
import { withDataLayer } from "../../datalayer/datalayer";
import "./loan.css";

const Loan = ({ t, app, dataLayer }) => {
  const [loading, setLoading] = useState();
  const [signed, setSigned] = useState();
  const [transferring, setTransferring] = useState();
  const getLoansByStatus = async (status) => {
    const loans = await dataLayer.loans.getLoans({
      associations: "users",
      status: status,
      type: "status",
    });
    return Object.values(loans);
  };
  const getLoans = async () => {
    setLoading(true);
    try {
      const signed = await getLoansByStatus("signed");
      const transferring = await getLoansByStatus("transferring");
      setSigned(signed);
      setTransferring(transferring);
    } catch (error) {
      app.onError({
        text: t("Error fetching loan data."),
        data: error.details,
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!loading && !signed && !transferring) {
      getLoans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid>
      <Row>
        <Cell columns={12}>
          <Row>
            <Cell columns={12}>
              <Card className="loans status-signed">
                <CardHeader
                  title={t("Signed loans")}
                  titleNote={
                    signed && signed.length > 0 ? `(${signed?.length})` : ""
                  }
                  subtitle={t("Loans waiting to be transferred")}
                  graphic={<CircleIcon light={true} icon="gesture" />}
                />
                <CardContent>
                  <LoanList
                    adapter={LoanListAdapter}
                    loading={loading}
                    loans={signed}
                    onReload={getLoans}
                  />
                </CardContent>
              </Card>
            </Cell>
            <Cell columns={12}>
              <Card className="loans status-transferring">
                <CardHeader
                  title={t("Transferring loans")}
                  titleNote={
                    transferring && transferring.length > 0
                      ? `(${transferring?.length})`
                      : ""
                  }
                  subtitle={t("Loans currently being transferred")}
                  graphic={<CircleIcon light={true} icon="swap_horiz" />}
                />
                <CardContent>
                  <LoanList
                    adapter={LoanListAdapter}
                    loading={loading}
                    loans={transferring}
                    onReload={getLoans}
                  />
                </CardContent>
              </Card>
            </Cell>
          </Row>
        </Cell>
      </Row>
    </Grid>
  );
};

Loan.propTypes = {
  t: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  dataLayer: PropTypes.object.isRequired,
};

export default compose(Loan)(withApp, withDataLayer, withTranslation());
