export const fields = {
  amount: {
    key: "amount",
    label: "Amount",
    icon: "lock",
    columns: 6,
  },
  interest_amount: {
    key: "interest_amount",
    label: "Interest amount",
    icon: "lock",
    columns: 6,
  },
  interest_rate: {
    key: "interest_rate",
    label: "Interest rate",
    icon: "lock",
    columns: 6,
  },
  term: {
    key: "term",
    label: "Term",
    icon: "lock",
    columns: 6,
  },
  category: {
    key: "category",
    label: "Category",
    icon: "lock",
    columns: 6,
  },
  sub_category: {
    key: "sub_category",
    label: "Subcategory",
    icon: "lock",
    columns: 6,
  },
  purpose: {
    key: "purpose",
    label: "Purpose",
    icon: "lock",
    columns: 6,
  },
  disbursal_method: {
    key: "disbursal_method",
    label: "Disbursal method",
    icon: "lock",
    type: "select",
    readonly: true,
    columns: 6,
    options: [
      { label: "Cash pickup", value: "gtc_rem", from: [] },
      { label: "Bank transfer", value: "transfer", from: [] },
    ],
  },
  processing_fee: {
    key: "processing_fee",
    label: "Administrative expenses",
    icon: "lock",
    columns: 6,
  },
  promissory_note: {
    key: "promissory_note",
    label: "Promissory note",
    type: "link",
    url: (value) => value,
    columns: 12,
  },
  signature: {
    key: "signature",
    label: "Signature",
    type: "image",
    url: (value) => value,
    showValueInField: false,
    columns: 12,
  },
};
