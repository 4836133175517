export const fields = {
  id_number: {
    key: "id_number",
    label: "DPI",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  id_number_verify_link: {
    key: "id_number_verify_link",
    label: "Consultar en SAT",
    columns: 6,
    type: "link",
    value: "https://portal.sat.gob.gt/portal/consulta-cui-nit/"
  },
  first_name: {
    key: "first_name",
    label: "First name",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  last_name: {
    key: "last_name",
    label: "Last name",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  selfie: {
    key: "selfie",
    label: "Selfie",
    columns: 12,
    readonly: true,
    type: "image"
  },
  front: {
    key: "front",
    label: "Front",
    columns: 6,
    readonly: true,
    type: "image"
  },
  back: {
    key: "back",
    label: "Back",
    columns: 6,
    readonly: true,
    type: "image"
  }
};
