export const countryFilterOptions = {
  GT: { text: 'GT', icon: 'IconFlagGT' },
  DO: { text: 'DO', icon: 'IconFlagDO' },
  HN: { text: 'HN', icon: 'IconFlagHN' },
};

export const countryFilter = (items, selected) => {
  return items.filter(({ user, personal }) => {
    const country =
      user?.personal?.country ||
      personal?.country ||
      countryFilterOptions.GT.text;
    return !selected.length || selected.includes(country);
  });
};
