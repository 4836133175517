import { mergeItemByIndex, mergeItemsByIndex } from "../../util/stateutils";

export const mergePayment = (state, action) => {
  state.payments = mergeItemByIndex(
    (payment) => payment.paymentId,
    action.payload,
    state.payments
  );
};

export const mergePayments = (state, action) => {
  state.payments = mergeItemsByIndex(
    (payment) => payment.paymentId,
    action.payload,
    state.payments
  );
};

export const mergePendingPayment = (state, action) => {
  state.payments = mergeItemByIndex(
    (payment) => payment.paymentId,
    {
      paymentId: action.payload,
      $isPending: true,
      $isFulfilled: false,
      $isRejected: false,
    },
    state.payments
  );
};

export const mergeFulfilledPayment = (state, action) => {
  state.payments = mergeItemByIndex(
    (payment) => payment.paymentId,
    {
      ...action.payload,
      $isPending: false,
      $isFulfilled: true,
      $isRejected: false,
    },
    state.payments
  );
};

export const mergeFulfilledPayments = (state, action) => {
  state.payments = mergeItemsByIndex(
    (payment) => payment.paymentId,
    action.payload.map((payment) => ({
      ...payment,
      $isPending: false,
      $isFulfilled: true,
      $isRejected: false,
    })),
    state.payments
  );
};

export const mergeRejectedPayment = (state, action) => {
  state.payments = mergeItemByIndex(
    (payment) => payment.paymentId,
    {
      paymentId: action.payload,
      $isPending: false,
      $isFulfilled: false,
      $isRejected: true,
    },
    state.payments
  );
};
