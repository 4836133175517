import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";
import { compose } from "../../../../../util/compose";

import "@material/react-layout-grid/dist/layout-grid.css";

class State extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    onUpdate: PropTypes.func
  };

  static defaultProps = {
    onUpdate: (update) => {}
  };

  onUpdate(update) {
    this.props.onUpdate(update.status.value);
  }

  render() {
    const { data, t } = this.props;
    return (
      <FieldsCard
        data={data}
        editable={true}
        fields={fields}
        icon="perm_data_setting"
        title={t("Loan state")}
        subtitle={t("Stores the current state of this loan")}
        onUpdate={this.onUpdate.bind(this)}
      />
    );
  }
}

export default compose(State)(withTranslation());
