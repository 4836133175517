import { objectKeysToCamelCase } from "../util/commonutils";

export const createLoanRepo = (apiClient) => {
  const getBalance = async (loanId) => {
    if (!loanId) {
      throw new Error("invalid or missing params");
    }
    const url = `/loans/${loanId}/balance`;
    const response = await apiClient.get({ url });
    return response.data ? objectKeysToCamelCase(response.data) : "";
  };
  return {
    getBalance,
  };
};
