import { useEffect, useRef, useState } from "react";
import { ImageViewer } from "../../controls/imageviewer/imageviewer";
import { Section } from "../../layout/layout";
import "./receiptimage.css";

export const ReceiptImage = ({ payment }) => {
  const element = useRef();
  const [maxWidth, setMaxWidth] = useState(350);

  const imageUrl =
    payment && payment.image && payment.image.remoteUrl
      ? payment.image.remoteUrl
      : "";
  useEffect(() => {
    if (element.current) {
      const rect = element.current.getBoundingClientRect();
      setMaxWidth(rect.width);
    }
  }, [setMaxWidth]);
  return (
    <Section className="receipt" key={payment.paymentId}>
      <h5 ref={element}>Boleta</h5>
      <ImageViewer imageUrl={imageUrl} maxWidth={maxWidth} maxHeight={360} />
    </Section>
  );
};
