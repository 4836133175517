import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/sidebar/slice";

function AppLayout(props) {
  const dispatch = useDispatch();
  const onMask = () => {
    dispatch(actions.toggleCollections());
  };

  return (
    <div className="app-content toggle-off">
      <div className="app-mask" onClick={onMask} />
      {props.children}
    </div>
  );
}

AppLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AppLayout;
