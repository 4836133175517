import { handleError } from "../util/handleerror";

/**
 * Repost repository.
 */
class Disbursals {
  /**
   * @param {Object} token
   * @param {Axios} requests
   */
  constructor(token, requests) {
    this.token = token;
    this.requests = requests;
  }
  /**
   * @return {Promise<Object>}
   */
  getServices() {
    return this.requests({
      method: "get",
      url: "/disbursals/services",
    })
      .then((response) => response)
      .catch(handleError);
  }
  /**
   * @param {String} service
   * @param {String} handler
   * @param {String} type
   * @return {Promise<Object>}
   */
  getDisbursals(service, handler, type) {
    return this.requests({
      method: "get",
      url: `/disbursals/services/${service}/${handler}/${type}`,
    })
      .then((response) => response)
      .catch(handleError);
  }
  /**
   * @param {String} service
   * @param {String} handler
   * @param {String} type
   * @return {Promise<Object>}
   */
  fallbackLoad(service, handler, type) {
    return this.requests({
      method: "post",
      url: "/disbursals/fallbacks/load",
      data: { data: { service, handler, type } },
    })
      .then((response) => response)
      .catch(handleError);
  }
  /**
   * @param {String} loan_id
   * @param {String} status
   * @return {Promise<Object>}
   */
  failures(loan_id) {
    return this.requests({
      method: "post",
      url: "/disbursals/failures",
      data: { data: { loan_id, status: "failed_transfer" } },
    })
      .then((response) => response)
      .catch(handleError);
  }
    /**
   * @param {String} execution_id
   * @param {String} service
   * @return {Promise<Object>}
   */
  settleFallback(execution_id, service, type) {
    return this.requests({
      method: "post",
      url: "/disbursals/fallbacks/settle",
      data: { data: { execution_id, service, type} },
    })
      .then((response) => response)
      .catch(handleError);
  }
}

export default Disbursals;
