export const fields = {
  phone_number: {
    key: "phone_number",
    label: "Phone",
    icon: "phone",
    columns: 6,
    readonly: true,
  },
  contact_name: {
    key: "contact_name",
    label: "Contact name",
    icon: "lock",
    columns: 6,
    readonly: true,
  },
  full_name: {
    key: "full_name",
    label: "Full name",
    icon: "lock",
    columns: 6,
    readonly: true,
  },
  relation: {
    key: "relation",
    label: "Relation",
    icon: "lock",
    columns: 6,
    readonly: true,
    type: "select",
  },
  type: {
    key: "type",
    label: "Type",
    icon: "lock",
    columns: 6,
    readonly: true,
    type: "select",
    options: [
      { label: "Personal", value: "personal", from: [] },
      { label: "Work", value: "work", from: [] },
    ],
  },
  status: {
    key: "status",
    label: "Status",
    icon: "lock",
    columns: 6,
    readonly: true,
    type: "select",
    options: [
      { label: "Registered", value: "registered", from: [] },
      { label: "Verified", value: "verified", from: [] },
      { label: "Rejected", value: "rejected", from: [] },
    ],
  },
};

export const extraFields = {
  personal_relation: [
    { label: "Friend", value: "friend", from: [] },
    { label: "Neighbor", value: "neighbor", from: [] },
    { label: "Acquaintance", value: "acquaintance", from: [] },
    { label: "Spouse", value: "spouse", from: [] },
    { label: "Children", value: "children", from: [] },
    { label: "Sibling", value: "sibling", from: [] },
    { label: "Parent", value: "parent", from: [] },
    { label: "Sibling in law", value: "sibling_in_law", from: [] },
  ],
  work_relation: [
    { label: "Client", value: "client", from: [] },
    { label: "Coworker", value: "coworker", from: [] },
    { label: "Employer", value: "employer", from: [] },
  ],
};
