import { withRouter } from "react-router";
import { compose } from "../../util/compose";
import { Bank } from "./bank";
import "./banks.css";
import { List } from "./list";

const BanksWrap = ({ match }) => {
  const paramA = match.params.paramA ? match.params.paramA : "";
  const paramB = match.params.paramB ? match.params.paramB : "";
  const allowStatementUpdate = paramA === "update";
  const bankId = allowStatementUpdate ? paramB : paramA;
  return (
    <div className="banks">
      <List bankId={bankId} allowStatementUpdate={allowStatementUpdate} />
      <Bank bankId={bankId} allowStatementUpdate={allowStatementUpdate} />
    </div>
  );
};

export const Banks = compose(BanksWrap)(withRouter);
