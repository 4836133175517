import React, { useMemo } from "react";

export const Lazy = ({ component, fallback, ...otherProps }) => {
  const Component = useMemo(
    () =>
      typeof component === "string"
        ? React.lazy(() => import(`./${component}.js`))
        : component,
    [component]
  );
  return (
    <React.Suspense fallback={fallback}>
      <Component {...otherProps} />
    </React.Suspense>
  );
};

export const Conditional = ({ children, condition }) => {
  if (condition) {
    if (children && {}.toString.call(children) === "[object Function]") {
      return children();
    } else {
      return children;
    }
  } else {
    return null;
  }
};
