import React from 'react';
import PropTypes from 'prop-types';
import { DialogFooter, DialogButton } from '@material/react-dialog';
import './basic-confirmation.css';

class BasicConfirmation extends React.Component {
  static propTypes = {
    dismiss: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
  };

  render() {
    return (
      <DialogFooter className="basic-footer">
        <DialogButton action="dismiss">{this.props.dismiss}</DialogButton>
        <DialogButton action="accept" isDefault>
          {this.props.accept}
        </DialogButton>
      </DialogFooter>
    );
  }
}
export default BasicConfirmation;
