import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "../slice";
import { thunkTypes } from "../thunktypes";

export const listBanks = createAsyncThunk(
  thunkTypes.LIST_BANKS,
  async (params, { dispatch }) => {
    const banks = [
      {
        bankId: "BRRDDOSD",
        swift: "BRRDDOSD",
        country: "DO",
        name: "Banreservas",
      },
      {
        bankId: "INDLGTGC",
        swift: "INDLGTGC",
        country: "GT",
        name: "Banco Industrial",
      },
      {
        bankId: "BRRLGTGC",
        swift: "BRRLGTGC",
        country: "GT",
        name: "Banrural",
      },
      {
        bankId: "GTCOGTGC",
        swift: "GTCOGTGC",
        country: "GT",
        name: "GyT",
      },
      {
        bankId: "AMCNGTGT",
        swift: "AMCNGTGT",
        country: "GT",
        name: "BAC",
      },
      {
        bankId: "BMILHNTE",
        swift: "BMILHNTE",
        country: "HN",
        name: "BAC - HN",
      }
    ];
    await dispatch(actions.mergeBanks(banks));
    return banks;
  }
);
