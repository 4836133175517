import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '@material/react-button';

import '@material/react-button/dist/button.css';
import CommonDialog from './dialog/dialog';

class ConfirmButton extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onDismiss: PropTypes.func,
    t: PropTypes.func,
  };

  state = {
    open: false,
    onConfirm: () => {},
    onDismiss: () => {},
  };

  render() {
    const { onConfirm, onDismiss, t, text } = this.props;
    return [
      <CommonDialog
        dialogType="confirm"
        dismiss={t('Cancel')}
        accept={t('Continue')}
        title={t('Are you sure you want to continue?')}
        open={this.state.open}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        key="comm-dialog"
      />,
      <Button key="button" onClick={this.onClick.bind(this)}>
        {text}
      </Button>,
    ];
  }

  onClick() {
    this.setState({ open: true });
  }
}

export default withTranslation()(ConfirmButton);
