import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./progress.css";

class Progress extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.bool.isRequired,
  };
  render() {
    const { children, text, className, loading } = this.props;
    return (
      <div className={classNames("progress", className, { loading })}>
        {!loading && children()}
        <div className={"progress-mask"}>
          <div className="center">
            {text && <div className="text">{text}</div>}
            <div className="icon">sync</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Progress;
