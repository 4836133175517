import { Conditional } from "../../components";
import { Button } from "../../controls/button/button";
import { Icon } from "../../controls/icon/icon";
import { TextTrim } from "../../globals/texttrim/texttrim";
import { Section } from "../../layout/layout";
import "./attributes.css";

export const Attributes = ({ payment, user }) => {
  const onViewLoanButtonClick = () => {
    window.open(`/loans/details/${payment.loanId}`, "_blank");
  };
  const onViewRequestButtonClick = () => {
    window.open(`/requests/details/${payment.loanRequestId}`, "_blank");
  };
  const onViewUserButtonClick = () => {
    window.open(`/users/details/${payment.userId}`, "_blank");
  };
  const isForActiveLoan =
    payment &&
    payment.loanRequestId &&
    user &&
    user.state &&
    user.state.activeLoanId &&
    payment.loanRequestId === user.state.activeLoanId;
  return (
    <Section className="attributes">
      <dl>
        <div>
          <dt>
            <span>ID de usuario</span>
          </dt>
          <dd>
            <code>
              <TextTrim maxLength={40} moreText="&hellip;">
                {payment.userId}
              </TextTrim>
              <Button onClick={onViewUserButtonClick}>
                <Icon icon="open_in_new" />
              </Button>
            </code>
          </dd>
        </div>
        <div>
          <dt>
            <span>ID de crédito</span>
            <Conditional condition={isForActiveLoan}>
              <span className="active-loan">Active</span>
            </Conditional>
          </dt>
          <dd>
            <code>
              <TextTrim maxLength={40} moreText="&hellip;">
                {payment.loanId}
              </TextTrim>
              <Button onClick={onViewLoanButtonClick}>
                <Icon icon="open_in_new" />
              </Button>
            </code>
          </dd>
        </div>
        <div>
          <dt>
            <span>ID de solicitud</span>
          </dt>
          <dd>
            <code>
              <TextTrim maxLength={40} moreText="&hellip;">
                {payment.loanRequestId}
              </TextTrim>
              <Button onClick={onViewRequestButtonClick}>
                <Icon icon="open_in_new" />
              </Button>
            </code>
          </dd>
        </div>
        <div>
          <dt>
            <span>ID de pago</span>
          </dt>
          <dd>
            <code>
              <TextTrim maxLength={30} moreText="&hellip;">
                {payment.paymentId}
              </TextTrim>
            </code>
          </dd>
        </div>
      </dl>
    </Section>
  );
};
