import React from "react";
import PropTypes from "prop-types";

class ListItemGraphic extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };
  render() {
    const { children } = this.props;
    return <div className="graphic">{children}</div>;
  }
}

export default ListItemGraphic;
