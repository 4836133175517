import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Button from "@material/react-button";

import Heading from "../../common/heading";
import ConfirmButton from "../confirmbutton";
import Card from "../card/card";
import CardContent from "../card/cardcontent";
import CardActions from "../card/cardactions";
import CardActionButtons from "../card/cardactionbuttons";
import CircleIcon from "../circle/circleicon";
import Fields from "./fields";
import { compose } from "../../../util/compose";

import "@material/react-button/dist/button.css";
import "@material/react-typography/dist/typography.css";

class FieldsCard extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    onUpdate: PropTypes.func,
    onDataChange: PropTypes.func,
  };

  static defaultProps = {
    subtitle: "",
    icon: "description",
    editable: false,
    onChange: (update, data) => {},
    onUpdate: (update) => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this.mergeFieldData(),
      update: null,
      readonly: true,
      prevValue: null,
    };
  }

  componentDidUpdate(prev) {
    if (prev.data !== this.props.data) {
      this.setState({ data: this.mergeFieldData() });
    }
  }

  mergeFieldData() {
    const data = {};
    Object.keys(this.props.fields).forEach((key) => {
      try {
        const field = this.props.fields[key];
        const defaultValue = field.hasOwnProperty("defaultValue")
          ? field.defaultValue
          : "";
        const value = this.props.data[field.key] || defaultValue;

        const isVisible = value ? true : field.visible ?? true;
        if (isVisible) {
          data[key] = Object.assign({}, { value }, field);
        }
      } catch (error) {
        // console.log('error', error);
      }
    });
    return data;
  }

  onChange(value, data) {
    const update = this.state.update || {};
    update[data.key].value = value;
    update[data.key].updated = true;
    this.setState({ prevValue: this.state.data.status });
    this.setState({ update });
    if (this.props.onDataChange) {
      this.props.onDataChange(update);
    }
  }

  onCancelEditClick() {
    this.setState({ update: null, readonly: true });
  }

  onEditClick() {
    const update = {};
    Object.keys(this.state.data).forEach((key) => {
      update[key] = Object.assign({}, this.state.data[key]);
    });
    this.setState({ update, readonly: false });
  }

  onSaveConfirm() {
    this.props.onUpdate(this.state.update);
    this.setState({ update: null, readonly: true });
  }

  render() {
    const { editable, icon, t, title, subtitle } = this.props;
    const { data, readonly, update, prevValue } = this.state;
    return (
      <Card>
        <Heading
          title={title}
          subtitle={subtitle}
          graphic={<CircleIcon light={true} icon={icon} />}
        />
        <CardContent>
          <Fields
            data={readonly ? data : update}
            readonly={readonly}
            onChange={this.onChange.bind(this)}
            prevValue={prevValue}
          />
        </CardContent>
        {editable ? (
          <CardActions>
            {readonly ? (
              <CardActionButtons>
                <Button onClick={this.onEditClick.bind(this)}>
                  {t("Edit")}
                </Button>
              </CardActionButtons>
            ) : (
              <CardActionButtons>
                <Button onClick={this.onCancelEditClick.bind(this)}>
                  {t("Cancel")}
                </Button>
                <ConfirmButton
                  text={t("Save")}
                  onConfirm={this.onSaveConfirm.bind(this)}
                />
              </CardActionButtons>
            )}
          </CardActions>
        ) : null}
      </Card>
    );
  }
}

export default compose(FieldsCard)(withTranslation());
