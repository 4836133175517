import classNames from "classnames";
import { Conditional } from "../../components";

export const TextInputLayout = ({ children, className, ...otherProps }) => {
  return (
    <div {...otherProps} className={classNames(className, "text-input-layout")}>
      {children}
    </div>
  );
};

export const TextInputSupportingText = ({
  children,
  className,
  errorMessage,
}) => {
  return (
    <Conditional condition={children || errorMessage}>
      <div className={classNames(className, "supporting-text")}>
        {children || errorMessage}
      </div>
    </Conditional>
  );
};

export const TextInputErrorMessage = ({
  children,
  className,
  errorMessage,
  ...otherProps
}) => {
  return (
    <Conditional condition={children || errorMessage}>
      <div {...otherProps} className={classNames(className, "error-message")}>
        {children || errorMessage}
      </div>
    </Conditional>
  );
};
