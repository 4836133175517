import { withRouter } from "react-router";
import { compose } from "../../util/compose";
import { List } from "./list/list";
import { Payment } from "./payment/payment";
import "./payments.css";

const PaymentsWrap = ({ match, history }) => {
  const paymentId = match.params.paymentId ? match.params.paymentId : "";
  const onItemClick = (destination) => {
    history.push(destination);
  };
  return (
    <div className="payments">
      <List paymentId={paymentId} onItemClick={onItemClick} />
      <Payment paymentId={paymentId} />
    </div>
  );
};

export const Payments = compose(PaymentsWrap)(withRouter);
