export const fields = {
  first_name: {
    key: "first_name",
    label: "Name",
    icon: "lock"
  },
  last_name: {
    key: "last_name",
    label: "Last name",
    icon: "lock"
  },
  id_number: {
    key: "id_number",
    label: "DPI",
    icon: "lock"
  },
  email: {
    key: "email",
    label: "Email",
    icon: "lock",
    readonly: true
  },
  phone: {
    key: "phone",
    label: "Phone number",
    icon: "phone",
    readonly: true
  },
  gender: {
    key: "gender",
    label: "Gender",
    icon: "lock",
    readonly: true,
    type: "select",
    options: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" }
    ]
  },
  birthdate: {
    key: "birthdate",
    label: "Birthdate",
    icon: "lock",
    readonly: true
  },
  tax_id: {
    key: "tax_id",
    label: "NIT",
    icon: "lock",
    readonly: true
  },
  civil_status: {
    key: "civil_status",
    label: "Civil status",
    icon: "lock",
    readonly: true,
    type: "select",
    options: [
      { label: "Single", value: "single" },
      { label: "Married", value: "married" },
      { label: "Divorced", value: "divorced" },
      { label: "United", value: "united" }
    ]
  }
};
