import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class TagsTag extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    tag: PropTypes.object.isRequired,
  };
  static defaultProps = {
    className: "",
    onTag: (tag) => {},
  };
  render() {
    const { tag, onTag } = this.props;
    return (
      <div
        className={classNames("tag", tag.className)}
        onClick={() => onTag(tag)}
      >
        {tag.icon && <div className="icon">{tag.icon}</div>}
        {(tag.title || tag.text) && (
          <div className="content">
            {tag.title && <div className="title">{tag.title}</div>}
            {tag.text && <div className="text">{tag.text}</div>}
          </div>
        )}
      </div>
    );
  }
}

export default TagsTag;
