import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBankAccount } from "../../../../../redux/users/selectors";
import { getBankAccount } from "../../../../../redux/users/thunks/getbankaccount";
import FieldsCard from "../../../../common/fields/fieldscard";
import { fields } from "./fields";

export const BankAccount = ({ userId }) => {
  const dispatch = useDispatch();
  const bankAccount = useSelector(selectBankAccount(userId));
  const [hasToGetBankAccounts, setHasToGetBankAccounts] = useState(true);
  useEffect(() => {
    if (userId && hasToGetBankAccounts) {
      setHasToGetBankAccounts(false);
      dispatch(getBankAccount({ userId }));
    }
  }, [dispatch, hasToGetBankAccounts, setHasToGetBankAccounts, userId]);
  return (
    <FieldsCard
      data={bankAccount || {}}
      fields={fields}
      icon="account_balance_wallet"
      title="Perfil bancario"
    />
  );
};
