import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import TextField, { Input } from "@material/react-text-field";
import MaterialIcon from "@material/react-material-icon";

import { compose } from "../../../../util/compose";

import "@material/react-text-field/dist/text-field.css";
import "@material/react-material-icon/dist/material-icon.css";

const styles = {
  textField: {
    width: "100%"
  }
};

class Link extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.string
  };

  static defaultProps = {
    icon: "open_in_browser",
    url: ""
  };

  render() {
    const { icon, label, t, value } = this.props;
    return (
      <TextField
        style={styles.textField}
        label={t(label)}
        onTrailingIconSelect={this.onClick.bind(this)}
        trailingIcon={<MaterialIcon role="button" icon={icon} />}
      >
        <Input value={value || ""} />
      </TextField>
    );
  }

  onClick() {
    window.open(this.props.value, "_blank");
  }
}

export default compose(Link)(withRouter, withTranslation());
