import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, withRouter } from "react-router-dom";

import Details from "./details/details";
import Loan from "./loan";
import { compose } from "../../../util/compose";

/**
 * @type {React.Component}
 */
class Router extends React.Component {
  /**
   * @type {Object}
   */
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  /**
   * @param {Object} loan
   */
  onLoan(loan) {
    this.props.history.push(`/loans/details/${loan.loan_request_id}`);
  }
  /**
   * @return {React.ReactNode}
   */
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}/details/:loanRequestId`}>
          <Details />
        </Route>
        <Route path={match.path}>
          <Loan />
        </Route>
      </Switch>
    );
  }
}

export default compose(Router)(withRouter);
