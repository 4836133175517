import React from "react";
import i18n from "../../i18n/i18n";
import Context from "./context";
import Moment from "moment";
import "moment/locale/es";

class Language extends React.Component {
  constructor(props) {
    super(props);
    this.state = { locale: this.readLocale() };
  }
  readLocale() {
    return this.writeLocale(localStorage.getItem("locale") || "es");
  }
  writeLocale(locale) {
    localStorage.setItem("locale", locale);
    i18n.changeLanguage(locale);
    return locale;
  }
  changeLocale(locale) {
    console.log("changeLocale", locale);
    Moment.locale(locale);
    this.writeLocale(locale);
    this.setState({ locale });
  }
  render() {
    return (
      <Context.Provider
        value={{
          locale: this.state.locale,
          changeLocale: this.changeLocale.bind(this),
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Language;

export function withLanguage(Component) {
  return (props) => (
    <Context.Consumer>
      {(language) => <Component language={language} {...props} />}
    </Context.Consumer>
  );
}
