class ObjectUtils {
  static navigate(object, path) {
    if (!object) {
      return null;
    }
    if (typeof path !== "string" || typeof object !== "object") {
      return null;
    }
    const keys = path.split(".");
    let value = object;
    for (const key of keys) {
      if (value[key]) {
        value = value[key];
      } else {
        return null;
      }
    }
    return value;
  }
}

export default ObjectUtils;
