export const mergeItemByIndex = (mapIndexValue, payload, state = {}) => {
  if (!mapIndexValue || !payload) {
    return;
  }
  const indexValue = mapIndexValue(payload);
  if (!indexValue) {
    return;
  }
  const prev = state[indexValue] ? state[indexValue] : {};
  return {
    ...state,
    [indexValue]: { ...prev, ...payload },
  };
};

export const mergeItemsByIndex = (mapIndexValue, payload, state = {}) => {
  if (!mapIndexValue || !payload || !Array.isArray(payload)) {
    return;
  }
  return payload.reduce(
    (prev, item) => mergeItemByIndex(mapIndexValue, item, prev),
    state ? state : {}
  );
};

export const onFulfilled = (state, action) => {
  const pending =
    state.pending && state.pending.length
      ? state.pending.filter((id) => id !== action.meta.requestId)
      : [];
  state.errorMessage = "";
  state.isPending = pending.length > 0;
  state.isRejected = false;
  if (pending.length > 0) {
    state.pending = pending;
  } else {
    delete state.pending;
  }
};

export const onPending = (state, action) => {
  const pending =
    state.pending && state.pending.length
      ? [...state.pending, action.meta.requestId]
      : [action.meta.requestId];
  state.isPending = pending.length > 0;
  state.isRejected = false;
  if (pending.length > 0) {
    state.pending = pending;
  } else {
    delete state.pending;
  }
};

export const onRejected = (state, action) => {
  const pending =
    state.pending && state.pending.length
      ? state.pending.filter((id) => id !== action.meta.requestId)
      : [];
  state.errorMessage = action.error.message || action.error.name;
  state.isPending = pending.length > 0;
  state.isRejected = true;
  if (pending.length > 0) {
    state.pending = pending;
  } else {
    delete state.pending;
  }
};

export const isFulfilled = (action, matchers) => {
  return isLifeCycle(action.type, matchers, "/fulfilled");
};

export const isPending = (action, matchers) => {
  return isLifeCycle(action.type, matchers, "/pending");
};

export const isRejected = (action, matchers) => {
  return isLifeCycle(action.type, matchers, "/rejected");
};

const isLifeCycle = (actionType, matchers, lifeCycle) => {
  const types = Array.isArray(matchers) ? matchers : [matchers];
  for (const type of types) {
    if (actionType.startsWith(type) && actionType.endsWith(lifeCycle)) {
      return true;
    }
  }
  return false;
};
