import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import Progress from "../progress/progress";
import ListItemGroup from "./item/itemgroup";
import ArrayUtils from "../../../util/arrayutils";
import { compose } from "../../../util/compose";

class ListItems extends React.Component {
  static propTypes = {
    adapter: PropTypes.object.isRequired,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    items: PropTypes.array,
    onItem: PropTypes.func,
    onUpdate: PropTypes.func,
    t: PropTypes.func.isRequired,
  };
  static defaultProps = {
    loading: false,
    filters: {},
    onItem: (item) => {},
    onUpdate: () => {},
  };
  componentDidUpdate() {
    if (this.props.items) {
      this.props.onUpdate(this.props.items.length);
    }
  }

  render() {
    const isEmpty = this.props.items && this.props.items.length === 0;
    return (
      <Progress
        className={classNames("list-items", { "list-empty": isEmpty })}
        loading={!this.props.items && this.props.loading}
        text={this.props.t("Loading items")}
      >
        {() => {
          if (isEmpty) {
            return (
              <div className="center">
                <span className="text">
                  {this.props.t("No items found")} xx
                </span>
                <span className="icon">cloud_off</span>
              </div>
            );
          }
          const list = [];
          const buffer = {};
          for (const itemKey in this.props.items) {
            const item = this.props.items[itemKey];
            for (const groupKey in this.props.adapter.groups) {
              let make = true;
              const group = this.props.adapter.groups[groupKey];
              for (const filter in group.filters) {
                const rule = group.filters[filter];
                const selected = this.props.filters[filter] || [];
                if (rule === "*") {
                  continue;
                }
                if (selected.length && ArrayUtils.containsAll(rule, selected)) {
                  continue;
                }
                make = false;
                break;
              }
              if (make && group.onGroup(item, buffer[groupKey])) {
                buffer[groupKey] = item;
                list.push(
                  <ListItemGroup
                    key={`group-${groupKey}-${itemKey}`}
                    className={groupKey}
                    icon={group.icon}
                    text={group.onText(item)}
                  />
                );
              }
            }
            list.push(this.props.children(item, `item-${itemKey}`));
          }
          return list;
        }}
      </Progress>
    );
  }
}

export default compose(ListItems)(withTranslation());
