import React from "react";
import PropTypes from "prop-types";
import { DialogTitle } from "@material/react-dialog";
import './basic-title.css';

class BasicTitle extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <DialogTitle className="basic-title">{this.props.title}</DialogTitle>
    )
  }
}
export default BasicTitle;
