import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { actions } from "../slice";
import { thunkTypes } from "../thunktypes";

const REVEAL_TIME = 50;

export const revealModalView = createAsyncThunk(
  thunkTypes.REVEAL_MODAL_VIEW,
  async (params, { dispatch }) => {
    const modalView = { ...params, id: uuidv4(), isVisible: false };
    await dispatch(actions.mergeModalView(modalView));
    setTimeout(() => {
      /* make visible after delay */
      dispatch(actions.mergeModalView({ id: modalView.id, isVisible: true }));
    }, REVEAL_TIME);
    return modalView.id;
  }
);
