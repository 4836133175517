import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import List from "../../common/list/list";
import ListItems from "../../common/list/listitems";
import ListToolbar from "../../common/list/toolbar/toolbar";
import LoanListItem from "./item/item";
import { compose } from "../../../util/compose";

class LoanList extends React.Component {
  static propTypes = {
    adapter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loans: PropTypes.array,
    loading: PropTypes.bool,
    selected: PropTypes.string,
    onReload: PropTypes.func,
  };
  static defaultProps = {
    loading: false,
    onReload: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      tools: [],
      filters: {
        sort: ["recent"],
      },
      sorted: null,
    };
  }
  componentDidMount() {
    if (!this.props.loading && !this.props.loans) {
      this.props.onReload();
    }
  }
  filter() {
    console.log("LoanList.filter");
    if (!this.props.loans) {
      return null;
    }
    const adapter = this.props.adapter;
    const filters = this.state.filters;
    let sorted = this.props.loans;
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filters[filter].length) {
        sorted = adapter.filters[filter].onFilter(sorted, filters[filter]);
      }
    });
    return sorted;
  }
  onReload() {
    this.props.onReload();
  }
  onFilter(filters) {
    this.setState({ sorted: null, filters });
  }
  onLoan(loan) {
    this.props.history.push(`/loans/details/${loan.loan_request_id}`);
  }
  render() {
    return (
      <List className="loans">
        <ListToolbar
          filters={this.props.adapter.filters}
          loading={this.props.loading}
          selected={{ filters: this.state.filters }}
          onFilter={this.onFilter.bind(this)}
          onReload={this.onReload.bind(this)}
        />
        <ListItems
          adapter={this.props.adapter}
          loading={this.props.loading}
          filters={this.state.filters}
          items={this.filter()}
        >
          {(loan, key) => (
            <LoanListItem
              key={key}
              loan={loan}
              selected={loan.loan_id === this.props.selected}
              onSelect={this.onLoan.bind(this)}
            />
          )}
        </ListItems>
      </List>
    );
  }
}

export default compose(LoanList)(withRouter);
