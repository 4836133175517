import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Circle from "./circle";
import MaterialIcon from "@material/react-material-icon";
import "@material/react-material-icon/dist/material-icon.css";

import "./circleicon.css";

class CircleIcon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    style: PropTypes.object,
    light: PropTypes.bool,
    dark: PropTypes.bool,
    small: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    light: false,
    dark: false,
    small: false,
    icon: "settings",
    style: {},
  };

  render() {
    const { className, icon, light, dark, small, style } = this.props;
    return (
      <Circle
        className={classNames("icon", className)}
        light={light}
        dark={dark}
        small={small}
        style={style}
      >
        <MaterialIcon icon={icon} />
      </Circle>
    );
  }
}

export default CircleIcon;
