import classNames from "classnames";

export const TextTrim = ({
  children,
  className,
  maxLength = 18,
  moreText = "...",
  text,
}) => {
  const inputText = children || text || "";
  const trimedText =
    inputText.length > maxLength
      ? `${inputText.substring(0, maxLength)}${moreText}`
      : inputText;
  return (
    <span title={inputText} className={classNames(className, "trimmed-text")}>
      {trimedText}
    </span>
  );
};
