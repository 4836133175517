import React from "react";
import "react-awesome-lightbox/build/style.css";
import { withTranslation } from "react-i18next";
import { compose } from "../../util/compose";
import AppLayout from "../applayout/applayout";
import Auth from "../auth/auth";
import "../common/common.css";
import DataLayer from "../datalayer/datalayer";
import { ModalViews } from "../globals/modalviews/modalviews";
import Language from "../language/language";
import Context from "./context";
import AppError from "./error/error";
import AppSnackbar from "./snackbar/snackbar";

class App extends React.Component {
  state = {
    snackbar: null,
    error: null,
  };
  onError(error) {
    if (typeof error === "string" || error instanceof String) {
      error = { text: error };
    }
    this.setState({ error });
  }
  onErrorClose() {
    this.setState({ error: null });
  }
  onSnackbar(snackbar) {
    if (typeof snackbar === "string" || snackbar instanceof String) {
      snackbar = { text: snackbar };
    }
    this.setState({ snackbar });
  }
  onSnackbarClose() {
    this.setState({ snackbar: null });
  }
  render() {
    const { snackbar, error } = this.state;
    return (
      <Context.Provider
        value={{
          onError: this.onError.bind(this),
          onSnackbar: this.onSnackbar.bind(this),
        }}
      >
        <Auth>
          <Language>
            <DataLayer>
              <AppLayout />
              <ModalViews />
            </DataLayer>
          </Language>
        </Auth>
        <AppError data={error} onClose={this.onErrorClose.bind(this)} />
        <AppSnackbar
          data={snackbar}
          onClose={this.onSnackbarClose.bind(this)}
        />
      </Context.Provider>
    );
  }
}

export default compose(App)(withTranslation());

export function withApp(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <Context.Consumer>
      {(app) => <Component app={app} {...props} />}
    </Context.Consumer>
  );
}
