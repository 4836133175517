import classNames from "classnames";

export const ItemCount = ({
  className,
  count,
  plural = "items",
  singular = "item",
}) => {
  const countNumber = count ? parseInt(count) : 0;
  return (
    <span className={classNames(className, "item-count")}>
      {countNumber && countNumber === 1
        ? `${countNumber} ${singular}`
        : `${countNumber} ${plural}`}
    </span>
  );
};
