import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class DrawerContent extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
  };
  static defaultProps = {
    children: null,
    className: '',
  };
  render() {
    return (
      <div className={classNames(this.props.className, 'drawer-content')}>
        {this.props.children}
      </div>
    );
  }
}

export default DrawerContent;
