import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class CardActionIcon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    styles: PropTypes.object,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    className: "",
    icon: "settings",
    styles: {},
    onClick: () => {},
  };

  render() {
    const { className, icon, styles, onClick } = this.props;
    return (
      <i
        style={styles}
        className={classNames("card-action-icon", className)}
        onClick={onClick}
      >
        {icon}
      </i>
    );
  }
}

export default CardActionIcon;
