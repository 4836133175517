import { currencies } from "./currency";

export const banks = {
  BAGUGTGC: `BAGUGTGC`,
  // BAGUGTGB: `BAGUGTGB`,
  BRRDDOSD: "BRRDDOSD",
  CHNAGTGC: `CHNAGTGC`,
  TRAJGTGC: `TRAJGTGC`,
  BINMGTGC: `BINMGTGC`,
  INDLGTGC: `INDLGTGC`,
  BRRLGTGC: `BRRLGTGC`,
  DIBIGTGC: `DIBIGTGC`,
  CTRCGTGC: `CTRCGTGC`,
  CITIGTGC: `CITIGTGC`,
  VIVBGTGT: `VIVBGTGT`,
  FCOHGTGC: `FCOHGTGC`,
  BPRCGTGC: `BPRCGTGC`,
  AMCNGTGT: `AMCNGTGT`,
  AGROGTGC: `AGROGTGC`,
  GTCOGTGC: `GTCOGTGC`,
  BDCTGTGT: `BDCTGTGT`,
  FIVNGTGT: `FIVNGTGT`,
  AZTKGTGC: `AZTKGTGC`,
  BMILHNTE: `BMILHNTE`,
  ____FINANCIERA_DE_OCCIDENTE: `____FINANCIERA_DE_OCCIDENTE`,
};

export const bankLabels = {
  [banks.BAGUGTGC]: `Banco de Guatemala`,
  [banks.BRRDDOSD]: `Banreservas`,
  [banks.CHNAGTGC]: `CHN`,
  [banks.TRAJGTGC]: `Bantrab`,
  [banks.BINMGTGC]: `Banco Inmobiliario`,
  [banks.INDLGTGC]: `Banco Industrial`,
  [banks.BRRLGTGC]: `Banrural`,
  [banks.DIBIGTGC]: `Banco Internacional`,
  [banks.CTRCGTGC]: `Banco Reformador`,
  [banks.CITIGTGC]: `Citibank`,
  [banks.VIVBGTGT]: `Vivi Banco`,
  [banks.FCOHGTGC]: `Banco Ficohsa`,
  [banks.BPRCGTGC]: `Banco Promérica`,
  [banks.AMCNGTGT]: `BAC`,
  [banks.AGROGTGC]: `Banco Agromercantil`,
  [banks.GTCOGTGC]: `G&T`,
  [banks.BDCTGTGT]: `Banco De Crédito`,
  [banks.FIVNGTGT]: `Banco INV`,
  [banks.AZTKGTGC]: `Banco Azteca`,
  [banks.BMILHNTE]: `BAC - HN`,
  [banks.____FINANCIERA_DE_OCCIDENTE]: `Financiera De Occidente`,
};

export const bankOptions = Object.values(banks).map((value) => ({
  value,
  label: bankLabels[value],
}));

export const bankCurrencies = {
  [banks.BAGUGTGC]: currencies.GTQ,
  [banks.BRRDDOSD]: currencies.DOP,
  [banks.CHNAGTGC]: currencies.GTQ,
  [banks.TRAJGTGC]: currencies.GTQ,
  [banks.BINMGTGC]: currencies.GTQ,
  [banks.INDLGTGC]: currencies.GTQ,
  [banks.BRRLGTGC]: currencies.GTQ,
  [banks.DIBIGTGC]: currencies.GTQ,
  [banks.CTRCGTGC]: currencies.GTQ,
  [banks.CITIGTGC]: currencies.GTQ,
  [banks.VIVBGTGT]: currencies.GTQ,
  [banks.FCOHGTGC]: currencies.GTQ,
  [banks.BPRCGTGC]: currencies.GTQ,
  [banks.AMCNGTGT]: currencies.GTQ,
  [banks.AGROGTGC]: currencies.GTQ,
  [banks.GTCOGTGC]: currencies.GTQ,
  [banks.BDCTGTGT]: currencies.GTQ,
  [banks.FIVNGTGT]: currencies.GTQ,
  [banks.AZTKGTGC]: currencies.GTQ,
  [banks.BMILHNTE]: currencies.L,
  [banks.____FINANCIERA_DE_OCCIDENTE]: currencies.GTQ,
};
