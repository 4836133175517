const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const dayNames = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

export const localeByCountry = {
  DO: "en-ES",
  GT: "en-ES",
};

export const timezoneByCountry = {
  DO: "America/Santo_Domingo",
  GT: "America/Guatemala",
};

const parseDate = (dateTime) => {
  let date;
  if (dateTime) {
    return new Date(dateTime);
  } else {
    date = new Date();
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  }
};

export const dateFormats = {
  MMMM_D: "MMMM D",
  MMMM_DD: "MMMM DD",
  MMMM_YYYY: "MMMM YYYY",
  MMMM_D_h_mm_a: "MMMM D, h:mm a",
  MMMM_D_YYYY: "MMMM D, YYYY",
  MMMM_D_YYYY_H_mm: "MMMM D, YYYY H:mm",
  YYYYMMMMDD: "YYYYMMMMDD",
  YYYY_MMMM_DD: "YYYY-MMMM-DD",
  MM_D_YYYY: "MM D, YYYY",
  h_mm_a: "h:mm a",
  D_OF_MMMM: "D [de] MMMM",
  D_OF_MMMM_OF_YYYY: "D [de] MMMM [del] YYYY",
  DD_MM_YYYY_h_mm_ss_a: "DD/MM/YYYY, h:mm:ss a",
};

export const formatDate = (dateTime, format, keepTimezone) => {
  switch (format) {
    case dateFormats.MMMM_D: {
      const date = getDayOfMonth(dateTime);
      const month = getMonthName(dateTime);
      return `${month} ${date}`;
    }
    case dateFormats.MMMM_DD: {
      const date = `${getDayOfMonth(dateTime)}`.padStart(2, "0");
      const month = getMonthName(dateTime);
      return `${month} ${date}`;
    }
    case dateFormats.MMMM_YYYY: {
      const year = getYear(dateTime);
      const month = getMonthName(dateTime);
      return `${month} ${year}`;
    }
    case dateFormats.MMMM_D_h_mm_a: {
      const date = getDayOfMonth(dateTime, keepTimezone);
      const month = getMonthName(dateTime, keepTimezone);
      const hours = getHours(dateTime, keepTimezone) % 12;
      const minutes = `${getMinutes(dateTime)}`.padStart(2, "0");
      const period = getPeriod(dateTime);
      return `${month} ${date}, ${hours}:${minutes} ${period}`;
    }
    case dateFormats.MMMM_D_YYYY_H_mm: {
      const year = getYear(dateTime, keepTimezone);
      const date = getDayOfMonth(dateTime, keepTimezone);
      const month = getMonthName(dateTime, keepTimezone);
      const hours = getHours(dateTime, keepTimezone);
      const minutes = `${getMinutes(dateTime)}`.padStart(2, "0");
      return `${month} ${date}, ${year} ${hours}:${minutes}`;
    }
    case dateFormats.MMMM_D_YYYY: {
      const year = getYear(dateTime);
      const date = getDayOfMonth(dateTime);
      const month = getMonthName(dateTime);
      return `${month} ${date}, ${year}`;
    }
    case dateFormats.YYYYMMMMDD: {
      const year = getYear(dateTime);
      const month = `${getMonth(dateTime)}`.padStart(2, "0");
      const date = `${getDayOfMonth(dateTime)}`.padStart(2, "0");
      return `${year}${month}${date}`;
    }
    case dateFormats.YYYY_MMMM_DD: {
      const year = getYear(dateTime);
      const month = `${getMonth(dateTime)}`.padStart(2, "0");
      const date = `${getDayOfMonth(dateTime)}`.padStart(2, "0");
      return `${year}-${month}-${date}`;
    }
    case dateFormats.MM_D_YYYY: {
      const year = getYear(dateTime);
      const month = `${getMonthName(dateTime)}`.substring(0, 3);
      const date = `${getDayOfMonth(dateTime)}`;
      return `${month} ${date}, ${year}`;
    }
    case dateFormats.h_mm_a: {
      const hours = getHours(dateTime, keepTimezone) % 12;
      const minutes = `${getMinutes(dateTime)}`.padStart(2, "0");
      const period = getPeriod(dateTime);
      return `${hours}:${minutes} ${period}`;
    }
    case dateFormats.D_OF_MMMM: {
      const month = getMonthName(dateTime);
      const date = getDayOfMonth(dateTime);
      return `${date} de ${month}`;
    }
    case dateFormats.D_OF_MMMM_OF_YYYY: {
      const year = getYear(dateTime);
      const month = getMonthName(dateTime);
      const date = `${getDayOfMonth(dateTime)}`.padStart(2, "0");
      return `${date} de ${month} del ${year}`;
    }
    case dateFormats.DD_MM_YYYY_h_mm_ss_a: {
      const year = getYear(dateTime, keepTimezone);
      const month = `${getMonth(dateTime, keepTimezone)}`.padStart(2, "0");
      const date = `${getDayOfMonth(dateTime, keepTimezone)}`.padStart(2, "0");
      const hours = getHours(dateTime, keepTimezone) % 12;
      const minutes = `${getMinutes(dateTime)}`.padStart(2, "0");
      const seconds = `${getSeconds(dateTime)}`.padStart(2, "0");
      const period = getPeriod(dateTime);
      return `${date}/${month}/${year} ${hours}:${minutes}:${seconds} ${period}`;
    }
    default: {
      const year = getYear(dateTime, keepTimezone);
      const month = `${getMonth(dateTime, keepTimezone)}`.padStart(2, "0");
      const date = `${getDayOfMonth(dateTime, keepTimezone)}`.padStart(2, "0");
      const hours = `${getHours(dateTime, keepTimezone)}`.padStart(2, "0");
      const minutes = `${getMinutes(dateTime)}`.padStart(2, "0");
      const seconds = `${getSeconds(dateTime)}`.padStart(2, "0");
      return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.000Z`;
    }
  }
};

export const getEndOfMonth = (dateTime) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth() + 1,
      0,
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    )
  );
  return formatDate(utc);
};

export const getMiddleOfMonth = (dateTime) => {
  return setDayOfMonth(dateTime, 15);
};

export const getDayName = (dateTime) => {
  const date = parseDate(dateTime);
  const dayOfWeek = date.getDay();
  return dayNames[dayOfWeek];
};

export const getDayOfMonth = (dateTime, keepTimezone) => {
  const date = parseDate(dateTime);
  return keepTimezone ? date.getDate() : date.getUTCDate();
};

export const getDayOfWeek = (dateTime) => {
  const date = parseDate(dateTime);
  return date.getUTCDay();
};

export const getDaysInMonth = (dateTime) => {
  const endOfMonth = getEndOfMonth(dateTime);
  return getDayOfMonth(endOfMonth);
};

export const getDiffInDays = (dateA, dateB) => {
  const startOfDayA = getStartOfDay(dateA);
  const startOfDayB = getStartOfDay(dateB);
  const timeA = new Date(startOfDayA).getTime();
  const timeB = new Date(startOfDayB).getTime();
  return (timeB - timeA) / (1000 * 3600 * 24);
};

export const getDiffInHours = (dateA, dateB) => {
  const startOfHourA = getStartOfHour(dateA);
  const startOfHourB = getStartOfHour(dateB);
  const timeA = new Date(startOfHourA).getTime();
  const timeB = new Date(startOfHourB).getTime();
  return (timeB - timeA) / (1000 * 3600);
};

export const getHours = (dateTime, keepTimezone) => {
  const date = parseDate(dateTime);
  return keepTimezone ? date.getHours() : date.getUTCHours();
};

export const getMinutes = (dateTime) => {
  const date = parseDate(dateTime);
  return date.getUTCMinutes();
};

export const getMonth = (dateTime, keepTimezone) => {
  const date = parseDate(dateTime);
  return keepTimezone ? date.getMonth() + 1 : date.getUTCMonth() + 1;
};

export const getMonthName = (dateTime, keepTimezone) => {
  const monthOfYear = getMonth(dateTime, keepTimezone);
  return monthNames[monthOfYear - 1];
};

export const getNextDay = (dateTime) => {
  return setOffsetInDays(dateTime, 1);
};

export const getNextMonth = (dateTime) => {
  return setOffsetInMonths(dateTime, 1);
};

export const getPrevMonth = (dateTime) => {
  return setOffsetInMonths(dateTime, -1);
};

export const getPeriod = (dateTime) => {
  return getHours(dateTime) >= 12 ? "pm" : "am";
};

export const getSeconds = (dateTime) => {
  const date = parseDate(dateTime);
  return date.getUTCSeconds();
};

export const getStartOfDay = (dateTime) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
      0
    )
  );
  return formatDate(utc);
};

export const getStartOfMonth = (dateTime) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0, 0)
  );
  return formatDate(utc);
};

export const getStartOfHour = (dateTime, hour) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hour ? hour : date.getUTCHours(),
      0,
      0,
      0
    )
  );
  return formatDate(utc);
};

export const getYear = (dateTime, keepTimezone) => {
  const date = parseDate(dateTime);
  return keepTimezone ? date.getFullYear() : date.getUTCFullYear();
};

export const setDayOfMonth = (dateTime, dayOfMonth) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      dayOfMonth,
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    )
  );
  return formatDate(utc);
};

export const setOffsetInMonths = (dateTime, months) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth() + months,
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    )
  );
  return formatDate(utc);
};

export const setOffsetInDays = (dateTime, days) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate() + days,
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    )
  );
  return formatDate(utc);
};

export const setOffsetInHours = (dateTime, hours) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours() + hours,
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    )
  );
  return formatDate(utc);
};

export const setYear = (dateTime, year) => {
  const date = parseDate(dateTime);
  const utc = new Date(
    Date.UTC(
      year,
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    )
  );
  return formatDate(utc);
};

export const addHoursToDateString = (dateString, hoursToAdd) => {
  const date = new Date(dateString);
  date.setHours(date.getHours() + hoursToAdd);
  const formattedDate = date.toISOString();
  return formattedDate;
};
