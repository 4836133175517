import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "../slice";
import { thunkTypes } from "../thunktypes";

export const getBankAccount = createAsyncThunk(
  thunkTypes.GET_BANK_ACCOUNT,
  async (params, { dispatch, extra }) => {
    if (!params.userId) {
      throw new Error("invalid user id");
    }
    const result = await extra.userRepo.getBankAccount(params.userId);
    if (!result) {
      throw new Error("error loading bank statement");
    }
    const bankAccountNumber = result.number
      ? result.number.replace(/\d(?=(?:\D*\d){4})/g, "*")
      : "";
    const bankAccount = {
      bank: result.bank,
      createdAt: result.createdAt,
      name: result.name,
      number: bankAccountNumber,
      type: result.type,
      updatedAt: result.updatedAt,
      userId: result.userId,
    };
    await dispatch(actions.mergeBankAccount(bankAccount));
    return bankAccount;
  }
);
