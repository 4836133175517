import React from "react";
import PropTypes from "prop-types";
import ListItemTags from "../../../common/list/item/tags/tags";

class UserListItemTags extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };
  render() {
    const { user } = this.props;
    const t = (s) => s;
    const tags = [];
    user.state.active_loan_id &&
      tags.push({
        className: "active-loan",
        icon: "credit_card",
        text: t("Active loan"),
      });
    user.state.loan_review &&
      tags.push({
        className: "loan-review",
        icon: "flag",
        text: t("Loan review"),
      });
    user.state.active_loan_id &&
      user.state.release_number === 1 &&
      tags.push({
        className: "first-loan",
        icon: "looks_one",
        text: t("First loan"),
      });
    user.state.active_loan_id &&
      user.state.release_number === 2 &&
      tags.push({
        className: "second-loan",
        icon: "looks_two",
        text: t("Second loan"),
      });
    user.state.active_loan_id &&
      user.state.release_number > 2 &&
      tags.push({
        className: "second-plus-loan",
        icon: "looks",
        text: t("Second+ loan"),
      });
    return <ListItemTags tags={tags} />;
  }
}

export default UserListItemTags;
