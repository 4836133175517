import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ReactComponent as Loader } from "../../../assets/vector/loader.svg";
import { Conditional } from "../../components";
import "./blockingview.css";

export const BlockingViewScrim = ({
  isBlocked,
  isPending,
  isVisible,
  labelText,
}) => (
  <Conditional condition={isPending || isBlocked || isVisible}>
    <div className="scrim">
      <Loader className="throbber" />
      <Conditional condition={labelText}>
        <span className="label-text">{labelText}</span>
      </Conditional>
    </div>
  </Conditional>
);

export const BlockingViewContent = ({ isPending, children }) => {
  return typeof children === "function" ? (
    <Conditional condition={isPending}>{children()}</Conditional>
  ) : (
    children
  );
};

export const BlockingView = ({
  children,
  className,
  component,
  tag,
  isPending,
  labelText,
  ...otherProps
}) => {
  const Wrap = tag || component || "div";
  const [isVisible, setIsVisible] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  useEffect(() => {
    if (isPending) {
      const timeOut = setTimeout(() => setIsVisible(true), 5);
      setIsBlocked(true);
      return () => clearTimeout(timeOut);
    } else {
      const timeOut = setTimeout(() => setIsBlocked(false), 1000);
      setIsVisible(false);
      return () => clearTimeout(timeOut);
    }
  }, [isPending, setIsVisible]);
  return (
    <Wrap
      {...otherProps}
      className={classNames(className, "blocking-view", {
        blocked: isBlocked,
        pending: isPending,
        visible: isVisible,
      })}
    >
      <BlockingViewContent>{children}</BlockingViewContent>
      <BlockingViewScrim
        labelText={labelText}
        isBlocked={isBlocked}
        isPending={isPending}
        isVisible={isVisible}
      />
    </Wrap>
  );
};
