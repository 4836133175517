import React from "react";
import { string } from "prop-types";

function Flag({ country }) {
  const flagUrl = `/flags/${country ? country.toLowerCase() : "gt"}.svg`;
  return <img className="flag" src={flagUrl} alt="flag"></img>;
}
Flag.propTypes = {
  country: string,
};
export default Flag;
