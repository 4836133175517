import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import ListItem from "../../../common/list/item/item";
import ListItemGraphic from "../../../common/list/item/itemgraphic";
import ListItemContent from "../../../common/list/item/itemcontent";
import ListItemMeta from "../../../common/list/item/meta/meta";
import PaymentListItemTags from "./itemtags";
import PaymentAvatar from "../../avatar/avatar";
import Time from "../../../common/time/time";
import Uuid from "../../../common/uuid/uuid";
import UserName from "../../../user/name/name";
import { compose } from "../../../../util/compose";
import { dateFormats } from "../../../../util/dateutils";
import { Currency } from "../../../globals/currency/currency";
import "./item.css";

class PaymentListItem extends React.Component {

  
  static propTypes = {
    country: PropTypes.string,
    history: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    payment: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };
  static defaultProps = {
    selected: false,
    onSelect: (payment) => {},
  };
  onPayment() {
    const payment = this.props.payment;
    this.props.history.push(`/payments/${payment.payment_id}`);
  }
  render() {
    const { payment, selected, t } = this.props;
    return (
      <ListItem
        className="payment"
        item={payment}
        selected={selected}
        onSelect={this.onPayment.bind(this)}
        linkRef={"/payments/" + payment.payment_id}
      >
        <ListItemGraphic>
          <PaymentAvatar payment={payment} />
        </ListItemGraphic>
        <ListItemContent>
          <h4>
            <UserName user={payment.user} />
          </h4>
          <Uuid text={`${t("ID")}:`} uuid={payment.payment_id} />
          <Uuid text={`${t("Ref.")}:`} uuid={payment.reference} />
          <Time
            time={payment.updated_at}
            format={dateFormats.MMMM_D_YYYY_H_mm}
          />
        </ListItemContent>
        <ListItemMeta>
          <h4>
            <Currency amount={payment.amount} country={this.props.country} />
          </h4>
          <h5>{payment.bank}</h5>
          <h5>{payment.agency}</h5>
        </ListItemMeta>
        <PaymentListItemTags payment={payment} />
      </ListItem>
    );
  }
}

export default compose(PaymentListItem)(withRouter, withTranslation());
