export const fields = {
  due_date: {
    key: "due_date",
    label: "Due date",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  grace_period_date: {
    key: "grace_period_date",
    label: "Grace period date",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  status: {
    key: "status",
    label: "Status",
    icon: "lock",
    type: "select",
    columns: 6,
    validateFrom: true,
    options: [
      { label: "Signed", value: "signed", from: ["document_approved"] },
      { label: "Transferring", value: "transferring", from: ["signed"] },
      {
        label: "Failed transfer",
        value: "failed_transfer",
        from: ["transferring"]
      },
      {
        label: "Released",
        value: "released",
        from: ["transferring", "signed"]
      },
      { label: "Fulfilled", value: "fulfilled", from: ["released"] },
      { label: "Archived", value: "archived", from: ["*"] }
    ],
  },
  settled: {
    key: "settled",
    label: "Settled",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  over_settled: {
    key: "over_settled",
    label: "Over settled",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  late_fee: {
    key: "late_fee",
    label: "Late fee",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  charges: {
    key: "charges",
    label: "Charges",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  discount: {
    key: "discount",
    label: "Discount",
    icon: "lock",
    columns: 6,
    readonly: true
  },
  estimated_release: {
    key: "estimated_release",
    label: "Estimated release",
    icon: "event",
    columns: 12,
    readonly: true
  },
  released_datetime: {
    key: "released_datetime",
    label: "Released datetime",
    icon: "event",
    columns: 12,
    readonly: true
  },
  updated_at: {
    key: "updated_at",
    label: "Updated at",
    icon: "event",
    columns: 12,
    readonly: true
  },
  created_at: {
    key: "created_at",
    label: "Created at",
    icon: "event",
    columns: 12,
    readonly: true
  }
};
