import classNames from "classnames";
import { bankCurrencies, bankLabels } from "../../../domain/banks/banks";
import {
  transactionStatus,
  transactionStatusLabels,
} from "../../../domain/banks/transactionstatus";
import { isNumeric } from "../../../util/commonutils";
import { dateFormats, formatDate } from "../../../util/dateutils";
import { Button } from "../../controls/button/button";
import { Icon } from "../../controls/icon/icon";
import { Ripple } from "../../controls/ripple/ripple";
import { Currency } from "../../globals/currency/currency";
import { TextTrim } from "../../globals/texttrim/texttrim";
import { Section } from "../../layout/layout";
import Table, {
  DataCell,
  HeaderCell,
  TableBody,
  TableHeader,
  TableRow,
} from "../../layout/table/table";
import "./statementtable.css";

const HighlightedText = ({ className, text, highlight }) => {
  if (!text) {
    return null;
  }
  const index = text && highlight ? text.indexOf(highlight) : -1;
  if (index >= 0) {
    const before = text.substring(0, index);
    const middle = text.substring(index, index + highlight.length);
    const after = text.substring(index + highlight.length);
    return (
      <span className={classNames(className, "highlighted-text")}>
        {before ? <span>{before}</span> : null}
        <em>{middle}</em>
        {after ? <span>{after}</span> : null}
      </span>
    );
  } else {
    return (
      <span className={classNames(className, "highlighted-text")}>{text}</span>
    );
  }
};

const DataCellWithButtons = ({ text, highlight }) => {
  const copyToClipboard = () => {
    const type = "text/plain";
    const blob = new Blob([text], { type });
    const data = [new window.ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data).then(
      () => console.log(`text copied to clipboard`),
      () => console.log(`can't copy to clipboard`)
    );
  };
  return (
    <DataCell>
      <div>
        <HighlightedText text={text} highlight={highlight} />
        <div className="actions">
          <Button onClick={copyToClipboard}>
            <Icon icon="content_copy" />
          </Button>
        </div>
      </div>
    </DataCell>
  );
};

const HeaderCellWithButtons = ({ filter, setFilter, columnName, children }) => {
  const isSorted = filter.sortBy === columnName;
  const isAsc = filter.sortOrder === "asc";
  const isDesc = filter.sortOrder === "desc";
  const onClick = async () => {
    if (!isSorted) {
      await setFilter({
        sortBy: columnName,
        sortOrder: "asc",
      });
    } else if (isAsc) {
      await setFilter({
        sortBy: columnName,
        sortOrder: "desc",
      });
    } else if (isDesc) {
      await setFilter({
        sortBy: "",
        sortOrder: "",
      });
    }
  };
  return (
    <HeaderCell
      className={classNames("sortable", {
        "sort-by": isSorted,
        "sort-asc": isSorted && isAsc,
        "sort-desc": isSorted && isDesc,
      })}
    >
      <Ripple onClick={onClick}>
        <span className="text">{children}</span>
        <Icon icon="arrow_drop_down" />
      </Ripple>
    </HeaderCell>
  );
};

export const StatementTable = ({ statement, filter, setFilter }) => {
  const transactions = (
    statement?.transactions ? statement.transactions : []
  ).filter((transaction) => {
    if (
      (filter &&
        filter.amount &&
        transaction.credit &&
        parseInt(transaction.credit) !== parseInt(filter.amount)) ||
      (filter &&
        filter.reference &&
        transaction.reference &&
        transaction.reference.search(filter.reference) < 0) ||
      (filter &&
        filter.document &&
        transaction.document &&
        transaction.document.search(filter.document) < 0) ||
      (filter &&
        filter.date &&
        transaction.date &&
        transaction.date !== filter.date) ||
      (filter &&
        filter.status &&
        transaction.linked &&
        transaction.linked.toUpperCase() !== filter.status)
    ) {
      return false;
    }
    return true;
  });
  const sortedTransactions = filter.sortBy
    ? transactions.sort((a, b) => {
        const compA =
          filter?.sortBy &&
          a &&
          a[filter.sortBy] &&
          a[filter.sortBy].toLowerCase
            ? a[filter.sortBy].toLowerCase()
            : "";
        const compB =
          filter?.sortBy &&
          b &&
          b[filter.sortBy] &&
          b[filter.sortBy].toLowerCase
            ? b[filter.sortBy].toLowerCase()
            : "";
        const compare =
          isNumeric(compA) && isNumeric(compB)
            ? compA - compB
            : compA.localeCompare(compB);
        return filter.sortOrder === "asc" ? compare * -1 : compare;
      })
    : transactions;
  return (
    <Section className="table">
      <Table>
        <TableHeader>
          <TableRow>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="bank"
            >
              Banco
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="branch"
            >
              Agencia
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="description"
            >
              Descripción
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="reference"
            >
              No. de referencia
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="document"
            >
              Documento
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="linked"
            >
              Status
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="date"
            >
              Depositado
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="createdAt"
            >
              Registrado
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="credit"
            >
              Crédito
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="debit"
            >
              Débito
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="checkOther"
            >
              Cheques/Otros
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="bankTransactionId"
            >
              Id Transacción
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="category"
            >
              Categoría
            </HeaderCellWithButtons>
            <HeaderCellWithButtons
              filter={filter}
              setFilter={setFilter}
              columnName="loanId"
            >
              Id Crédito
            </HeaderCellWithButtons>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedTransactions.map((transaction, key) => (
            <TableRow key={key}>
              <DataCell>{bankLabels[transaction.bank]}</DataCell>
              <DataCell>
                <TextTrim maxLength={25} text={transaction.branch} />
              </DataCell>
              <DataCell>{transaction.description}</DataCell>
              <DataCellWithButtons
                text={transaction.reference}
                highlight={filter.reference}
              />
              <DataCellWithButtons
                text={transaction.document}
                highlight={filter.document}
              />
              <DataCell>
                {transaction?.linked
                  ? transactionStatusLabels[transaction.linked.toUpperCase()]
                  : transactionStatusLabels[transactionStatus.UNLINKED]}
              </DataCell>
              <DataCell>
                {formatDate(transaction.date, dateFormats.MMMM_D_YYYY)}
              </DataCell>
              <DataCell>
                {formatDate(
                  transaction.createdAt,
                  dateFormats.MMMM_D_YYYY_H_mm
                )}
              </DataCell>
              <DataCell>
                <Currency
                  amount={transaction.credit}
                  currency={bankCurrencies[transaction.bank]}
                />
              </DataCell>
              <DataCell>
                <Currency
                  amount={transaction.debit}
                  currency={bankCurrencies[transaction.bank]}
                />
              </DataCell>
              <DataCell>
                <Currency
                  amount={transaction.checkOther}
                  currency={bankCurrencies[transaction.bank]}
                />
              </DataCell>
              <DataCell>{transaction.bankTransactionId}</DataCell>
              <DataCell>{transaction.categories}</DataCell>
              <DataCell>{transaction.loanId}</DataCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Section>
  );
};
