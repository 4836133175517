export const selectAllModalViews = () => (store) => {
  return store.globals.modalViews
    ? Object.values(store.globals.modalViews)
    : [];
};

export const selectFragment = (fragmentId) => (store) => {
  /* fragments are little pieces of data stored globally. miscellany. */
  return fragmentId &&
    store.globals.fragments &&
    store.globals.fragments[fragmentId]
    ? store.globals.fragments[fragmentId]
    : "";
};

export const selectModalView = (modalViewId) => (store) => {
  return modalViewId &&
    store.globals.modalViews &&
    store.globals.modalViews[modalViewId]
    ? store.globals.modalViews[modalViewId]
    : "";
};

export const selectViewPort = () => (store) => {
  return store?.globals?.viewPort
    ? { width: 0, height: 0, ...store.globals.viewPort }
    : { width: 0, height: 0 };
};
