import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "../slice";
import { thunkTypes } from "../thunktypes";

export const updateModalView = createAsyncThunk(
  thunkTypes.UPDATE_MODAL_VIEW,
  async (params, { dispatch }) => {
    await dispatch(actions.mergeModalView(params));
    return params.id;
  }
);
