import classNames from "classnames";
import { ReactComponent as FlagDo } from "../../../assets/vector/flag-do.svg";
import { ReactComponent as FlagGt } from "../../../assets/vector/flag-gt.svg";
import { ReactComponent as FlagHn } from "../../../assets/vector/flag-hn.svg";
import "./icon.css";

export const Icon = ({ className, icon }) => (
  <span className={classNames(className, "icon", icon)} />
);

export const OutlinedIcon = ({ className, icon }) => (
  <Icon className={classNames(className, "outlined")} icon={icon} />
);

export const FlagIcon = ({ className, country }) => {
  const renderFlagAsset = () => {
    const countryCode = country ? country.trim().toLowerCase() : "";
    switch (countryCode) {
      case "gt":
        return <FlagGt />;
      case "do":
        return <FlagDo />;
      case "hn":
        return <FlagHn />;
      default:
        return null;
    }
  };
  return (
    <span className={classNames(className, "icon", "flag")}>
      {renderFlagAsset()}
    </span>
  );
};
