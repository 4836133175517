/* payment banks are stored as bank names (not swift) by default */
export const paymentBanks = {
  INDLGTGC: "Banco Industrial",
  GTCOGTGC: "G&T",
  BRRLGTGC: "Banrural",
  BRRDDOSD: "Banreservas",
  AMCNGTGT: "BAC",
  BMILHNTE: "BAC - HN"
};

export const paymentBankLabels = {
  [paymentBanks.INDLGTGC]: `Banco Industrial`,
  [paymentBanks.GTCOGTGC]: `G&T`,
  [paymentBanks.BRRLGTGC]: `Banrural`,
  [paymentBanks.BRRDDOSD]: `Banreservas`,
  [paymentBanks.AMCNGTGT]: `BAC`,
  [paymentBanks.BMILHNTE]: `BAC - HN`,
};

export const paymentBankOptions = Object.values(paymentBanks).map((value) => ({
  value,
  label: paymentBankLabels[value],
}));

export const paymentBanksOrigin = {
  BACGT: "BAC",
  BACHN: "BAC - HN",
  BAM: "BAM",
  Banrural: "Banrural",
  Bantrab: "Bantrab",
  "Banco Azteca": "Banco Azteca",
  "Banco Industrial": "Banco Industrial",
  "G&T Continental": "G&T Continental",
  Promerica: "Promerica",
  "Banco Popular": "Banco Popular",
  "Banco BHD": "Banco BHD",
  Banreservas: "Banreservas",
  Scotiabank: "Scotiabank",
  "Asociación Popular": "Asociación Popular",
  "Otro banco": "Otro banco"
};

export const paymentBankOriginLabels = {
  [paymentBanksOrigin.BACGT]: "BAC",
  [paymentBanksOrigin.BACHN]: "BAC - HN",
  [paymentBanksOrigin.BAM]: "BAM",
  [paymentBanksOrigin.Banrural]: "Banrural",
  [paymentBanksOrigin.Bantrab]: "Bantrab",
  "Banco Azteca": "Banco Azteca",
  "Banco Industrial": "Banco Industrial",
  "G&T Continental": "G&T Continental",
  Promerica: "Promerica",
  "Banco Popular": "Banco Popular",
  "Banco BHD": "Banco BHD",
  Banreservas: "Banreservas",
  Scotiabank: "Scotiabank",
  "Asociación Popular": "Asociación Popular",
  "Otro banco": "Otro banco"
};

export const paymentBankOriginOptions = Object.values(paymentBanksOrigin).map(
  (value) => ({
    value,
    label: paymentBankOriginLabels[value],
  })
);
