import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FieldsCard from "../../../../common/fields/fieldscard";
import { compose } from "../../../../../util/compose";
import { fields } from "./fields";

import "@material/react-layout-grid/dist/layout-grid.css";

class State extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data, t } = this.props;
    const fields_filter = {};
    let fields_result = {};

    if (data.country === "DO") {
      fields_result = fields;
    } else {
      Object.entries(fields).map(([key, value]) => {
        if (!key.match(/processing_fee/)) {
          fields_filter[key] = value;
        }
        return null;
      });
      
      fields_result = fields_filter;
    }

    return (
      <FieldsCard
        data={data}
        fields={fields_result}
        icon="description"
        title={t("Loan request")}
        subtitle={t("Loan requested amount and other data")}
      />
    );
  }
}

export default compose(State)(withTranslation());
