import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

class ListItemGroup extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
  };
  render() {
    const { text, icon, className } = this.props;
    return (
      <div className={ClassNames("list-item group", className)}>
        <div className="center">
          {icon && <span className="icon">{icon}</span>}
          {text && <span className="text">{text}</span>}
        </div>
      </div>
    );
  }
}

export default ListItemGroup;
