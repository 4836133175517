import { initialState } from "./initialState";

export const setLoanRequestfilterReducer = (state = initialState, action) => {
  switch (action.payload.source) {
    case "documentSentLength":
      return { ...state, documentSentLength: action.payload.filters };
    case "createdLength":
      return { ...state, createdLength: action.payload.filters };
    case "reviewingLength":
      return { ...state, reviewingLength: action.payload.filters };
    case "offerAcceptedLength":
      return { ...state, OfferAcceptedLength: action.payload.filters };
    default:
      return state;
  }
};
