export const createFileRepo = (fileReader) => {
  const exportToCsv = async (params) => {
    return await fileReader.exportToCsv(params);
  };
  const parseTextAsCsv = async (text, config) => {
    return await fileReader.parseTextAsCsv(text, config);
  };
  const readFileAsText = async (file, encoding) => {
    return await fileReader.readFileAsText(file, encoding);
  };
  const readXLSFileAsText = async (file) => {
    return await fileReader.readXLSFileAsText(file);
  };
  return {
    exportToCsv,
    parseTextAsCsv,
    readFileAsText,
    readXLSFileAsText,
  };
};
