import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "../slice";
import { thunkTypes } from "../thunktypes";

export const updateViewPort = createAsyncThunk(
  thunkTypes.UPDATE_VIEW_PORT,
  async (params, { dispatch }) => {
    await dispatch(actions.mergeViewPort(params));
    return params;
  }
);
