export const fields = {
  first_name: {
    key: "first_name",
    label: "Nombre",
    icon: "lock"
  },
  last_name: {
    key: "last_name",
    label: "Apellido",
    icon: "lock"
  },
  email: {
    key: "email",
    label: "Email",
    icon: "lock"
  }
};
