import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import TextField from "../text/text";
import CalendarDialog from "../../calendar/calendardialog";
import { compose } from "../../../../util/compose";

class DateField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    date: PropTypes.object.isRequired,
    display: PropTypes.string,
    format: PropTypes.string,
    label: PropTypes.string.isRequired,
    onDate: PropTypes.func,
    readonly: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    display: "MMMM D",
    format: "YYYY-MM-DD",
    onDate: ({ from, to }) => {},
    readonly: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }
  onDate(date) {
    this.props.onDate(date);
    this.setState({ toggle: false });
  }
  onCalendar() {
    this.setState({ toggle: true });
  }
  onDismiss() {
    this.setState({ toggle: false });
  }
  getText() {
    const { date, display, t } = this.props;
    if (!date.from && !date.to) {
      return t("Select a date");
    }
    if (!date.from && date.to) {
      return t("Select a date");
    }
    if (date.from && !date.to) {
      return Moment(date.from).format(display);
    }
    if (date.from && date.to) {
      const from = date.from ? Moment(date.from).format(display) : "";
      const to = date.to ? Moment(date.to).format(display) : "";
      return `${from} to ${to}`;
    }
  }
  render() {
    return (
      <div className={classNames("field field-date", this.props.className)}>
        <CalendarDialog
          date={this.props.date}
          format={this.props.format}
          onConfirm={this.onDate.bind(this)}
          onDismiss={this.onDismiss.bind(this)}
          toggle={this.state.toggle}
          type={this.props.type}
        />
        <TextField
          label={this.props.t(this.props.label)}
          icon={this.props.readonly ? "lock" : "calendar_today"}
          onClick={this.onCalendar.bind(this)}
          readonly={this.props.readonly}
          value={this.getText()}
        />
      </div>
    );
  }
}

export default compose(DateField)(withTranslation());
