import { configureStore } from "@reduxjs/toolkit";
import { reducer } from "./reducer";
import { middleware } from "./middleware/middleware";
import { persistStore } from "redux-persist";

export const store = configureStore({
  middleware,
  reducer,
});

export const persistor = persistStore(store);
