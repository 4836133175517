import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import List from "../../common/list/list";
import ListItems from "../../common/list/listitems";
import UserListItem from "./item/item";
import ListToolbar from "../../common/list/toolbar/toolbar";
import { compose } from "../../../util/compose";
import "./list.css";

class UserList extends React.Component {
  static propTypes = {
    adapter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    users: PropTypes.array,
    loading: PropTypes.bool,
    selected: PropTypes.string,
    onUser: PropTypes.func,
    onReload: PropTypes.func,
  };
  static defaultProps = {
    loading: false,
    onUser: (user) => {},
    onReload: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      sorted: null,
    };
  }
  componentDidMount() {
    if (!this.props.users) {
      this.props.onReload();
    }
  }
  filter() {
    console.log("UserList.filter");
    if (!this.props.users) {
      return this.props.users;
    }
    const adapter = this.props.adapter;
    const filters = this.state.filters;
    let sorted = [...this.props.users];
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] && filters[filter].length) {
        sorted = adapter.filters[filter].onFilter(sorted, filters[filter]);
      }
    });
    return sorted;
  }
  onReload() {
    this.props.onReload();
  }
  onFilter(filters) {
    this.setState({ sorted: null, filters });
  }
  onUser(user) {
    this.props.history.push(`/users/details/${user.user_id}`);
  }
  render() {
    return (
      <List className="users">
        <ListToolbar
          filters={this.props.adapter.filters}
          loading={this.props.loading}
          selected={{ filters: this.state.filters }}
          onFilter={this.onFilter.bind(this)}
          onReload={this.onReload.bind(this)}
        />
        <ListItems
          adapter={this.props.adapter}
          loading={this.props.loading}
          filters={this.state.filters}
          items={this.filter()}
        >
          {(user, key) => (
            <UserListItem
              key={key}
              user={user}
              selected={user.user_id === this.props.selected}
              onSelect={this.onUser.bind(this)}
            />
          )}
        </ListItems>
      </List>
    );
  }
}

export default compose(UserList)(withRouter);
