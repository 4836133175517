import { handleError } from "../util/handleerror";

/**
 * Loan repository.
 */
class Loans {
  /**
   * @param {Object} token
   * @param {Axios} requests
   * @param {Object} dataLayer
   */
  constructor(token, requests, dataLayer) {
    this.token = token;
    this.requests = requests;
    this.dataLayer = dataLayer;
  }
  getLoan(loanRequestId, withUser) {
    const { requests, dataLayer } = this;
    return requests({
      method: "get",
      url: `/loans/${loanRequestId}`,
    })
      .then(async ({ data }) => {
        return withUser
          ? await dataLayer.users.getUser(data.data.user_id).then((user) => {
            data.data.user = user;
            return data.data;
          })
          : data.data;
      })
      .catch(handleError);
  }
  getLoans(params) {
    const { requests } = this;
    return requests({
      method: 'get',
      url: '/loans',
      params
    })
      .then(async ({ data }) => {
        const loans = {};
        data.data.items.forEach(
          (loan) => (loans[loan.loan_request_id] = loan)
        );
        return loans;
      })
      .catch(handleError);
  }
  getPayments(loanRequestId) {
    return this.requests({
      method: "get",
      url: `/loans/${loanRequestId}/payments`,
    })
      .then(({ data }) => data.data.items)
      .catch(handleError);
  }
  getBalance(loanRequestId, userId) {
    return this.requests({
      method: "post",
      url: `${process.env.REACT_APP_API_IO_BASE}/loans/balance`,
      data: { data: { user_id: userId, loan_id: loanRequestId } },
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
  updateLoanStatus(loanRequestId, userId, status, isSoftReject) {
    return this.requests({
      method: "post",
      url: `/loans/${loanRequestId}/update_status`,
      data: { data: { user_id: userId, status, isSoftReject } },
    })
      .then(({ data }) => data.data)
      .catch(handleError);
  }
}

export default Loans;
