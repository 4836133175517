import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBankById } from "../../redux/banks/selectors";
import { getBankStatement } from "../../redux/banks/thunks/getbankstatement";
import { Conditional } from "../components";
import { Article } from "../layout/layout";
import { Attributes } from "./attributes";
import "./bank.css";
import { Statement } from "./statement";

export const Bank = ({ bankId, allowStatementUpdate }) => {
  const store = useSelector((store) => store);
  const bank = useSelector(selectBankById(bankId));
  const [hasFetchedStatement, setHasFetchedStatement] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setHasFetchedStatement(false);
  }, [bank.bankId, setHasFetchedStatement]);
  useEffect(() => {
    if (
      bank.bankId &&
      !store.banks.isPending &&
      !store.banks.isRejected &&
      !hasFetchedStatement
    ) {
      setHasFetchedStatement(true);
      dispatch(getBankStatement({ bankId: bank.bankId }));
    }
  }, [
    dispatch,
    store.banks.isPending,
    store.banks.isRejected,
    hasFetchedStatement,
    setHasFetchedStatement,
    bank,
  ]);
  return (
    <Article className="bank" component="article">
      <Conditional condition={bankId}>
        <Attributes bank={bank} />
        <Statement bank={bank} allowStatementUpdate={allowStatementUpdate} />
      </Conditional>
    </Article>
  );
};
