import { objectKeysToCamelCase } from "../util/commonutils";
import { dateFormats, formatDate } from "../util/dateutils";

export const createBankRepo = (apiClient, fileReader) => {
  const getBankStatement = async (bankId, date, days) => {
    const d = formatDate(date, dateFormats.YYYY_MMMM_DD);
    const base = `/reports/bank_statement/last`;
    const url = `${base}/${bankId}?date=${d}&days=${days ?? "1"}`;
    const headers = { "Content-Type": "text/csv" };
    const csv = await apiClient.get({ url, headers });
    const { data } =
      typeof csv === "string"
        ? await fileReader.parseTextAsCsv(csv)
        : { data: [] };
    const transactions = data.map(objectKeysToCamelCase);
    return {
      bankId,
      transactions,
    };
  };
  const updateBankStatement = async (bank, statement) => {
    return await apiClient.post({
      url: "/banks/statements/update",
      data: { bank, statement },
    });
  };
  return {
    getBankStatement,
    updateBankStatement,
  };
};
