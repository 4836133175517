export const selectAllBanks = () => (store) => {
  return store && store.banks && store.banks.banks
    ? Object.values(store.banks.banks)
    : [];
};

export const selectBankById = (bankId) => (store) => {
  return bankId &&
    store &&
    store.banks &&
    store.banks.banks &&
    store.banks.banks[bankId]
    ? store.banks.banks[bankId]
    : "";
};

export const selectBankStatement = (bankId) => (store) => {
  return bankId &&
    store &&
    store.banks &&
    store.banks.statements &&
    store.banks.statements[bankId]
    ? store.banks.statements[bankId]
    : [];
};
