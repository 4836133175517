export const currencies = {
  GTQ: "GTQ",
  DOP: "DOP",
  L: "L",
};

export const currencySymbols = {
  [currencies.GTQ]: "Q",
  [currencies.DOP]: "RD$",
  [currencies.L]: "L",
};

export const currencyLabels = {
  [currencies.GTQ]: "Quetzal",
  [currencies.DOP]: "Peso dominicano",
  [currencies.L]: "Lempira",
};
