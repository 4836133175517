import { currencies, currencySymbols } from "../../../domain/banks/currency";

const getCountryCurrency = (country) => {
  const code = country ? country.toLowerCase() : "gt";
  switch (code) {
    case "do":
      return currencies.DOP;
    case "gt":
      return currencies.GTQ;
    case "hn":
      return currencies.L;
    default:
      return currencies.GTQ;
  }
};

const getCurrencyLocale = (currency) => {
  switch (currency) {
    case currencies.DOP:
      return "es-DO";
    case currencies.GTQ:
      return "es-GT";
    case currencies.L:
      return "es-HN";
    default:
      return "es-GT";
  }
};

export const Currency = ({ amount, children, country, currency, truncate }) => {
  let symbol = "";
  let locale = "";
  if (country) {
    currency = getCountryCurrency(country);
  }
  if (currency && currencies[currency]) {
    symbol = currencySymbols[currency];
    locale = getCurrencyLocale(currency);
  }
  if (!symbol || !locale) {
    symbol = currencySymbols[currencies.GTQ];
    locale = getCurrencyLocale(currencies.GTQ)
  }
  const intAmount = truncate
    ? parseInt(children || amount || 0)
    : children || amount || 0;
  const localeAmount = locale ? intAmount.toLocaleString(locale) : intAmount;
  const formatAmount = `${symbol} ${localeAmount}`;
  return <span className="currency">{formatAmount}</span>;
};
