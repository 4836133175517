import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import CircleIcon from "../../../../common/circle/circleicon";
import ListItem from "../../../../common/list/item/item";
import ListItemGraphic from "../../../../common/list/item/itemgraphic";
import ListItemContent from "../../../../common/list/item/itemcontent";
import ListItemMeta from "../../../../common/list/item/meta/meta";
import DisbursalListItemTags from "./itemtags";
import Dialog from "../../../../common/dialog/dialog";
import Time from "../../../../common/time/time";
import Uuid from "../../../../common/uuid/uuid";
import { withApp } from "../../../../app/app";
import { compose } from "../../../../../util/compose";
import { withDataLayer } from "../../../../datalayer/datalayer";
import "./item.css";

class DisbursalListItem extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    disbursal: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  static defaultProps = {
    selected: false,
    onSelect: (disbursal) => {},
  };
  onLoan() {
    const disbursal = this.props.disbursal;
    this.props.history.push(`/loans/details/${disbursal.loan_request_id}`);
  }

  onDialogClose() {
    this.setState({
      isOpen: false,
    });
  }

  failure() {
    const { disbursal } = this.props;
    this.props.dataLayer.disbursals
      .failures(disbursal.loan_id)
      .then((response) => {
        console.log("success");
      })
      .catch((error) => {
        this.props.app.onError({
          text: this.props.t("Error updating failure."),
          data: error.details,
        });
      });
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { disbursal, selected } = this.props;
    const { isOpen } = this.state;
    return (
      <>
        <ListItem
          className="loan"
          item={disbursal}
          selected={selected}
          onSelect={this.onLoan.bind(this)}
        >
          <ListItemGraphic>
            <CircleIcon icon="credit_card" />
          </ListItemGraphic>
          <ListItemContent>
            <h4>{disbursal.first_name}</h4>
            <Uuid text="ID:" uuid={disbursal.loan_request_id} />
            <Time
              time={disbursal.processing_date}
              format="MMMM DD, YYYY H:mm:ss"
            />
          </ListItemContent>
          <ListItemMeta>
            <h4>Q {disbursal.amount}</h4>
          </ListItemMeta>
          <button
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                isOpen: true,
              });
            }}
            className="button"
          >
            Add failure
          </button>
          <DisbursalListItemTags disbursal={disbursal} />
        </ListItem>
        <Dialog
          open={isOpen}
          onDismiss={this.onDialogClose.bind(this)}
          onConfirm={this.failure.bind(this)}
        />
      </>
    );
  }
}

export default compose(DisbursalListItem)(
  withRouter,
  withTranslation(),
  withDataLayer,
  withApp
);
