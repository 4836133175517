import Moment from "moment";
import ObjectUtils from "../../../../util/objectutils";

const ListAdapter = {
  tools: {},
  filters: {
    status: {
      text: "Status",
      type: "filter",
      options: {
        failed_transfer: { text: "Failed transfer", icon: "clear" },
      },
      onFilter: (items, selected) =>
        items.filter(
          (disbursal) =>
            !selected.length ||
            (disbursal.revised_status &&
              selected.includes(disbursal.revised_status))
        ),
    },
    sort: {
      text: "Sort by",
      type: "choice",
      options: {
        firstName: { text: "First name", icon: "sort_by_alpha" },
        recent: { text: "Recent", icon: "arrow_upward" },
        oldest: { text: "Oldest", icon: "arrow_downward" },
        amount: { text: "Amount", icon: "arrow_upward" },
      },
      onFilter: (items, selected) => {
        switch (selected[0]) {
          case "firstName":
            return [...items].sort((a, b) => {
              const path = "user.personal.first_name";
              const firstNameA = ObjectUtils.navigate(a, path);
              const firstNameB = ObjectUtils.navigate(b, path);
              const lowerNameA = firstNameA ? firstNameA.toLowerCase() : "";
              const lowerNameB = firstNameB ? firstNameB.toLowerCase() : "";
              if (lowerNameA > lowerNameB) return 1;
              if (lowerNameA < lowerNameB) return -1;
              return 0;
            });
          case "recent":
            return [...items].sort((a, b) => {
              if (a.created_at < b.created_at) return 1;
              if (a.created_at > b.created_at) return -1;
              return 0;
            });
          case "oldest":
            return [...items].sort((a, b) => {
              if (a.created_at > b.created_at) return 1;
              if (a.created_at < b.created_at) return -1;
              return 0;
            });
          case "amount":
            return [...items].sort((a, b) => {
              if (a.amount < b.amount) return 1;
              if (a.amount > b.amount) return -1;
              return 0;
            });
          default:
            return items;
        }
      },
    },
  },
  groups: {
    sort: {
      icon: "history",
      filters: {
        status: "*",
        sort: ["recent", "oldest"],
      },
      onText: (a) => Moment(a.created_at).fromNow(),
      onGroup: (a, b) => {
        const c = Moment(a.created_at).fromNow();
        const d = Moment(b ? b.created_at : 0).fromNow();
        return c !== d;
      },
    },
    amount: {
      icon: "toll",
      filters: {
        status: "*",
        sort: ["amount"],
      },
      onText: (a) => `Q ${a.amount}`,
      onGroup: (a, b) => {
        return a.amount !== (b ? b.amount : 0);
      },
    },
    firstName: {
      icon: "sort_by_alpha",
      filters: {
        status: "*",
        sort: ["firstName"],
      },
      onText: (a) => {
        const path = "user.personal.first_name";
        const firstNameA = ObjectUtils.navigate(a, path);
        return firstNameA ? firstNameA.substring(0, 1).toUpperCase() : "";
      },
      onGroup: (a, b) => {
        const path = "user.personal.first_name";
        const firstNameA = ObjectUtils.navigate(a, path);
        const firstNameB = ObjectUtils.navigate(b, path);
        const lowerNameA = firstNameA ? firstNameA.toLowerCase() : "";
        const lowerNameB = firstNameB ? firstNameB.toLowerCase() : "";
        return lowerNameA.substring(0, 1) !== lowerNameB.substring(0, 1);
      },
    },
  },
};

export default ListAdapter;
