export const toCamelCase = (str) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const isObject = (obj) => {
  return (
    obj &&
    obj.hasOwnProperty &&
    obj === Object(obj) &&
    !Array.isArray(obj) &&
    typeof obj !== "function"
  );
};

export const objectKeysToCamelCase = (obj) => {
  const innerB = (obj) => {
    return innerA(obj);
  };
  const innerA = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(innerB);
    }
    if (isObject(obj)) {
      return Object.entries(obj).reduce((prev, [propKey, propValue]) => {
        const replaceKey = propKey.match(/[a-zA-Z0-9_]+/gi)
          ? toCamelCase(propKey)
          : propKey;
        return { ...prev, [replaceKey]: innerB(propValue) };
      }, {});
    }
    return obj;
  };
  return innerA(obj);
};

export const isNumeric = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};
