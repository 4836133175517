import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Progress from "../progress/progress";

class ListInput extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    posting: PropTypes.bool,
  };
  static defaultProps = {
    children: null,
    className: null,
    posting: false,
  };
  render() {
    const { children, className, posting } = this.props;
    return (
      <Progress
        loading={posting}
        children={() => children}
        className={classNames("list-input", className)}
      />
    );
  }
}

export default ListInput;
