import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectModalView } from "../redux/globals/selectors";
import { hideModalView } from "../redux/globals/thunks/hidemodalview";
import { revealModalView } from "../redux/globals/thunks/revealmodalview";
import { updateModalView } from "../redux/globals/thunks/updatemodalview";

export const useModalView = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const modalView = useSelector(selectModalView(id));
  const reveal = async (modalView) => {
    const result = await dispatch(
      revealModalView({
        ...modalView,
      })
    );
    if (result && result.payload) {
      setId(result.payload);
    }
  };
  const update = async (modalView) => {
    await dispatch(
      updateModalView({
        ...modalView,
        id,
      })
    );
  };
  const hide = async () => {
    await dispatch(
      hideModalView({
        id,
      })
    );
  };
  return [reveal, modalView, update, hide];
};
