import React from "react";
import PropTypes from "prop-types";
import ListFilters from "../listfilters";
import ToolbarButton from "./toolbarbutton";
import "./toolbar.css";
/*
 * const props = {
 *   tools: {
 *     comment: { text: "Comment", icon: "autorenew" },
 *     remove: { text: "Remove", icon: "trash" },
 *   },
 *   filters: {
 *     status: {
 *       text: "Status",
 *       type: "choice",
 *       options: {
 *         fulfilled: { text: "Fulfilled", icon: "autorenew" },
 *         released: { text: "Released", icon: "autorenew" },
 *       },
 *       onFilter: (items, selected) => items,
 *     },
 *   },
 *   groups: {
 *     sort: {
 *       icon: "history",
 *       filters: {
 *         status: "*",
 *       },
 *       onText: (a) => a.dueDate,
 *       onGroup: (a, b) => a.dueDate !== b.dueDate,
 *     },
 *   },
 * };
 *
 * const selected = {
 *   filters: {
 *     status: ["fulfilled"],
 *     sort: ["recent"],
 *   },
 *   buttons: ["comment"],
 * };
 *
 */
class ListToolbar extends React.Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    tools: PropTypes.object,
    selected: PropTypes.object,
    loading: PropTypes.bool,
    onTool: PropTypes.func,
    onFilter: PropTypes.func,
    onReload: PropTypes.func,
  };
  static defaultProps = {
    selected: {},
    loading: false,
    onFilter: (filters) => {},
    onTool: (tool) => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }
  onFilter(filters) {
    const selected = this.props.selected.filters;
    this.props.onFilter(Object.assign({}, selected, filters));
  }
  onToggle() {
    this.setState({ toggle: !this.state.toggle });
  }
  onTool(tool) {
    const multiple = false;
    const selected = this.props.selected.tools || [];
    if (multiple) {
      const change = [...selected.tools];
      if (change.includes(tool)) {
        change.filter((t) => t === tool);
      } else {
        change.push(tool);
      }
      this.props.onTool(change);
    } else {
      if (selected.includes(tool)) {
        this.props.onTool([]);
      } else {
        this.props.onTool([tool]);
      }
    }
  }
  render() {
    const loading = this.props.loading;
    const tools = this.props.selected.tools || [];
    const filters = this.props.selected.filters || {};
    return (
      <div className="list-toolbar">
        {this.state.toggle && (
          <ListFilters
            filters={this.props.filters}
            selected={filters}
            onFilter={this.onFilter.bind(this)}
          />
        )}
        <div className="tools">
          {this.props.tools &&
            Object.keys(this.props.tools).map((tool, key) => (
              <ToolbarButton
                key={key}
                className={`tool tool-${tool}`}
                button={this.props.tools[tool]}
                toggle={tools.includes(tool)}
                onClick={() => this.onTool(tool)}
              />
            ))}
          {this.props.filters && (
            <ToolbarButton
              className="tool tool-filters"
              button={{ icon: "filter_list" }}
              toggle={this.state.toggle}
              onClick={this.onToggle.bind(this)}
            />
          )}
          {this.props.onReload && (
            <ToolbarButton
              className="tool tool-refresh"
              button={{ icon: "replay" }}
              toggle={loading}
              onClick={this.props.onReload}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ListToolbar;
