import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SideBarTabs from "./sidebartabs";
import { withApp } from "../../app/app";
import { withDataLayer } from "../../datalayer/datalayer";
import { compose } from "../../../util/compose";

import "./sidebar.css";

class SideBar extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    loan: PropTypes.object,
    payment: PropTypes.object,
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    // onAnnotationCreation: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      annotations: null,
      loadingAnnotations: false,
      loadingLoans: false,
      loadingPayments: false,
      loans: null,
      payments: null,
      postingAnnotation: false,
      user: this.props.user,
    };
  }
  componentDidUpdate(props) {
    if (this.props.user.user_id !== props.user.user_id) {
      this.getAnnotations();
      this.getLoans();
      if (this.props.loan) {
        this.getPayments();
      }
    }
  }
  getAnnotations() {
    this.setState({ loadingAnnotations: true });
    return this.props.dataLayer.users
      .getAnnotations(this.props.user.user_id)
      .then((annotations) => Object.values(annotations))
      .then((annotations) => this.setState({ annotations }))
      .catch((error) =>
        this.props.app.onError({
          text: this.props.t("Error fetching user annotations."),
          data: error.details,
        })
      )
      .finally(() => this.setState({ loadingAnnotations: false }));
  }
  postAnnotation(annotation) {
    this.setState({ postingAnnotation: true });
    return this.props.dataLayer.users
      .addAnnotation(this.props.user.user_id, annotation)
      .then(() => this.onAnnotations())
      .catch((error) =>
        this.props.app.onError({
          text: this.props.t("Error posting annotation."),
          data: error.details,
        })
      )
      .finally(() => {
        this.setState({ postingAnnotation: false });
        this.props.user.state.loan_review = annotation.data?.review ?? this.props.user.state.loan_review;

        if (this.props.onAnnotationCreation) {
          this.props.onAnnotationCreation(this.props.user);
        }
      })
  }
  getLoans() {
    this.setState({ loadingLoans: true });
    return this.props.dataLayer.users
      .getLoans(this.props.user.user_id)
      .then((loans) =>
        this.setState({
          loans: loans.map((loan) =>
            Object.assign({ user: this.props.user }, loan)
          ),
        })
      )
      .catch((error) =>
        this.props.app.onError({
          data: error.details,
          text: this.props.t("Error fetching user loans."),
        })
      )
      .finally(() => this.setState({ loadingLoans: false }));
  }
  getPayments() {
    this.setState({ loadingPayments: true });
    return this.props.dataLayer.loans
      .getPayments(this.props.loan.loan_request_id, true)
      .then((payments) =>
        this.setState({
          payments: payments.map((payment) =>
            Object.assign({ user: this.props.user }, payment)
          ),
        })
      )
      .catch((error) =>
        this.props.app.onError({
          data: error.details,
          text: this.props.t("Error fetching payments."),
        })
      )
      .finally(() => this.setState({ loadingPayments: false }));
  }
  onAnnotations() {
    return Promise.all([this.getAnnotations(), this.getLoans()]);
  }
  onLoans() {
    return this.getLoans();
  }
  onPayments() {
    return this.getPayments();
  }
  render() {
    return (
      <SideBarTabs
        annotations={this.state.annotations}
        loadingAnnotations={this.state.loadingAnnotations}
        loadingLoans={this.state.loadingLoans}
        loadingPayments={this.state.loadingPayments}
        loan={this.props.loan}
        loans={this.state.loans}
        payment={this.props.payment}
        payments={this.state.payments}
        onAnnotations={this.onAnnotations.bind(this)}
        onAnnotationPost={this.postAnnotation.bind(this)}
        onLoans={this.onLoans.bind(this)}
        onPayments={this.onPayments.bind(this)}
        user={this.props.user}
      />
    );
  }
}

export default compose(SideBar)(withTranslation(), withDataLayer, withApp);
