import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TagsTag from "./tagstag";
import "./tags.css";

class ItemTags extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    tags: PropTypes.array.isRequired,
  };
  static defaultProps = {
    className: "",
    onTag: (tag) => {},
  };
  render() {
    return (
      <div className={classNames("tags", this.props.className)}>
        {this.props.tags.map((tag, key) => (
          <TagsTag key={key} tag={tag} onTag={this.props.onTag} />
        ))}
      </div>
    );
  }
}

export default ItemTags;
