import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material/react-dialog';
import BasicFooter from './footer/basic-confirmation/basic-confirmation';
import BasicTtitle from './title/basic-title/basic-title';
import InputContent from './content/input-confirm/input-confirm';
import ReactDOM from 'react-dom';
import '@material/react-dialog/dist/dialog.css';
import './dialog.css';

function CommonDialog(props) {
  const [data, setData] = useState({});

  const classifyDialog = () => {
    switch (props.dialogType) {
      case 'input-confirm':
        return getInputConfirm();
      default:
        return getConfirm();
    }
  };

  const getConfirm = () => {
    return [getBasicTitle(), getBasicFooter()];
  };

  const getInputConfirm = () => {
    return [getBasicTitle(), getInputContent(), getBasicFooter()];
  };

  const getInputContent = () => {
    return (
      <InputContent
        key="input-content"
        data={data}
        onChange={setData}
        inputText={props.inputText}
      />
    );
  };

  const getBasicFooter = () => {
    return (
      <BasicFooter
        key="basic-footer"
        accept={props.accept}
        dismiss={props.dismiss}
      />
    );
  };

  const getBasicTitle = () => {
    return <BasicTtitle key="basic-title" title={props.title} />;
  };

  const onClose = (action) => {
    switch (action) {
      case 'accept':
        props.onConfirm(data);
        break;
      default:
        props.onDismiss();
        break;
    }
  };

  return ReactDOM.createPortal(
    <Dialog open={props.open} onClose={onClose}>
      {classifyDialog()}
    </Dialog>,
    document.getElementById('vana-lms')
  );
}

CommonDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  dismiss: PropTypes.string,
  accept: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  dialogType: PropTypes.string,
  inputText: PropTypes.string,
};

CommonDialog.defaultProps = {
  open: false,
  onConfirm: () => {},
  onDismiss: () => {},
  dismiss: 'Dismiss',
  accept: 'Accept',
  title: 'Are you sure you want to continue?',
  content: '',
  dialogType: 'default',
  inputText: 'Write something here',
  role: 'dialog',
};

export default CommonDialog;
