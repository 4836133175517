import { Cell, Grid, Row } from "@material/react-layout-grid";
import "@material/react-layout-grid/dist/layout-grid.css";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "../../../../util/compose";
import { withApp } from "../../../app/app";
import Card from "../../../common/card/card";
import CardContent from "../../../common/card/cardcontent";
import Fields from "../../../common/fields/fields";
import Progress from "../../../common/progress/progress";
import { withDataLayer } from "../../../datalayer/datalayer";
import { Balance } from "../../../loan/balance/balance";
import Flag from "../../../user/flag/flag";
import UserVerified from "../../../user/certified/certified";
import Contact from "../../request/details/contact/contact";
import SideBar from "../../sidebar/sidebar";
import Document from "./document/document";
import Facebook from "./facebook/facebook";
import { fields } from "./fields";
import Personal from "./personal/personal";
import PersonalAddress from "./personaladdress/personaladdress";
import { State } from "./state/state";
import Work from "./work/work";
import WorkAddress from "./workaddress/workaddress";
import "./details.css";
import {
  MODULE_SEARCH_CLIENT_ACTIVE,
  USER_DETAILS_LMSV2_URL,
} from "../../../globals/variables/variables";

class Details extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dataLayer: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      user: null,
    };
  }
  componentDidUpdate(props) {
    this.props.match.params.userId !== props.match.params.userId &&
      this.getUser();
  }

  componentDidMount() {
    !this.state.user && this.getUser();
    if (!MODULE_SEARCH_CLIENT_ACTIVE) {
      window.location.href = `${USER_DETAILS_LMSV2_URL}/${this.props.match.params.userId}`;
    }
  }

  async getLoan(loanRequestId) {
    const { dataLayer } = this.props;
    const loanRequest = await dataLayer.requests.getRequest(loanRequestId);
    if (!loanRequest?.state?.loan_id) {
      return;
    }
    const loan = await dataLayer.loans.getLoan(loanRequest.state.loan_id);
    if (loan) {
      this.setState({ loan });
    }
  }
  async getUser() {
    const { app, dataLayer, match, t } = this.props;
    this.setState({ busy: true });
    try {
      const user = await dataLayer.users.getUser(match.params.userId);
      this.setState({ user });
      if (user?.state?.active_loan_id) {
        await this.getLoan(user.state.active_loan_id);
      }
    } catch (error) {
      app.onError({
        text: t("Error fetching user data."),
        data: error.details,
      });
    }
    this.setState({ busy: false });
  }
  onPersonalUpdate(personal) {
    const { app, dataLayer, match, t } = this.props;
    return dataLayer.users
      .updateUser(match.params.userId, { personal })
      .then(() => {
        app.onSnackbar(t("User personal data updated."));
        this.getUser();
      })
      .catch((error) =>
        app.onError({
          text: t("Error updating user personal data."),
          data: error.details,
        })
      );
  }
  onContactUpdate(referrer) {
    const { app, dataLayer, match, t } = this.props;
    return dataLayer.users
      .updateUser(match.params.userId, { referrer })
      .then(() => {
        app.onSnackbar(t("User contact data updated."));
        this.getUser();
      })
      .catch((error) =>
        app.onError({
          text: t("Error updating user contact data."),
          data: error.details,
        })
      );
  }
  onAnnotationCreation(user) {
    this.setState({ data: user.state });
  }
  render() {
    const { t, match } = this.props;
    const { busy, user, loan } = this.state;
    const state = user?.state.id_number_status;
    const confirmed = "confirmed";
    const personal = user ? user.personal || {} : {};
    const work = user ? user.work || {} : {};
    const contact = user && user.referrer ? user.referrer.contacts || {} : {};
    const data = {};
    const showBalance =
      loan &&
      loan.state &&
      (loan.state.status === "released" || loan.state.status === "fulfilled");
    Object.keys(fields).forEach((key) => {
      data[key] = Object.assign(
        { value: user ? user.state[fields[key].key] : "" },
        fields[key]
      );
    });
    return (
      <Progress
        className="user details sidebar-layout"
        loading={!user || busy}
        text={t("Fetching user data")}
      >
        {() => [
          <div key="content" className="content profile">
            <Grid>
              <Cell columns={12}>
                <Row>
                  <Cell columns={12}>
                    <Row>
                      <Cell columns={12}>
                        <Row>
                          <Cell columns={12}>
                            <div className="title-wrapper">
                              <Flag
                                country={personal.country}
                                className="flag"
                              />
                              <span className="title-1">
                                {t("User profile")}
                              </span>
                              {state === confirmed && <UserVerified />}
                            </div>
                          </Cell>
                          <Cell columns={12}>
                            <Card>
                              <CardContent>
                                <Fields data={data} />
                              </CardContent>
                            </Card>
                          </Cell>
                          {showBalance && (
                            <Cell columns={12}>
                              <Balance
                                loanId={loan.loan_id}
                                userId={loan.user_id}
                              />
                            </Cell>
                          )}
                          <Cell columns={12}>
                            <Personal
                              data={user.personal || {}}
                              userId={match.params.userId}
                              onUpdate={this.onPersonalUpdate.bind(this)}
                            />
                          </Cell>
                          <Cell columns={12}>
                            <PersonalAddress data={personal.address || {}} />
                          </Cell>
                          <Cell columns={12}>
                            <Facebook data={user.facebook || {}} />
                          </Cell>

                          <Contact
                            data={contact}
                            onUpdate={this.onContactUpdate.bind(this)}
                          />

                          <Cell columns={12}>
                            <State data={user.state || {}} />
                          </Cell>
                          <Cell columns={12}>
                            <Work data={user.work || {}} />
                          </Cell>
                          <Cell columns={12}>
                            <WorkAddress data={work.address || {}} />
                          </Cell>
                          <Cell columns={12}>
                            <Document data={personal.document || {}} />
                          </Cell>
                        </Row>
                      </Cell>
                    </Row>
                  </Cell>
                </Row>
              </Cell>
            </Grid>
          </div>,
          <SideBar
            key="sidebar"
            user={user}
            onAnnotationCreation={this.onAnnotationCreation.bind(this)}
          />,
        ]}
      </Progress>
    );
  }
}

export default compose(Details)(
  withApp,
  withDataLayer,
  withRouter,
  withTranslation()
);
