import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Snackbar } from "@material/react-snackbar";

import { compose } from "../../../util/compose";
import "@material/react-snackbar/dist/snackbar.css";

class AppSnackbar extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
  };
  static defaultProps = {
    data: null,
    onClose: () => {},
  };
  render() {
    const { onClose, t } = this.props;
    const data = Object.assign(
      { action: t("Dismiss"), text: t("Empty message") },
      this.props.data
    );
    return this.props.data ? (
      <Snackbar
        actionText={data.action}
        message={data.text}
        onClose={onClose}
        stacked={true}
      />
    ) : null;
  }
}

export default compose(AppSnackbar)(withTranslation());
