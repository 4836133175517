import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TextField, { Input } from '@material/react-text-field';
import MaterialIcon from '@material/react-material-icon';
import '@material/react-text-field/dist/text-field.css';
import '@material/react-material-icon/dist/material-icon.css';
import Lightbox from 'react-awesome-lightbox';

const styles = {
  img: {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
  },
  div: {
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
  },
};

class ImageField extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    showValueInField: PropTypes.bool,
    t: PropTypes.func,
  };

  static defaultProps = {
    icon: 'open_in_browser',
    value: '',
    showValueInField: true,
  };

  onClick() {
    window.open(this.props.value, '_blank');
  }

  render() {
    const { icon, label, t, value, showValueInField } = this.props;
    return (
      <div>
        <div style={styles.div}>
          <Lightbox image={value} />
        </div>
        <TextField
          style={styles.textField}
          label={t(label)}
          onTrailingIconSelect={this.onClick.bind(this)}
          trailingIcon={<MaterialIcon role="button" icon={icon} />}
        >
          <Input value={showValueInField ? value : ''} />
        </TextField>
      </div>
    );
  }
}

export default withTranslation()(ImageField);
